export default [
  {
    path: "opinion", component: { render: h => h("router-view") },
    //   meta: { title: "会刊列表", breadcrumb: { name: "JournalList" } },
    name: "Opinion",
    redirect: { name: "OpinionIndex" },
    children: [
      {
        path: "opinion", name: "OpinionIndex", component: () => import("../views/opinion-list"),
      },
      {
        path: "opinion-detail/:id", name: "OpinionDetail", component: () => import("../views/opinion-detail"),
      }
    ]
  }
]
