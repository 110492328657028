export default [
  {
    path: "mail", name: "Mail", component: { render: h => h("router-view") }, meta: { title: "" },
    redirect: { name: "CycMemberList" },
    children: [
      {
        path: "member", component: { render: h => h("router-view") },
        meta: { title: "会员名录", activeMenu: "CycMemberList", breadcrumb: { name: "CycMemberList" } },
        children: [
          { path: "member-list", name: "CycMemberList", component: () => import("../views/member/member-list") }
        ]
      },
      {
        path: "Company", component: { render: h => h("router-view") },
        // meta: {
        //   breadcrumb: { title: "", name: "CycCompanyList" }
        // },
        children: [
          {
            path: "list", name: "CycCompanyList",
            component: { render: h => h("router-view") },
            // meta: { title: "企业列表", breadcrumb: { name: "CycCompanyList" }, activeMenu: "CycCompanyList" },
            redirect: {name: "CycCompanyListIndex"},
            children: [
              {
                path: "company_list", name: "CycCompanyListIndex",
                component: () => import("../views/company/list/list"),
                // meta: { title: "" }
              },
              {
                path: "add-view-edit/:type/:id", name: "addViewEdit",
                component: () => import("../views/company/list/add-view-edit"),
                meta: { title: "新增" }
              },
              // {
              //   path: "edit_company", name: "editCompany",
              //   component: () => import("../views/company/list/add"),
              //   meta: { title: "编辑" }
              // },
            ]
          },
          {
            path: "Examine", name: "CycCompanyCheckList",
            component: { render: h => h("router-view") },
            // meta: { title: "审核列表", breadcrumb: { name: "CycCompanyCheckList" }, activeMenu: "CycCompanyCheckList" },
            redirect: { name: "CycCompanyCheckListIndex" },
            children: [
              {
                path: "examine_list", name: "CycCompanyCheckListIndex",
                component: () => import("../views/company/examine/list"),
                // meta: { title: "" }
              },
              {
                path: "examine_detail/:type/:id", name: "CompanyExamineDetail",
                component: () => import('../views/company/list/add-view-edit'),
                meta: {title: '查看'}
              },
              // {
              //   path: "examine_detail", name: "examineDetail",
              //   component: () => import("../views/company/examine/detail"),
              //   meta: { title: " 详情" }
              // },
            ]
          },
          {
            path: "Classify", name: "CycCompanyCategory", component: () => import("../views/company/classify/list"),
          },
          {
            path: 'cyc-company-config', name: 'CycCompanyConfig', component: () => import("../views/company/config/config"),
            // meta: {title: '设置'}
          },
          {
            path: "form-editor",
            name: "CycCompanyFormEditor",
            component: () =>
                import ("../views/company/config/form-editor"),
            meta: {title: "配置申请信息"}
          },
        ]
      },
    ]
  }
]
