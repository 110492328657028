export default [
  {
    path: "wechat-store", component: { render: h => h("router-view") },
    // meta: {
    //   title: "", activeMenu: "WxStoreConfig", breadcrumb: { title: "小商店", name: "WxStoreConfig" }
    // },
    children: [
      {
        path: "product", component: { render: h => h("router-view") },
        meta: {
          title: "", breadcrumb: { title: "商品列表", name: "WxStoreGoods" },
        },
        children: [
          { path: "product-list", name: "WxStoreGoods", component: () => import("../views/product-list/list"), meta: { title: "" } },
          { path: "product-detail/:type/:id", name: "WxStoreGoodsDetail", component: () => import("../views/product-list/detail"), meta: { title: "新增商品" } },
        ]
      },
      // {
      //   path: "product-category", name: "WxStoreCategory", component: () => import("../views/product-category"), meta: { title: "分类管理" },
      //   // children: [
      //   //   { path: "spirit_list", name: "AppletBaseInfo", component: () => import("../views/spirit-list"), meta: { title: "" } },
      //   //   { path: "add-spirit/:type/:id", name: "AddSpirit", component: () => import("../views/add-spirit"), meta: { title: "新增" } },
      //   // ]
      // },
      {
        path: "store-config", name: "WxStoreConfig", component: () => import("../views/store-config"),
        // meta: {
        //   title: "配置"
        // }
      }
    ]
  }
]
