/*
 * @Author: mulingyuer
 * @Date: 2021-04-12 18:23:38
 * @LastEditTime: 2021-05-28 17:40:23
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\lottery\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "lottery", component: { render: h => h("router-view") },
    name: "LotteryList",
    redirect: {name: "LotteryListIndex"},
    children: [
      {
        path: "", name: "LotteryListIndex", component: () => import("../views/lottery-list"),
      },
      {
        path: "add-edit-basic", component: { render: h => h("router-view") },
        meta: {
          title: "编辑",
          breadcrumb: { title: "编辑", name: "LotteryAddEditBasic" },
          activeMenu: "LotteryList"
        },
        children: [
          {
            path: "", name: "LotteryAddEditBasic", component: () => import("../views/add-edit-basic"),
          },
          {
            path: "prize-config/:id", name: "AddEditPrizeConfig", props: true,
            component: () => import("../views/prize-config"),
            meta: {
              title: "奖品设置",
            }
          }
        ]
      },
      {
        path: "prize-config/:id", name: "PrizeConfig", props: true,
        component: () => import("../views/prize-config"),
        meta: {
          title: "奖品设置",
        }
      },
      {
        path: "lottery-record/:id", name: "LotteryRecord", props: true,
        component: () => import("../views/lottery-record"),
        meta: {
          title: "抽奖记录",
        }
      }
    ]
  }
]
