/*
 * @Author: mulingyuer
 * @Date: 2021-08-04 10:54:13
 * @LastEditTime: 2021-08-06 15:28:45
 * @LastEditors: mulingyuer
 * @Description: 自定义指令入口
 * @FilePath: \agent-admin-vue\src\base\directives\index.js
 * 怎么可能会有bug！！！
 */
import skeleton from "./lib/skeleton/index"
import miniSrc from "./lib/mini-src"
import copy from "./lib/copy"
import focus from './lib/focus'

const directives = {
  skeleton,
  miniSrc,
  copy,
  focus
}


//批量注册
export default {
  install(Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key]);
    })
  }
}

