/*
 * @Author: mulingyuer
 * @Date: 2021-10-19 11:54:19
 * @LastEditTime: 2021-10-22 10:24:23
 * @LastEditors: mulingyuer
 * @Description:数据session
 * @FilePath: \super-admin-vue\src\base\plugins\mu-router-swatch-data\core\mu-session.js
 * 怎么可能会有bug！！！
 */
import { isString } from "@/base/utils/tool";

class MuSession {
  defaultOptions = {
    sessionKey: "mu_router_data",  //session保存的key
  }

  constructor(options = {}) {
    this.init(options);
  }

  //初始化
  init(options) {
    //存储的key
    const { sessionKey } = options;
    if (isString(sessionKey) && sessionKey.trim() !== "") {
      this.defaultOptions = sessionKey;
    }

    //初始数据-Map 有效防止重复的key
    this.data = this.getSessionData();
  }

  //获取存储的数据-Map
  getSessionData() {
    let sData = sessionStorage.getItem(this.defaultOptions.sessionKey);

    if (sData && sData.trim() !== "") {
      sData = JSON.parse(sData);
    } else {
      sData = {};
    }

    return new Map(Object.entries(sData));
  }

  //写入存储数据
  setSessionData(data) {
    //map转对象
    const obj = {};
    for (let [key, value] of data) {
      obj[key] = value;
    }

    const sData = JSON.stringify(obj);
    sessionStorage.setItem(this.defaultOptions.sessionKey, sData);

    return true;
  }

  //为真就报错
  isTrue(flag, errorText = "未标明的错误") {
    if (flag) throw new Error(errorText);
  }

  //key校验
  checkKey(key) {
    this.isTrue(!isString(key) || key.trim() === "", "传入的key必须为字符串且不能为空");
  }

  //value校验
  checkValue(value) {
    this.isTrue(value === null || typeof value === "undefined", "传入的vlaue不能为null或者undefined");
  }

  //set
  set(key, value) {
    this.checkKey(key);  //校验key
    this.checkValue(value); //校验value

    //判断key是否存在，存在就移除
    if (this.data.has(key)) this.data.delete(key);

    //写入新数据
    this.data.set(key, value);

    //更新session
    this.setSessionData(this.data);
  }

  //get
  get(key) {
    this.checkKey(key);  //校验key

    return this.data.get(key);
  }

  //has
  has(key) {
    this.checkKey(key); //校验key

    return this.data.has(key);
  }

  //size
  size() {
    return this.data.size();
  }

  //delete
  delete(key) {
    this.checkKey(key); //校验key

    const flag = this.data.delete(key);
    this.setSessionData(this.data);

    return flag;
  }

  //clear
  clear() {
    this.data.clear();  //清空

    this.setSessionData(this.data);
  }

}

export default MuSession
