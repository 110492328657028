/*
 * @Author: mulingyuer
 * @Date: 2021-09-16 15:18:10
 * @LastEditTime: 2021-09-16 15:23:21
 * @LastEditors: mulingyuer
 * @Description: 数据库
 * @FilePath: \saas-admin-vue\src\modules\gov\router\data-list\index.js
 * 怎么可能会有bug！！！
 */
import memberLibrary from "./member-library";
import newsLibrary from "./news-library";
import resourceLibrary from "./resource-library";
import enterpriseLibrary from "./enterprise-library";
import activityLibrary from "./activity-library";
import postLibrary from "./post-library";
import topicLibrary from "./topic-library"
import journalLibrary from './journal-library'
import liveLibrary from './live-library'
import channelLibrary from './channel-library'
import albumLibrary from './album-library'
import brandLibrary from './brand-library'
import multiLevelLibrary from './multi-level-library'
import courseLibrary from './course-library'
import goodsLibrary from './goods-library'
import subjectLibrary from './subject-library'
import donationLibrary from './donation-library'

export default [
  {
    path: "data-list", component: { render: h => h("router-view") },
    children: [
      //会员库
      ...memberLibrary,
      //资讯库
      ...newsLibrary,
      //资源库
      ...resourceLibrary,
      //企业库
      ...enterpriseLibrary,
      //活动库
      ...activityLibrary,
      // 帖子库
      ...postLibrary,
      // 话题库
      ...topicLibrary,
      // 相册库
      ...albumLibrary,
      // 会刊库
      ...journalLibrary,
      // 小程序直播
      ...liveLibrary,
      // 视频号
      ...channelLibrary,
      // 栏目
      ...brandLibrary,
      // 行业界别
      ...multiLevelLibrary,
      // 课程
      ...courseLibrary,
      // 商品
      ...goodsLibrary,
      // 资讯专题
      ...subjectLibrary,
      // TODO: 新闻联播
      // 捐款
      ...donationLibrary,
    ]
  }
]
