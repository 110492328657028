/*
 * @Author: mulingyuer
 * @Date: 2021-10-26 11:23:40
 * @LastEditTime: 2021-10-26 11:25:03
 * @LastEditors: mulingyuer
 * @Description:布局vuex
 * @FilePath: \super-admin-vue\src\base\store\layout.js
 * 怎么可能会有bug！！！
 */
export default {
  namespaced: true,
  state: {
    mainLoading: false, //全局loading
  },
  getters: {
    mainLoading(state) {
      return state.mainLoading;
    },
  },
  mutations: {
    setMainLoading(state, val) {
      state.mainLoading = val;
    },
  },
  actions: {

  }
}
