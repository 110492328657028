export default [
  /* 活动管理 */
  {
    path: "activity",
    name: "activity",
    component: () => import("../views/activity-list/activity-index.vue"),
    meta: { title: "" },
    redirect: { name: "cycBlacklist" },
    children: [
      {
        path: "activity-list",
        name: "cycActivity",
        component: { render: (h) => h("router-view") },
        redirect: { name: "ActivityList" },
        children: [
          {
            path: "cyc-activity",
            name: "ActivityList",
            component: () => import("../views/activity-list/list"),
          },
          {
            path: "add-activity-new/:id/:type/:create_step?",
            name: "AddActivityNew",
            component: () => import("../views/activity-detail/detail-new"),
            meta: { title: "新增活动" },
          },
          {
            path: "activity-detail-new/:id/:type/:create_step?",
            name: "ActivityDetailNew",
            component: () => import("../views/activity-detail/detail-new"),
            meta: { title: "活动详情", fitContent: true },
          },
          {
            path: "add-activity/:id/:type/:create_step?",
            name: "AddActivity",
            component: () => import("../views/activity-list/detail"),
            meta: { title: "新增活动" },
          },
          {
            path: "activity-detail/:id/:type/:create_step",
            name: "ActivityDetail",
            component: () => import("../views/activity-list/detail"),
            meta: { title: "活动详情" },
          },
          {
            path: "image-text/:id/:type/:create_step",
            name: "ImageText",
            component: () => import("../views/activity-list/image-text"),
            meta: { title: "图文列表" },
          },
          {
            path: "interaction-list/:id/:type/:create_step",
            name: "InteractionList",
            component: () => import("../views/activity-list/interaction-list"),
            meta: { title: "互动列表" },
          },
          {
            path: "sign-up-list",
            component: { render: (h) => h("router-view") },
            meta: {
              title: "报名名单",
              breadcrumb: { title: "报名名单", name: "SignUpList" },
              activeMenu: "cycActivity",
            },
            redirect: { name: "SignUpList" },
            children: [
              {
                path: "list/:id/:type",
                name: "SignUpList",
                component: () => import("../views/activity-list/sign-up-list"),
                meta: { title: "" },
              },
              {
                path: "detail/:id/:type/:jid", /* 参数必须继承列表有的 */
                name: "SignUpDetail",
                component: () =>
                  import("../views/activity-list/sign-up-detail"),
                meta: { title: "详情" },
              },
            ],
          },
          {
            path: "ranking/:id/:type/:create_step",
            name: "Ranking",
            component: () => import("../views/activity-list/ranking"),
            meta: { title: "排位信息" },
          },
          {
            path: "information/:id/:type/:create_step",
            name: "Information",
            component: () => import("../views/activity-list/information"),
            meta: { title: "信息关联" },
          },
          {
            path: "post",
            meta: {
              title: "发帖列表",
              breadcrumb: { title: "发帖列表", name: "ActivityPostList" },
              activeMenu: "cycActivity",
            },
            component: { render: (h) => h("router-view") },
            redirect: { name: "ActivityPostList" },
            children: [
              {
                path: "list/:topic_id/:id/:type/:create_step",
                name: "ActivityPostList",
                component: () =>
                  import("@/modules/community-admin/views/post-admin/list"),
              },
              {
                path: "detail/:topic_id/:id/:type",
                component: { render: (h) => h("router-view") },
                redirect: { name: "ActivityPostDetailCopy" },
                children: [
                  {
                    path: "",
                    name: "ActivityPostDetailCopy",
                    meta: { title: "详情" },
                    component: { render: (h) => h("router-view") },
                    redirect: { name: "ActivityPostDetail" },
                    children: [
                      {
                        path: "detail",
                        name: "ActivityPostDetail",
                        component: () =>
                          import(
                            "@/modules/community-admin/views/post-admin/detail"
                          ),
                      },
                      {
                        path: "add-post",
                        name: "ActivityEditPost",
                        component: () =>
                          import(
                            "@/modules/community-admin/views/post-admin/add-post"
                          ),
                        meta: { title: "编辑" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "set-comments/:topic_id/:id/:create_step/:type",
            name: "SetComments",
            component: () =>
              import("@/modules/community-admin/views/comment-admin/list.vue"),
            meta: { title: "评论列表" },
          },
          {
            path: "list-config/:id",
            name: "ListConfig",
            component: () => import("../views/activity-list/config"),
            meta: { title: "活动配置" },
          },
          {
            path: "experience",
            name: "Experience",
            component: { render: (h) => h("router-view") },
            meta: { title: "心得列表", breadcrumbName: "ExperienceList" },
            redirect: { name: "ExperienceList" },
            children: [
              {
                path: "experience-list/:id",
                name: "ExperienceList",
                component: () =>
                  import("../views/activity-list/experience-list"),
                meta: { title: "" },
              },
              {
                path: "experience-detail/:id",
                name: "ExperienceDetail",
                component: () =>
                  import("../views/activity-list/experience-detail"),
                meta: { title: "心得详情" },
              },
            ],
          },
          {
            path: "comments",
            name: "Comments",
            component: { render: (h) => h("router-view") },
            meta: {
              breadcrumb: { title: "心得评论", name: "ExperienceComments" },
            },
            children: [
              {
                path: "experience-comments/:id",
                name: "ExperienceComments",
                component: () =>
                  import("../views/activity-list/experience-comments"),
                meta: { title: "" },
              },
              {
                path: "comment-detail/:id",
                name: "CommentsDetail",
                component: () =>
                  import("../views/activity-list/comment-detail"),
                meta: { title: "评论详情" },
              },
            ],
          },
          {
            path: "data-statistics/:id",
            name: "DataStatistics",
            component: () => import("../views/activity-list/data-statistics"),
            meta: { title: "活动配置" },
          },
          {
            path: "Activity-tag/:id",
            name: "ActivityTag",
            component: () => import("../views/activity-list/activity-tag"),
            meta: { title: "活动标签" },
            
          }
        ],
      },
      {
        // 活动报名表单
        path: 'form-list',
        name: 'CycActivityForm',
        component: {render: h => h('router-view')},
        redirect: {name: 'ActivityFormList'},
        children: [
          {
            path: "all",
            name: "ActivityFormList",
            component: () => import("../views/activity-form/activity-form"),
          },
          {
            path: "design/:id",
            name: "ActivityFormDesign",
            component: () => import("../views/activity-form/activity-form-design"),
          },
        ]
      },
      {
        path: "cyc-blacklist",
        name: "cycBlacklist",
        component: () => import("../views/cyc-blacklist"),
      },
      {
        path: "config",
        name: "Config",
        component: () => import("../views/activity-config/config-index"),
        meta: { title: "", activeMenu: "cycBasisConfig" },
        redirect: { name: "cycBasisConfig" },
        children: [
          {
            path: "base-config",
            name: "cycBasisConfig",
            component: () => import("../views/activity-config/base-config"),
            // meta: {title: "基础配置"}
          },
          {
            path: "comment-config",
            name: "CommentConfig",
            component: () => import("../views/activity-config/comment-config"),
            meta: { title: "评论配置" },
          },
        ],
      },
      {
        path: "cyc-category-list",
        name: "CycActivityCategory",
        component: () => import("../views/cyc-category-list"),
        // meta: { title: "分类列表", activeMenu: "CycActivityCategory" }
      },
      {
        path: "cyc-activity-order",
        name: "cycActivityOrderList",
        component: () => import("../views/cyc-activity-order"),
        // meta: { title: "订单列表", activeMenu: "cycActivityOrderList" }
      },
      {
        path: "cyc-activity-order",
        name: "cycActivityOrder",
        component: () => import("../views/cyc-activity-order"),
        // meta: { title: "订单管理", activeMenu: "cycActivityOrder" }
      },
    ],
  },
  /* 活动相册 */
  {
    path: "activity-photo",
    component: { render: (h) => h("router-view") },
    // meta: {
    //   title: "云相册",
    //   activeMenu: "cycActivityPhoto",
    //   breadcrumb: {name: "cycActivityPhoto"}
    // },
    name: "cycActivityPhotoIndex",
    redirect: { name: "cycActivityPhoto" },
    children: [
      {
        path: "",
        name: "cycActivityPhoto",
        component: () => import("../views/activity-album/category-list"),
      },
      {
        name: "cycPhotoList",
        path: "",
        component: { render: (h) => h("router-view") },
        redirect: { name: "AlbumList" },

        meta: {
          title: "相册列表",
        },
        children: [
          {
            path: "album-list",
            name: "AlbumList",
            component: () => import("../views/activity-album/album-list"),
          },
          {
            path: "upload-images/:id/:activity_id/:type/:category_id",
            name: "uploadImages",
            component: () => import("../views/activity-album/upload-images"),
            meta: { title: "上传图片" },
          },
        ],
      },
    ],
  },
];
