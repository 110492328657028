export default [
  {
    path: "page-renovation", name: "page-renovation", component: () => import("../views/index"), meta: { title: "" },
    redirect: { name: "CycMpIcon" },
    children: [
      { path: "applets-renovation", name: "CycMpIcon", component: () => import("../views/applets-renovation"), meta: { title: "图标" } },
      { path: "other-install", name: "CycMpSettings", component: () => import("../views/other-install"), meta: { title: "其他设置" } },
      { path: "index-install", name: "CycMpIndexNavs", component: () => import("../views/index-install"), meta: { title: "首页导航设置" } },
      { path: "cyc-bottom-explain", name: "CycBottomExplain", component: () => import("../views/cyc-bottom-explain"), meta: { title: "底部说明" } }
    ]
  }
]
