<template>
  <ImagePreview v-if="showPreview" :urlList="srcList" :initialIndex="currentIndex" :on-close="closeViewer" />
</template>

<script>
import ImagePreview from "./ImagePreview.vue";
export default {
  components: { ImagePreview },
  data() {
    return {
      showPreview: false,
      srcList: [],
      currentIndex: 0,
    };
  },
  watch: {
    showPreview(val) {
      if (val) {
        this.stopMove();
      } else {
        this.Move();
      }
    },
  },
  beforeDestroy() {
    let func = function() {}
    document.removeEventListener('touchmove', func)
  },
  methods: {
    closeViewer() {
      this.showPreview = false;
    },
    //停止页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    //开启页面滚动
    Move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true });
    },
  },
};
</script>

<style>
</style>
