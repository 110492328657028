import store from "@/base/store";

export default {
  methods: {
    /* 文案统一处理 */
    handleSaaSVersionKeyword (value) {
      let str = value
      const tenantVersion = store.getters.tenant.version
      if (tenantVersion === 'school') {
        str = str.replace('入会申请', '认证申请')
        str = str.replace('会员', '校友')
        return str
      }
      return value
    }
  }
}
