export default [
  {
    path: "non-cyc-user", name: "NonCycUser", component: {render: h => h("router-view")},
    children: [
      {
        path: "list",
        name: "NonCycUserList",
        component: () => import("../views/list"),
      },
      {
        path: "settings", name: "NonCycUserAuth", component: () => import("../views/settings"),
      },
    ]
  }
]
