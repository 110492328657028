export default [
  {
    path: "gov-admin", name: "", component: { render: h => h("router-view") }, meta: { title: "", activeMenu: "AdminGov" },
    children: [
      {
        path: "gov-list", name: "AdminGov", component: () => import("../views/gov-list"),
        // meta: { title: "政务平台管理" }
      }
    ]
  }
]
