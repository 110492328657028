import {Message, MessageBox} from 'element-ui';
import store from "@/base/store";
import router from "@/base/router";


const duration = 3 * 1000;//弹窗显示时间
let majorMessageBox = false; //重要弹窗计数


//登录失效
function reRegister(message) {
  majorMessageBox = true;
  MessageBox.confirm(message, '提示', {
    confirmButtonText: '重新登录',
    showCancelButton: false,
    type: 'warning',
    showClose: false,  //不显示关闭按钮
    closeOnClickModal: false, //不能点击遮罩层关闭
    closeOnPressEscape: false,  //阻止esc关闭
  }).then(() => {
    try {
      // 清理token
      store.commit("user/setToken", "");

      //路由跳转并获取跳转前的路由地址，登录后跳转回来
      router.replace({ name: "Login", params: { wantedRoute: router.currentRoute.fullPath } }).catch(err => {
        console.log(err)
      });
      //清除计数器
      majorMessageBox = false;
    } catch (err) {
      console.error(err)
    }

  }).catch(() => {
    //清除计数器
    majorMessageBox = false;
  });
}

//重定向
function redirect(message, data) {
  majorMessageBox = true;
  MessageBox.confirm(message, '提示', {
    confirmButtonText: '前往',
    showCancelButton: false,
    type: 'warning',
    showClose: false,  //不显示关闭按钮
    closeOnClickModal: false, //不能点击遮罩层关闭
    closeOnPressEscape: false,  //阻止esc关闭
  }).then(() => {
    majorMessageBox = false; //清除计数器
    window.location.href = data;
  }).catch(() => {
    majorMessageBox = false;//清除计数器
  });
}

const RequestError = {
  //普通error对象错误
  error(message) {
    Message({
      message,
      type: 'error',
      duration
    })
  },
  //后端返回code
  codeError(res) {
    if (res.code === 1) return;

    switch (res.code) {
      case 10011: {
        /* 服务暂停 */
        // MessageBox.alert('服务暂停')
        store.commit('setStateAttr', {key: 'isAvailable', val: false})
        let routerData = router.resolve({
          name: "NotAvailable"
        });
        // console.log(routerData.href, window.location.pathname)
        if (routerData.href === window.location.pathname) return
        router.replace({name: 'NotAvailable'}).catch()
        break;
      }
      case 10010: //10010 未登录或者登录信息已过期;
        if (!majorMessageBox) reRegister(res.msg || res.message);
        break;
      case 20000: //重定向
        if (!majorMessageBox) redirect(res.msg || res.message, res.data);
        break;
      default: //其他
        if ([20010, 20020, 20030, 20040, 11000].includes(res.code)) return
        //普通错误
        Message({
          message: res.msg,
          type: 'error',
          duration,
        });
    }
  },
  //根据状态码获取报错message
  getStatusMessage(status) {
    let msg = "";
    switch (status) {
      case 400: msg = '请求错误'; break;
      case 401: msg = '未授权，请重新登录'; break;
      case 403: msg = '拒绝访问'; break;
      case 404: msg = '请求出错'; break;
      case 408: msg = '请求超时，请重试'; break;
      case 500: msg = '服务器错误'; break;
      case 501: msg = '服务未实现'; break;
      case 502: msg = '网络错误'; break;
      case 503: msg = '服务不可用'; break;
      case 504: msg = '网络超时，请重试'; break;
      case 505: msg = 'HTTP版本不受支持'; break;
      default: msg = `连接出错`;
    }
    return `${msg}（${status}）`;
  }
};


export default RequestError;



