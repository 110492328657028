export default [
  {
    path: "sign-in", component: { render: h => h("router-view") },
    // meta: { breadcrumb: { name: "IncomesSignList", title: "" } },
    children: [
      // {
        // path: "sign-in-list", component: { render: h => h("router-view") },
        // meta: { breadcrumb: { name: "IncomesSignToday", title: "签到列表" }, activeMenu: "IncomesSignToday" },
        // children: [
          {
            path: "sign-today", name: "IncomesSignToday", component: () => import("../views/sign-in/sign-today"),
            // meta: { title: "今日签到" }
          },
          {
            path: "sign-month", name: "IncomesSignMonth", component: () => import("../views/sign-in/sign-month"),
            // meta: { title: "月排行" }
          },
          {
            path: "sign-total", name: "IncomesSignTotal", component: () => import("../views/sign-in/sign-total"),
            // meta: { title: "总排行" }
          },
          {
            path: "sign-config", name: "IncomesSignConfig", component: () => import("../views/sign-in/sign-config"),
            // meta: { title: "配置" }
          }
        // ]
      // },

    ]
  }
]
