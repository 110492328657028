/**
 * 数据处理
 */
const JUMP_LINK_MODEL = {
  jump_type: '',
  jump_config: {
    system_page: '',
    need_login: 0,
    need_join: 0,
    need_formal: 0,
    name: '',
    appid: '',
    path: '',
    url: '',
    view: {
      id: '',
      is_system: 0,
      system_page: '',
    },
  },
}

function _isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function _isArray(item) {
  return item && Array.isArray(item)
}

function _isNull(item) {
  return item === undefined || item === null
}

function _fixBaseUrl(url) {
  if (typeof url === 'string') {
    if (url.startsWith('{$baseUrl}')) {
      // console.log(url.replace('{$baseUrl}', window.serverConfig.VUE_APP_BASEURL))
      return url.replace('{$baseUrl}', window.serverConfig.VUE_APP_BASEURL)
    }
  }
  return url
}

function _fixModel(model) {
  if (!_isObject(model)) return model
  let output = {}
  Object.keys(model).forEach((key) => {
    // console.log('-------------model, key---------------')
    // console.log(model, key, model[key])
    // console.log('--------------------------------------')
    if (_isNull(model[key])) {
      // output[key] = {};
      // output[key] = model[key]; // 按原样输出
      if (key === 'jump_link') {
        output[key] = _fixModel(JUMP_LINK_MODEL)
      } else {
        throw new Error('模型对象中不允许出现 undefined 或 null 值，提示：' + key)
      }
    } else if (_isObject(model[key])) {
      output[key] = _fixModel(model[key])
    } else if (_isArray(model[key])) {
      output[key] = []
      if (typeof model[key][0] === 'string' && model[key][0]) {
        // 合并字符串数组
        model[key].forEach((m) => {
          output[key].push(m)
        })
      }
    } else if (typeof model[key] === 'string') {
      output[key] = _fixBaseUrl(model[key])
    } else {
      output[key] = model[key]
    }
  })
  return output
}

/* 简单类型值合并 */
function _mergeSimpleModel(model, userData) {
  if (typeof model === 'string') model = _fixBaseUrl(model)
  let output = null
  if (_isNull(userData)) {
    /* undefined or null */
    output = model
  } else if (typeof model !== typeof userData) {
    /* type mismatched */
    output = model
  } else if (typeof model === 'string' && !userData) {
    /* empty string */
    output = model
  } else {
    output = userData
  }
  return output
}

/**
 * 根据数据模型，处理数据
 * @param{Object} model    数据模型
 * @param{Object} userData  用户数据
 * @param{Boolean} isRoot   是否处在数据模型根对象下（大雾）
 * @returns {{}|null}
 */
function mergeModel(model, userData, isRoot = true) {
  //
  if (isRoot && !_isObject(model)) {
    return null
  }
  //
  if (isRoot && !_isObject(userData)) {
    return _fixModel(model)
  }

  let isObject = _isObject(model)
  let output = isObject ? {} : null

  if (!isObject) {
    return _mergeSimpleModel(model, userData)
  }

  // 合并
  Object.keys(model).forEach((key) => {
    // console.log('-------------model, key---------------')
    // console.log(model, key, model[key])
    // console.log('--------------------------------------')
    if (_isNull(model[key])) {
      if (key === 'jump_link') {
        /* 合并模型中，jump_link都使用统一的常量，所以支持model中的jump_link值为任意值，如null */
        output[key] = mergeModel(JUMP_LINK_MODEL, userData[key])
      } else {
        throw new Error('模型对象中不允许出现 undefined 或 null 值，提示：' + key)
      }
      // output[key] = userData[key]; // 直接返回用户设置的值
    } else if (_isObject(model[key])) {
      if (_isObject(userData[key])) {
        let m = model[key]
        /* model[key] 有可能是 `{}` */
        if (key === 'jump_link') m = JUMP_LINK_MODEL
        output[key] = mergeModel(m, userData[key])
      } else {
        output[key] = _fixModel(model[key])
      }
    } else if (_isArray(model[key])) {
      // if (key === 'loginMethod') {
      //   console.log('合并数组', key, model[key], userData[key])
      //   console.log(_isArray(userData[key]))
      //   console.log(_isNull(userData[key]))
      // }

      output[key] = []
      if (_isArray(userData[key])) {
        if (model[key].length === 0) {
          // output[key] = userData[key]
          console.log(model)
          console.log(key)
          console.log(model[key])
          console.log(userData)
          console.log(userData[key])
          throw new Error(
            '模型对象中的数组必须要有一个默认格式数据才能成功合并'
          )
        } else if (userData[key].length === 0) {
          if (typeof model[key][0] === 'string' && model[key][0]) {
            // 合并字符串数组，目前这种情况也有缺点，就是不允许用户清空这个数组
            model[key].forEach((m) => {
              output[key].push(m)
            })

            // console.log(output[key])
          }
        } else {
          Object.keys(userData[key]).forEach((key2) => {
            // console.log('--------------------'+key+'---------------------');
            //   if (isRoot) {
            //     console.log('before merge: ', model[key][0], userData[key][key2]);
            // }
            let r = mergeModel(model[key][0], userData[key][key2], false)
            // console.log('after merge: ', r);
            // console.log('---------------------------------------------');
            output[key].push(r)
          })
        }
      } else {
        console.log('-----------------------------------')
        console.log(model, userData)
        console.log(model[key], userData[key])
        console.log('isArray', key)
        console.log('-----------------------------------')
      }
    } else {
      output[key] = _mergeSimpleModel(model[key], userData[key])
    }
  })
  return output
}

module.exports = mergeModel
