export default [
  {
    path: "big-data",
    // redirect: {name: "BigDataOverview"},
    component: {render: h => h("router-view")},
    // meta: {title: "大数据可视化"},
    children: [
      {
        path: '',
        name: "DataView",
        component: () => import('../views/index'),
        // meta: {
        //   breadcrumb:
        //     {title: "大数据可视化", name: "DateView"}
        // },
      },
      {
        path: 'overview',
        name: "BigDataOverview",
        component: () => import("../views/overview"),
        meta: {title: "大数据可视化"}
      },
      {
        path: "design/:tab",
        name: "BigDataDesign",
        component: () => import("../views/design")
      }
    ]
  }
]
