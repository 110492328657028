export default [
  {
    path: "multi-level", name: "MultiLevel", component: {render: h => h("router-view")},
    redirect: {name: "MultiLevelList"},
    children: [
      {
        path: "brand-module", name: "MultiLevelList", component: () => import("../views/index"),
        // meta: { title: "专题列表", activeMenu: "CycBrandList" },
        redirect: { name: "MultiLevelListIndex" },
        children: [
          {path: "list", name: "MultiLevelListIndex", component: () => import("../views/multi-level-list")},
          {
            path: "add_level/:type/:id",
            name: "addLevel",
            component: () => import("../views/add-level"),
            meta: {title: "新增"}
          },
          {
            path: "edit_level/:type/:id",
            name: "editLevel",
            component: () => import("../views/add-level"),
            meta: {title: "内容信息"}
          },
          // { path: "edit-brand/:id", name: "editBrand", component: () => import("../views/add-level"), meta: { title: "内容信息" } },
          // { path: "check_brand/:id", name: "checkBrand", component: () => import("../views/check-brand"), meta: { title: "数据列表" } },
          {
            path: "check_level/:id",
            name: "checkLevel",
            component: () => import("../views/check-level"),
            meta: {title: "数据列表"}
          },
        ]
      }
    ]
  }
]
