export default [
  {
    path: "post-library", name: "DataListPostLibrary", component: { render: h => h("router-view") },
    redirect: {
      name: "DataListPostLibraryIndex"
    },
    children: [
      {
        path: "", name: "DataListPostLibraryIndex",
        component: () => import(/* webpackChunkName: "data-list"*/ "../../views/post-library/index")
      },
      { path: "post-detail/:id", name: "GovPostDetail",
        component: () => import("../../../community-admin/views/post-admin/detail"),
        meta: { title: "详情" }
      },
    ]
  }
]
