/*
 * @Author: mulingyuer
 * @Date: 2021-08-04 11:12:22
 * @LastEditTime: 2021-08-06 15:18:57
 * @LastEditors: mulingyuer
 * @Description: 骨架动画
 * @FilePath: \agent-admin-vue\src\base\directives\lib\skeleton\index.js
 * 怎么可能会有bug！！！
 */
import "./scss/index.scss";

export default {
  //首次
  bind(el, binding) {
    if (!el.classList.contains("mu-skeleton-animated") && binding.value) {
      el.classList.add("mu-skeleton-animated")
    }

  },

  //更新
  componentUpdated(el, binding) {
    if (binding.value) {
      if (!el.classList.contains("mu-skeleton-animated")) {
        el.classList.add("mu-skeleton-animated")
      }
    } else {
      if (el.classList.contains("mu-skeleton-animated")) {
        el.classList.remove("mu-skeleton-animated")
      }
    }
  }
}
