export default {
  namespaced: true,
  state: {
    /* 辅助判断组件使用次数 */
    clone: {
      key: '',
      data: null
    },
    pageDetail: null,
    paramsData: null,
    loading: false,
    library: [],
    /* 修改前的数据 */
    backupJson: "",
  },
  mutations: {
    /**
     * 更新state数据
     * @param {String} state
     * @param {Object} param 可以是对象数组或者对象 {key: [String], val: [Object]}
     */
    setStateAttr(state, param) {
      if (param instanceof Array) {
        for (let item of param) {
          state[item.key] = item.val;
        }
      } else {
        state[param.key] = param.val;
      }
    },
  },
}
