/*
 * @Author: mulingyuer
 * @Date: 2021-08-25 10:25:42
 * @LastEditTime: 2021-09-02 11:47:14
 * @LastEditors: mulingyuer
 * @Description: 素材管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\router\material-manage.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "material-manage", component: { render: h => h("router-view") },
    // meta: {
    //   // title: "素材管理",
    //   activeMenu: "ClassroomMaterialManage",
    //   breadcrumb: { name: "ClassroomMaterialManage" }
    // },
    name: "ClassroomMaterialManage",
    redirect: {name: "ClassroomMaterialManageIndex"},
    children: [
      {
        path: "", name: "ClassroomMaterialManageIndex",
        component: () => import(/* webpackChunkName: "online-course" */ "../views/material-manage/index"),
      },
      {
        path: "add-edit", name: "ClassroomMaterialManageAddEdit",
        component:
          () => import(/* webpackChunkName: "online-course" */ "../views/material-manage/add-edit"),
        meta: { title: "素材上传" }
      },
      {
        path: "change-log/:materialId", name: "ClassroomMaterialManageChangeLog",
        component:
          () => import(/* webpackChunkName: "online-course" */ "../views/material-manage/change-log"),
        meta: { title: "变更记录" }, props: true
      }
    ]
  }
]
