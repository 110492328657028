/*
 * @Author: mulingyuer
 * @Date: 2021-08-23 17:34:49
 * @LastEditTime: 2021-08-23 17:35:10
 * @LastEditors: mulingyuer
 * @Description: 在线课堂入口
 * @FilePath: \saas-admin-vue\src\modules\online-course\config.js
 * 怎么可能会有bug！！！
 */
import router from "./router";


export default {
  router
}
