export default {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding) {
    // console.log(binding.arg, binding.value)
    /**
     * argument
     * @example
     *
     * visible: true
     * v-focus:[visible]
     *
     * v-focus
     * v-focus:true
     * v-focus:false
     */
    if (binding.arg !== undefined && (binding.arg !== '' && binding.arg !== false)) {
      // Focus the element
      el.querySelector('input, textarea').focus()
    } else if (binding.value !== undefined && binding.value === true) {
      /**
       * value
       * @example
       *
       * visible: true
       * v-focus="visible"
       */
      // Focus the element
      el.querySelector('input, textarea').focus()
    } else if (binding.arg === undefined && binding.value === undefined) {
      // Focus the element
      el.querySelector('input, textarea').focus()
    }
  }
}
