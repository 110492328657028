/*
 * @Author: mulingyuer
 * @Date: 2021-04-12 18:22:59
 * @LastEditTime: 2021-04-12 18:29:11
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\lottery\config.js
 * 怎么可能会有bug！！！
 */
import router from "./router";

export default {
  router
}
