export default {
  namespaced: true,
  state: {
    data_show: {
      home_overview: [],

      member_map_unit_user: {},
      member_map_personal_user: {},

      member_photo_unit_user: {},
      member_photo_personal_user: {},
      // 活跃会员
      member_interact: {}
    },
    colorIndex: 0,
    colorList: [
      [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ],
    ],
  },
  mutations: {
    /**
     * 更新state数据
     * @param {String} state
     * @param {Object} param 可以是对象数组或者对象 {key: [String], val: [Object]}
     */
    setStateAttr(state, param) {
      if (param instanceof Array) {
        for (let item of param) {
          state[item.key] = item.val;
        }
      } else {
        state[param.key] = param.val;
      }
    },
  },
};
