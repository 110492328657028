export default [
  // 会员权益
  {
    path: "membership", name: "membership",
    component: {render: h => h("router-view")},
    redirect: {name: "CycRightsShopList"},
    children: [
      {
        path: "shop-list",
        name: "CycRightsShopList",
        component: {render: h => h("router-view")},
        redirect: {name: "CycRightsShopListIndex"},
        children: [
          {
            path: "cycShop-list",
            name: "CycRightsShopListIndex",
            component: () => import("../views/shop-list/list"),
            meta: {alias: 'CycRightsShopList'} // 三级菜单
          },
          {
            path: "add-shop",
            name: "addShop",
            component: () => import("../views/shop-list/add-shop"),
            meta: {title: "新增"},
          },
          {
            path: "edit-shop",
            name: "editShop",
            component: () => import("../views/shop-list/edit-shop"),
            meta: {title: "编辑"},
          }
        ]
      },
      {
        path: "shop-type",
        name: "CycRightsShopCategories",
        component: () => import('../views/type-list/list')
      }
    ]
  }
]
