export default {
  /*
  * outdated
  * */
  state: {
    uploadConfig: null, //上传配置
  },
  getters: {
    uploadConfig(state) {
      return state.uploadConfig;
    }
  },
  mutations: {
    setUploadConfig(state, value) {
      state.uploadConfig = value;
    }
  },
  actions: {

  },
}
