export default [
  {
    path: "filter-word", name: "FilterWord", component: () => import("../views/index"),
    redirect: { name: "FilterWordList" },
    children: [
      { path: "filter-list", name: "FilterWordList", component: () => import("../views/filter-list"),
        // meta: { title: "敏感词列表" }
      }
    ]
  }
]
