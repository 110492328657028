export default {
  is_main_route: true, //声明是独立主页

  router: [
    {
      name: 'ArticlePreview',
      path: '/article/preview/:id/:w?',
      component: () => import('./views/detail.vue'),
      meta: {title: '文章详情'}
    }
  ]
}