/*
 * @Author: mulingyuer
 * @Date: 2021-07-27 18:20:33
 * @LastEditTime: 2021-09-09 16:05:09
 * @LastEditors: mulingyuer
 * @Description: 路由守卫
 * @FilePath: \saas-admin-vue\src\router\guard.js
 * 怎么可能会有bug！！！
 */
import store from "@/base/store";  //vuex
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { deCode } from "@/base/utils/tool";
import api from "@/base/utils/request"

//路由重定向
const routerRedirect = (next, queryData) => {
  const redirectName = queryData.redirect;
  const query = queryData.query || {};
  const params = queryData.params || {};
  next({
    name: redirectName,
    query: deCode(query),
    params: deCode(params)
  })
};

export default function (router) {
  //路由守卫 进入前
  router.beforeEach(async (to, from, next) => {
    if (to.name === 'LoginError') {
      // console.log('去错误页')
      return next()
    }
    try {
      /* 先获取 租户项目全局配置 */
      if (store.getters.tenant?.version === '') {
        await store.dispatch('GET_WEB_CONFIG')
      }
    } catch (err) {
      // console.log('获取全局配置捕获到有错误')
      if (err && err.name) next(err)
      return
    }

    /* 动态修改面包屑标题 */
    if (to.matched.length) {
      let matchedList = to.matched
      let len = to.matched.length
      if (matchedList[len - 1].meta) {
        let titleObj = matchedList[len - 1].meta.titleObj || {}
        const tenantVersion = store.getters.tenant.version
        if (tenantVersion && titleObj[tenantVersion]) {
          to.meta.title = titleObj[tenantVersion]
        }
      }
    } else if (to.name !== "404") {
      // 不存在的路由
      return next({ name: "404" });
    }
    //路由跳转之前，中止还在等待的请求
    store.getters.axiosPromiseArr.forEach(item => {
      item.routeChangeCancel && item.cancel();
    });
    // 进度条
    NProgress.start();

    // console.log('进度条出现')

    //重定向
    const queryData = to.query;
    if (queryData.redirect) return routerRedirect(next, queryData);

    // 临时token令牌免登录
    if (to.query.temporaryToken) {
      const { data } = await api({
        url: "/admin/admin/auth/loginByAccessToken",
        method: "POST",
        data: {
          access_token: to.query.temporaryToken
        }
      });
      store.commit("user/setToken", data.token);
      // console.log('临时免登录')
      return next({ name: "Home" })
    }

    //模块的路由守卫
    // if (beforeRouter.length) {
    //   beforeRouter.forEach(fn => {
    //     return fn(to, from, next, store);
    //   });
    // };

    // 服务不可用
    if (!store.getters.isAvailable) {
      // console.log('服务不可用')
      return next()
    } else {
      /**
       * 已经在暂停服务页，执行浏览器刷新，需要自动跳转到首页
       * */
      if (store.getters['user/token'] && to.name === 'NotAvailable') {
        // console.log('刷新服务状态')
        return next({ name: "Home" });
      }
    }

    //未登录进入private权限路由自动跳至登录
    if (!store.getters['user/token'] && to.matched.some(r => r.meta.private)) {
      // console.log('未登录，去登录')
      return next({
        name: "Login",
        params: {
          wantedRoute: to.fullPath,
        }
      });
    };

    //已登录进入guest权限路由自动跳首页
    if (store.getters['user/token'] && to.matched.some(r => r.meta.guest)) {
      // console.log('自动去首页')
      return next({ name: "Home" });
    };

    // console.log(to, to.name)

    if (to.fullPath === '/' && !to.name) {
      return next({name: "Home"})
    }

    // console.log('不知道去哪了', from.name, to.name)
    next()
  });

  //路由进入后
  router.afterEach(() => {
    NProgress.done();
  });

}
