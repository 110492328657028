/*
 * @Author: mulingyuer
 * @Date: 2021-07-06 12:02:53
 * @LastEditTime: 2021-07-07 16:39:02
 * @LastEditors: mulingyuer
 * @Description: 证书管理
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: 'region-setting',   
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: 'detail',
        component: () => import('../views/detail'),
        name: 'RegionSetting',
      },
    ],
  },
]
