export default [
  {
    path: "multi-level-library", component: { render: h => h("router-view") },
    name: "DataListMultiLevel",
    redirect: {name: "DataListMultiLevelIndex"},
    children: [
      { path: "list", name: "DataListMultiLevelIndex", component: () => import("../../views/multi-level-library/index"),
      },
    ]
  }
]
