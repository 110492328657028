// 全局过滤器

// 无内容占位符
const placeholder = (value) => {
  const text = "--";
  if (!value && value !== 0) return text;
  if (typeof value === "string" && value.trim() === "") return text;
  return value;
};



export default { placeholder }