import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import merge from 'merge'
import { moduleStore, persistedStore } from "../config"
import layout from "./layout";
import socket from "./socket"

Vue.use(Vuex)

//模块入口
let modules = {};
const outhModules = { layout,socket };
//插入
if (moduleStore.length) {
  moduleStore.forEach(item => {
    for (let key in item) {
      modules[key] = item[key]
    }
  });
  //其他
  for (let key in outhModules) {
    modules[key] = outhModules[key]
  }
}

export default new Vuex.Store({
  state: {
    isCollapse: false,  //是否收缩侧边栏
    docTitle: "", //页面标题
    refresh: false, //是否刷新路由页
    axiosPromiseArr: [], //请求的axios
    // 用来全局停止上个音频播放的
    lastAudioId: '',
    /* 超级后台-服务暂停开关 */
    isAvailable: true,
    serverTimestamp: 0,
    localTimestamp: 0,
    pubKey: ''
  },
  getters: {
    isCollapse(state) {
      return state.isCollapse;
    },
    docTitle(state) {
      return state.docTitle;
    },
    refresh(state) {
      return state.refresh;
    },
    axiosPromiseArr(state) {
      return state.axiosPromiseArr;
    },
    lastAudioId: state => {
      return state.lastAudioId;
    },
    isAvailable: state => {
      return state.isAvailable
    },
    serverTimestamp(state) {
      return state.serverTimestamp
    },
    localTimestamp: state => state.localTimestamp,
    pubKey: state => state.pubKey
  },
  mutations: {
    /**
     * 更新state数据
     * @param {String} state
     * @param {Object} param 可以是对象数组或者对象 {key: [String], val: [Object]}
     */
    setStateAttr(state, param) {
      if (param instanceof Array) {
        for (let item of param) {
          state[item.key] = item.val;
        }
      } else {
        state[param.key] = param.val;
      }
    },
    setIsCollapse(state, value) {
      state.isCollapse = value;
    },
    setDocTitle(state, value) {
      state.docTitle = value;
    },
    setRefresh(state, value) {
      state.refresh = value;
    },
    pushAxiosPromiseArr(state, value) {
      state.axiosPromiseArr.push(value);
    },
    setAxiosPromiseArr(state, value) {
      state.axiosPromiseArr = value;
    },
    removeAxiosPromiseArrIndex(state, index) {
      state.axiosPromiseArr.splice(index, 1);
    },
    setLastAudioId(state, value) {
      state.lastAudioId = value;
    }
  },
  actions: {
  },
  modules,
  plugins: [createPersistedState({
    //指定持久化对象, return出的首个{}等同于state，val就是local保存的内容
    reducer(val) {
      const defaultPersisted = {
        user: {
          token: val.user.token,
          login_method: val.user.login_method
        }
      };
      //模块化加载到的配置
      const modulePersistedStore = persistedStore.map(fn => fn(val));
      merge.recursive(defaultPersisted, ...modulePersistedStore);

      return defaultPersisted;
    }
  })]
})
