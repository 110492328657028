import api from '@/base/utils/request'
export default {
  namespaced: true,
  state: {
    // 连接id
    client_id: '',
    // 连接检测定时器
    webSocketInterval: null,
    webSocketObject: null,

    messagesData: null
  },
  getters: {},
  mutations: {
    initSocket(state, callback) {
      if (state.webSocketObject) {
        console.log('服务连接中->',state.webSocketObject,);
        return
      }
      if(!window.serverConfig.VUE_APP_SOCKETURL){
        this._vm.$message.error('未配置webSocket地址')
        return
      }
      state.webSocketObject = new WebSocket(
        window.serverConfig.VUE_APP_SOCKETURL
      )
      state.webSocketObject.onopen = (e) => {
        this.commit('socket/webSocketOnOpen', e)
      }
      state.webSocketObject.onmessage = (e) => {
        this.commit('socket/webSocketOnMessage', e)
      }
      state.webSocketObject.onerror = (e) => {
        this.commit('socket/webSocketOnError', e)
      }
      state.webSocketObject.onclose = (e) => {
        this.commit('socket/webSocketOnClose', e)
      }
    },
    webSocketOnOpen(state, e) {
      // this._vm.$message.success('与服务端连接打开')
      console.log('与服务端连接打开->', new Date().toLocaleString())
    },
    webSocketOnMessage(state, message) {
      const data = JSON.parse(message.data)
      console.log('来自服务端的消息->', data)
      switch (data.type) {
        case 'init': {
          state.webSocketInterval = setInterval(() => {
            console.log('ping', new Date().toLocaleString())
            if (state.webSocketObject) {
              state.webSocketObject.send(JSON.stringify({ type: 'ping' }))
            } else {
              this.commit('socket/close')
            }
          }, 25000)
          api({
            url: '/admin/admin/wss/bind',
            method: 'post',
            data: {
              client_id: data.client_id,
            },
          }).then((res) => {
            state.client_id = data.client_id

            // console.log(res.data.messages_count) /* 结构如：{[menuObj.url]: 22} */
            // console.log(JSON.stringify(res.data.messages_count)) /* 结构如：{[menuObj.url]: 22} */
            state.messagesData = res.data.messages_count;
          })
          break
        }
        default: {
          this._vm.$bus.emit(data.type, data.data)
        }
      }
    },
    webSocketOnError(state, e) {
      console.log('与服务端连接异常->',new Date().toLocaleString())
      this.commit('socket/close')
    },
    webSocketOnClose(state, e) {
      console.log('与服务端连接关闭->', `code:${e.code}`, new Date().toLocaleString())
      this.commit('socket/close')
      if (e.code !== 1000) {
        this.commit('socket/initSocket')
      } else {
        this._vm.$message.success('服务端连接关闭')
      }
    },
    closeSocket(state) {
      state.webSocketObject.close()
      api({
        url: '/admin/admin/wss/unbind',
        method: 'post',
      }).then(() => {
        console.log('unbind success! ')
      }).catch(() => {})
    },
    close(state){
      clearInterval(state.webSocketInterval)
      state.webSocketObject = null
      state.webSocketInterval = null
      state.client_id = ''
    },
    updateMessageData(state, data) {
      if (data.key !== undefined && data.val !== undefined) {
        const oldVal = state.messagesData[data.key]
        state.messagesData[data.key] = data.val;
        console.log('消息（', data.key, '）更新成功：', oldVal, '=>', data.val)
      }
    }
  },
  actions: {},
}
