export default [
  {
    path: "live-library", component: { render: h => h("router-view") },
    name: "DataListWxLiveLibrary",
    redirect: {name: "DataListWxLiveLibraryIndex"},
    children: [
      { path: "list", name: "DataListWxLiveLibraryIndex", component: () => import("../../views/live-library/index"),
      },
    ]
  }
]
