export default [
  {
    path: "course-library", component: { render: h => h("router-view") },
    name: "DataListCourse",
    redirect: {name: "DataListCourseIndex"},
    children: [
      { path: "list", name: "DataListCourseIndex", component: () => import("../../views/course-library/index"),
      },
    ]
  }
]
