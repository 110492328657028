export default [
  {
    path: 'invite-members',
    name: 'Invitemembers',
    component: () => import('../views/index'),
    // meta: { title: "", activeMenu: "CycInviteMembersIncome" },
    redirect: { name: 'CycInviteMembersIncome' },
    children: [
      {
        path: 'benefits',
        name: 'CycInviteMembersIncome',
        component: { render: (h) => h('router-view') },
        // meta: { title: "会员收益" },
        redirect: { name: 'CycInviteMembersIncomeIndex' },
        children: [
          {
            path: '',
            name: 'CycInviteMembersIncomeIndex',
            component: () => import('../views/benefits'),
          },
          {
            path: 'menber-detail/:id',
            name: 'CycInviteMembersDetail',
            component: () => import('../views/benefits/menberDetail'),
            meta: { title: '会员详情' },
          },
        ],
      },
      {
        path: 'introduce-page',
        name: 'CycIntroducePage',
        component: () => import('../views/CycIntroducePage/index'),
        redirect: { name: 'CycIntroducePageBase' },
        children: [
          {
            component: () => import('../views/CycIntroducePage/base'),
            meta: { title: '基本设置' },
            name: 'CycIntroducePageBase',
            path: 'base',
          },
          {
            component: () => import('../views/CycIntroducePage/members'),
            meta: { title: '核心成员' },
            name: 'CycIntroducePageMembers',
            path: 'members',
          },
          {
            component: () => import('../views/CycIntroducePage/other_members'),
            meta: { title: '商会成员' },
            name: 'CycIntroducePageOtherMembers',
            path: 'other-members',
          },
          {
            component: () => import('../views/CycIntroducePage/activity'),
            meta: { title: '商会活动' },
            name: 'CycIntroducePageActivity',
            path: 'activity',
          },
          {
            component: () => import('../views/CycIntroducePage/cyc_circle'),
            meta: { title: '商会资源' },
            name: 'CycIntroducePageCycCircle',
            path: 'cyc-circle',
          },
          {
            component: () => import('../views/CycIntroducePage/honor'),
            meta: { title: '商会荣誉' },
            name: 'CycIntroducePageHonor',
            path: 'honor',
          },
          {
            component: () => import('../views/CycIntroducePage/requirement'),
            meta: { title: '加入要求' },
            name: 'CycIntroducePageRequirement',
            path: 'requirement',
          },
          {
            component: () => import('../views/CycIntroducePage/rights'),
            meta: { title: '会员权益' },
            name:'CycIntroducePageRights',
            path: 'rights',
          },
          {
            component: () => import('../views/CycIntroducePage/guide'),
            meta: { title: '入会引导设置' },
            name:'CycIntroducePageGuide',
            path: 'guide',
          }
        ],
      },
      {
        path: 'rule-list',
        name: 'CycInviteMembersConfig',
        component: () => import('../views/rule-list'),
        meta: { title: '规则配置' },
      },
      {
        path: 'template',
        name: 'CycInviteMembersTmpl',
        component: () => import('../views/template-setting'),
        meta: { title: '模板设置' },
      },
    ],
  },
]
