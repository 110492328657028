export default [
  {
    path: 'message',
    name: 'Message',
    component: { render: (h) => h('router-view') },
    // meta: { title: "", activeMenu: "SignupList" },
    redirect: { name: 'MessageConfig' },
    children: [
      {
        path: 'message_config',
        name: 'message_config',
        component: () => import('../views/message-config'),
      },
    ],
  },
]
