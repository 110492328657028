export default [
  {
    path: "blessing", component: { render: h => h("router-view") }, meta: { breadcrumb: { title: "", name: "Blessing" } },
    children: [
      {
        path: "blessing-admin", component: { render: h => h("router-view") },
        // meta: {
        //   activeMenu: "BlessingRuleList",
        //   breadcrumb: { title: "祝福管理" }
        // },
        children: [
          {
            path: "rule-list", component: { render: h => h("router-view") },
            name: "BlessingRuleList",
            // meta: { title: "规则列表", breadcrumb: { name: "BlessingRuleList" } },
            redirect: {name: "BlessingRuleListIndex"},
            children: [
              {
                path: "blessing-rule-list", name: "BlessingRuleListIndex",
                component: () => import("../views/rule-list/list"),
              },
              {
                path: "add-view-edit/:type/:id", name: "addEdit",
                component: () => import("../views/rule-list/add-view-edit"),
                meta: { title: "新增" }
              },
              // {
              //   path: "edit_company", name: "editCompany",
              //   component: () => import("../views/list/add"),
              //   meta: { title: "编辑" }
              // },
            ]
          },
          {
            path: "template-list", component: { render: h => h("router-view") },
            // meta: {
            //   title: "模板列表",
            //   breadcrumb: { name: "BlessingTmplList" }
            // },
            name: "BlessingTmplList",
            redirect: {name: "BlessingTmplListIndex"},
            children: [
              {
                path: "blessing-template-list", name: "BlessingTmplListIndex",
                component: () => import("../views/template-list/list"),
                meta: { title: "" }
              },
              {
                path: "template-detail/:type/:id", name: "templateDetail",
                component: () => import("../views/template-list/detail"),
                meta: { title: " 详情" }
              },
            ]
          },
          {
            path: "tmpl-category",
            // component: { render: h => h("router-view") },
            // meta: { title: "模板分类", breadcrumb: { name: "BlessingTmplCategoryList" } },
            // children: [
            //   {
            //     path: "category-list",
            name: "BlessingTmplCategoryList",
                component: () => import("../views/tmpl-category/list"),
              // },
            // ]
          },
        ]
      },
    ]
  }
]
