export default {
  namespaced: true,
  state: {
    pid: 0,
    src: '', // 可能是字符串，字符串数组，对象数组
    show: false,
    ratioList: [],
    ratio: '',
    mediaKey: '',
    directly: false
  },
  getters: {
    src(state) {
      return state.src;
    },
    show(state) {
      return state.show;
    },
    pid(state) {
      return state.pid;
    }
  },
  mutations: {
    setSrc(state, value) {
      state.src = value;
    },
    setShow(state, value) {
      state.show = value;
    },
    setPid(state, value) {
      state.pid = value;
    },
    setRatio(state, value) {
      state.ratio = value
    },
    setRatioList(state, value) {
      state.ratioList = value.map(v => ({
        ratio: typeof v === 'string' ? v : v.ratio,
        desc: typeof v === 'string' ? '' : v.desc
      }))
    },
    setMediaKey(state, mediaKey) {
      state.mediaKey = mediaKey
    },
    setDirectly(state, directly) {
      state.directly = directly
    }
  },
  actions: {
    openMediaCropDialog({ commit }, data) {
      // console.log(data)

      // if (!data.ratio) throw new Error('请传入 ratio')
      // if (!data.ratioList?.length) throw new Error('请传入 ratioList')

      commit('setPid', data.pid)
      commit('setSrc', data.imgSrc) // 可能是数组
      commit('setShow', true)
      commit('setRatio', data.ratio || '')
      commit('setRatioList', data.ratioList || [])
      commit('setMediaKey', data.mediaKey || '')
      commit('setDirectly', data.directly || false) // 直接裁剪一张选好的图，忽略张数校验
    }
  }
}
