export default [
  {
    path: 'platform-promotion',
    name: 'PlatformPromotion',
    component: { render: (h) => h('router-view') },
    // meta: { title: "" },
    redirect: { name: 'InfoAuth' },
    children: [
      {
        path: 'infoAuth',
        name: 'InfoAuth',
        component: () => import('../views/info-auth.vue'),
      },
    ],
  },
]
