export default [
  {
    path: 'subject-library',
    name: 'DataListArticleSubject',
    component: { render: h => h("router-view") },
    redirect: {name: 'DataListArticleSubjectIndex'},
    children: [
      {
        path: '',
        name: "DataListArticleSubjectIndex",
        component: () => import("../../views/subject-library/index")
      }
    ]
  }
]
