export default [
  {
    path: "shop",
    name: "shop",
    component: { render: (h) => h("router-view") },
    // meta: { title: "" },
    redirect: { name: "Goods" },
    children: [
      {
        path: "goods",
        name: "ShopList",
        component: { render: (h) => h("router-view") },
        // meta: { title: "商品列表", breadcrumbName: "GoodsShop" },
        redirect: { name: "ShopListIndex" },
        children: [
          {
            path: "",
            name: "ShopListIndex",
            component: () => import("../views/shop-list.vue"),
            // meta: { title: "" },
          },
          {
            path: "goodes-detail/:id",
            name: "GoodsDetail",
            component: () => import("../views/goods-detail.vue"),
            meta: { title: "商品详情" },
          },
        ],
      },
      {
        path: "product-category",
        name: "WxStoreCategory",
        component: () => import("../views/goods-category"),
        meta: { title: "分类管理" },
      },
      {
        path: "shop_config",
        name: "ShopConfig",
        component: () => import("../views/shop-config"),
        // meta: { title: "设置" },
      },
      {
        path: "shop_refund_list",
        name: "ShopRefundList",
        component: { render: (h) => h("router-view") },
        // meta: { title: "退款管理" },
        redirect: { name: "ShopRefund" },
        children: [
          {
            path: "refund-detail/:id",
            name: "OrderRefundDetail",
            component: () => import("../views/refund-detail.vue"),
            meta: { title: "售后详情", activeMenu: "ShopRefundList" },
          },
          {
            path: "shop_refund",
            /* api /menu/index 没有对应的url */
            name: "ShopRefund",
            component: () => import("../views/shop-refund-list"),
            // meta: { title: "售后列表" },
            // meta: { title: "售后列表", activeMenu: "ShopRefundList" },
          },
        ],
      },
      {
        path: "shop_order",
        name: "ShopOrder",
        component: { render: (h) => h("router-view") },
        // meta: { title: "订单/评价" },
        redirect: { name: "ShopOrderList" },
        // meta: {activeMenu: "ShopOrder"},
  
        children: [
          // {
          //   name: "ShopOrderList", path: '',
          //   redirect: { name: "ShopOrderListIndex" },
          //   component: { render: (h) => h("router-view") },
          //   meta: {activeMenu: "ShopOrder"},
          //   children: [
          //     {
          //       path: "shop_order_list",
          //       name: "ShopOrderListIndex",
          //       component: () => import("../views/shop-order-list"),
          //       // meta: { title: "订单列表" },
          //     },
          //
          //   ]
          // },
          {
            path: "shop_orders",
            name: "ShopOrderList",
            component: () => import("../views/shop-order-list"),
            // meta: { title: "订单列表" },
            meta: {
              alias: 'ShopOrder' // 消息数 节点 Url
            }
          },
          {
            path: 'order-detail',
            component: { render: (h) => h("router-view") },
            meta: {
              // breadcrumb: {title: "订单列表", name: "ShopOrderList"},
              activeMenu: "ShopOrder"
            },
            children: [
              {
                path: ":id",
                name: "GoodsOrderDetail",
                component: () => import("../views/order-detail.vue"),
                meta: { title: "订单详情", },
              },
            ]
          },
          {
            path: "shop_comment_list",
            name: "ShopCommentList",
            component: () => import("../views/shop-comment-list"),
            // meta: { title: "评论列表" },
          },
        ],
      },
    ],
  },
];
