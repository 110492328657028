export default [
  {
    path: 'wechat-app',
    component: { render: (h) => h('router-view') },
    meta: { title: '' },
    children: [
      {
        path: 'base-message',
        component: { render: (h) => h('router-view') },
        // component: () => import("../views/base-message/index"),
        // meta: { breadcrumb: { title: "", name: "AppletBaseInfo" }, activeMenu: "AppletBaseInfo" },
        redirect: { name: 'AppletBaseInfo' },
        children: [
          {
            path: 'base-info',
            name: 'AppletBaseInfo',
            component: () => import('../views/base-message/base-info'),
            meta: { title: '基本信息' },
          },
          {
            path: 'version-setting',
            name: 'VersionSetting',
            component: () => import('../views/base-message/version-setting'),
            meta: { title: '版本设置' },
          },
          {
            path: 'version-settings',
            name: 'AppletVersionSetting',
            component: () => import('../views/base-message/version-setting'),
            meta: { title: '版本设置' },
          },
        ],
      },
      {
        path: 'data-assistant',
        name: 'DataAssistant',
        component: () => import('../views/data-assistant'),
        // meta: { title: "数据助手" },
        // children: [
        //   { path: "spirit_list", name: "AppletBaseInfo", component: () => import("../views/spirit-list"), meta: { title: "" } },
        //   { path: "add-spirit/:type/:id", name: "AddSpirit", component: () => import("../views/add-spirit"), meta: { title: "新增" } },
        // ]
      },
      {
        path: 'applet-subscribe',
        name: 'AppletSubscribe',
        component: () => import('../views/subscribe-message'),
        meta: { title: '订阅消息' },
      },
      {
        path: 'wx_template_message',
        name: 'WxTemplateMessage',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'WxTemplateMessageConfig' },
        children: [
          {
            path: 'wx_template_message_config',
            name: 'WxTemplateMessageConfig',
            component: () => import('../views/template-message-config'),
            meta: { title: '模版消息设置' },
          },
          {
            path: 'wx_template_message_list',
            name: 'WxTemplateMessageList',
            component: () => import('../views/template-subscribe-message'),
            meta: { title: '模版消息' },
          },
        ],
      },
      {
        path: 'admin-wechat-live',
        name: 'AdminWechatLive',
        component: () => import('../views/live-list.vue'),
        // meta: { title: "小程序直播" }
      },
      {
        path: 'wx-common-config',
        name: 'WechatPublicConfig',
        component: () => import('../views/wx-common-config.vue'),
        meta: { title: '微信公众号配置' },
      },
    ],
  },
]
