export default {
  state: {
    sidebarData: [],
  },
  getters: {
    sidebarData(state) {
      return state.sidebarData;
    },
  },
  mutations: {
    setSidebarData: (state, value) => {
      state.sidebarData = value
      sessionStorage.setItem('sidebarData', JSON.stringify(value))
    }
  }
}
