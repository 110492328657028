/*
 * @Author: mulingyuer
 * @Date: 2021-04-06 15:39:02
 * @LastEditTime: 2021-08-06 11:43:48
 * @LastEditors: mulingyuer
 * @Description: 缩略图
 * @FilePath: \agent-admin-vue\src\base\mixins\image.js
 * 怎么可能会有bug！！！
 */
import { fixImageUrl } from "@/base/utils/tool";

export default {
  methods: {
    // 等比缩略图方法 url地址，{w:200,h:200}可不传，默认高优先，且默认值h为200
    fixImageUrl
  }
}
