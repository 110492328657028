export default [
  {
    path: "user", name: "User", component: () => import("../views/index"), meta: { title: "" },
    redirect: { name: "UserIndex" },
    children: [
      { path: "Index", name: "UserIndex", component: () => import("../views/user-index"), meta: { title: "个人中心" }}
    ]
  },
  {
    path: 'iframe', name: "Iframe", component: () => import('../views/iframe')
  },
  {
    path: 'user-center-apps', name: 'UserCenterApps', component: () => import('../views/iframe')
  },
  {
    path: 'user-center-extras', name: 'UserCenterExtras', component: () => import('../views/iframe')
  },
  {
    path: 'user-center-extras-sms/:project_id', name: 'UserCenterExtrasSms', component: () => import('../views/iframe')
  },
  {
    path: 'user-center-project-manage', name: 'MyProjectManage', component: () => import('../views/iframe')
  },
  {
    path: 'user-center-project-renew', name: 'MyProjectRenew', component: () => import('../views/iframe')
  }
]
