export default [
  {
    path: "organize-show", name: "CycOrganizationShow", component: { render: h => h("router-view") },
    // meta: {title: ""},
    children: [
      {
        path: "", name: "", component: { render: h => h("router-view") },
        // meta: {title: "",},
        children: [
          {
            path: "base-config", name: "CycOrganizationShowConfig111",
            component: { render: h => h("router-view") },
            redirect: {name: "CycOrganizationShowConfig"},
            meta: {title: "基础设置"},
            children: [
              // {
              //   path: "", name: "CycOrganizationShowConfig", component: () => import("../views/base-config"),
              //   meta: {title: ""},
              // },
              {
                path: "position-edit/:id", name: "CycOrganizationPositionShowConfig", component: () => import("../views/base-config-edit"),
                meta: {title: "编辑职务"}
              }
            ]
          },
        ]
      }
    ]
  }
]
