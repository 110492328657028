/*
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:30:00
 * @LastEditTime: 2021-04-08 18:39:04
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\vote\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: 'college',
    component: { render: (h) => h('router-view') },
    name: 'College',
    redirect: { name: 'CollegeTeacherList' },
    children: [
      {
        path: 'teacher',
        name: 'CollegeTeacherList',
        redirect: {name: 'CollegeTeacherListIndex'},
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: 'list',
            name: 'CollegeTeacherListIndex',
            component: () => import('../views/college-teacher-list.vue'),
          },
          {
            path: 'course',
            name: 'CollegeTeacherCourseList',
            component: () => import('../../../modules/online-classroom/views/course-manage/index'),
            meta: {}
          },
          {
            path: 'audio',
            name: 'CollegeTeacherAudioList',
            component: () => import('../views/college-audio-list.vue'),
            meta: {}
          },
        ]
      },
      {
        path: '/teacherDetail/:id',
        name: 'AddCollegeTeacher',
        component: () => import('../views/teacher-detail'),
        meta: { title: '老师详情' },
      },
      {
        path: 'college-category-list',
        name: 'CollegeCategoryList',
        component: () => import('../views/learn-category-list'),
        meta: { title: '分类' },
      },
      {
        path: 'college-audio-list',
        name: 'CollegeAudioList',
        component: () => import('../views/college-audio-list.vue'),
      },
      {
        path: '/audioDetail/:id',
        name: 'AddCollegeAudio',
        component: () => import('../views/audio-detail'),
        meta: { title: '音频详情' },
      },
      {
        path: 'college-ele-book-list',
        name: 'CollegeEleBookList',
        meta: { title: '电子书列表' },
        component: () => import('../views/college-ele-book-list.vue'),
      },
      {
        path: '/eleBookDetail/:id',
        name: 'AddCollegeEleBook',
        component: () => import('../views/ele-book-detail'),
        meta: { title: '电子书详情' },
      },
      {
        path: 'code-manage',
        name: 'CollegeCodeManage',
        component: () => import('../views/college-code-manage')
      },
      {
        path: 'college-study-stats',
        name: 'CollegeStudyStats',
        component: () => import('../views/college-study-stats'),
        meta: { title: '学习统计' },
      },
      {
        path: 'college-study-overview',
        name: 'CollegeStudyOverview',
        component: () => import('../views/college-study-overview'),
        meta: { title: '实时概况' },
      },
      {
        path: 'comment',
        name: 'CollegeAudioCommentList',
        // component: () => import('@/modules/article-admin/views/comment-list.vue')
        component: () => import('../views/comment-list.vue')
      }
    ],
  },
]
