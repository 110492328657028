import axios from 'axios'
// import qs from "qs";
import store from '@/base/store'
import RequestError from './request-error'
import { randomString, timestamp } from './tool'
import mainLoading from './loading'

const CancelToken = axios.CancelToken

//请求记录
const requestArr = []

//创建api实例
let api = axios.create({
  baseURL: window.serverConfig.VUE_APP_BASEURL,
  timeout: 25000, // 请求超时时间
  routeChangeCancel: true, //允许切换路由时取消请求
  notCancel: false, //不允许取消请求
  token: true, //是否携带token
  allowMainLoading: false, //是否使用全局loading
  errorToast: true,
})

// request拦截器
api.interceptors.request.use((config) => {
  //自定义baseUrl配置
  if (process.env.NODE_ENV == 'development') {
    if (config.mock) {
      config.baseURL = '/mock'
    } else {
      config.baseURL = '/api'
    }
  }
  //自动添加头信息
  addHeader(config)
  //开始loading
  if (config.allowMainLoading) mainLoading.startLoading()
  //记录请求并取消重复请求
  addRequest(config)

  return config
})

// response拦截器
api.interceptors.response.use(
  //2xx进入
  (response) => {
    //请求配置存在的话
    if (response.config) {
      const config = response.config
      removeRequest(config) //记录删除
      //loading加载完毕清除
      config.allowMainLoading && mainLoading.endLoading()
    }

    //内容处理
    if (response.status === 200) {
      const res = response.data
      //code不为1
      if (res.code !== 1) {
        if (response.config && response.config.errorToast !== false) {
          //错误提示
          RequestError.codeError(res)
        }
        return Promise.reject(res)
      } else {
        return res
      }
    } else {
      RequestError.error(
        response?.data.msg || RequestError.getStatusMessage(response.status)
      )
      return Promise.reject(response)
    }
  },
  //非2xx进入
  (error) => {
    //如果请求配置存在
    if (error.config) {
      const config = error.config
      removeRequest(config) //记录删除
      //loading加载完毕清除
      config.allowMainLoading && mainLoading.endLoading()
      removeRequest(error.config) //记录删除
    }

    //略过取消请求的报错,添加一个标识
    if (axios.isCancel(error)) {
      error.selfCancel = true

      //判断取消的请求是否还存在新的请求
      const url = error.message //取消请求传的是请求的url
      const findIndex = requestArr.findIndex((item) => item.url === url)
      if (findIndex !== -1) {
        error.offLoading = false
      } else {
        error.offLoading = true
      }
    } else {
      //错误提示处理
      if (error.response) {
        if (error.response.status === 413) {
          /** 413 Request Entity Too Large */
          return Promise.reject(error.response)
        }
        RequestError.error(RequestError.getStatusMessage(error.response.status))
      } else {
        RequestError.error(error.message)
      }
    }

    return Promise.reject(error)
  }
)

//request自动添加头信息
function addHeader(config) {
  //token
  if (config.token) {
    const key = window.serverConfig.VUE_APP_HEADER_TOKEN
    const token = store.getters['user/token']
    config.headers[key] = token || ''
  }

  if (process.env.NODE_ENV === 'development')
    config.headers['api-target'] = window.serverConfig.VUE_APP_BASEURL

  // 多语言
  if (store?.state.tenant?.project_type !== undefined) {
    config.headers.locale = [1, 2].includes(store.state.tenant.project_type)
      ? 'zh-hk'
      : 'zh-cn'
  }

  // 用于短信验证码
  const n = timestamp()
  const l = store.getters.localTimestamp
  const ser = store.getters.serverTimestamp
  config.headers['timestamp'] = n - l + ser
  config.headers['nonce'] = randomString(16)

  //其他头信息
  if (window.serverConfig.VUE_APP_OTHER_HEADERS) {
    const otherHeaders = window.serverConfig.VUE_APP_OTHER_HEADERS
    for (let [key, value] of Object.entries(otherHeaders)) {
      if (value) config.headers[key] = value
    }
  }
}

//记录请求+取消之前未完成的相同请求
function addRequest(config) {
  if (config.notCancel) return

  //获取请求协议+路径
  const requestUrl = `${config.method} ${config.url}`
  //查找取消请求标识中是否存在相同的requestUrl
  const cancelIndex = requestArr.findIndex((item) => {
    return item.url === requestUrl
  })
  //存在就说明重复了
  if (cancelIndex > -1) {
    requestArr[cancelIndex].cancel(requestUrl) //取消
    requestArr.splice(cancelIndex, 1) //删除该标识
    //loading
    config.allowMainLoading && mainLoading.endLoading()
  }

  //当前请求添加取消方法
  const source = CancelToken.source()
  config.cancelToken = source.token
  //添加响应拦截中成功后删除该标识的判断条件
  config.requestUrl = requestUrl
  //记录本次取消的请求
  requestArr.push({
    url: requestUrl,
    cancel: source.cancel,
    routeChangeCancel: config.routeChangeCancel,
    notCancel: config.notCancel,
    allowMainLoading: config.allowMainLoading,
    config,
  })
}

//删除请求中的记录
//config不一定是请求的config，还有可能是requestArr中的每个对象
function removeRequest(config) {
  //存在的下标
  const cancelIndex = requestArr.findIndex((item) => {
    return item.url === config.requestUrl
  })

  //存在就移除记录
  if (cancelIndex > -1) {
    requestArr.splice(cancelIndex, 1)
  }
}

//路由翻页取消请求
function routerCancelRequest() {
  const cancelArr = [] //被取消的请求记录
  requestArr.forEach((item) => {
    if (!item.notCancel) {
      //不允许取消
      removeRequest(item) //移除记录，不一定用得上
    } else {
      //允许取消，允许切换路由取消的请求
      if (item.routeChangeCancel) cancelArr.push(item)
    }
  })
  //取消后自己进行删除，因为取消后直接进入api.interceptors.response.use的error处理
  //但是error对象是取消错误对象，它没有config属性
  cancelArr.forEach((item) => {
    //先移除记录，保证请求的守卫触发一定不存在该条记录了
    removeRequest(item)
    item.cancel(item.url) //取消
    item.allowMainLoading && mainLoading.endLoading() //loading
  })
}

export { requestArr, api, routerCancelRequest }

export default api
