import router from "./router/index";
import appDesign from './store'

export default {
  router,
  store: {
    appDesign
  },
  storePersisted: function (val) {
    return {
      appDesign: {
        /** @example
         * val[namespace][state]
         */
        // foo: val.appDesign.foo
        lastFetchTimestampRecord: val.appDesign.lastFetchTimestampRecord,
        mePageLinkOptions: val.appDesign.mePageLinkOptions,
        onlineLearningOptions: val.appDesign.onlineLearningOptions,
        activityPhotoCategoryOptions: val.appDesign.activityPhotoCategoryOptions,
        spiritColumnOptions: val.appDesign.spiritColumnOptions,
        brandCategoryOptions: val.appDesign.brandCategoryOptions,
        specialArticleCategory: val.appDesign.specialArticleCategory,
        articleCategory: val.appDesign.articleCategory,
        shopCategory: val.appDesign.shopCategory,
        memberIndexQuickEnters: val.appDesign.memberIndexQuickEnters,
      }
    }
  }
}
