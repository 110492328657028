import api from '@/base/utils/request';

// 顶部导航栏数据
export const headerData = data => {
  return api({
    url: '/admin/admin/Nav/index',
    method: 'post',
    data,
    routeChangeCancel: false
  });
};
// 记录点开广告弹窗
export const recordMsgCount = data => {
  return api({
    url: '/admin/admin/platformPromotion/recordMsgCount',
    method: 'post',
    data,
  });
};

// 修改密码
export const changePassword = data => {
  return api({
    url: '/admin/admin/user/changePassword',
    method: 'post',
    data
  });
};

// 退出登录
export const AdminLogout = data => {
  return api({
    url: '/admin/admin/user/logout',
    method: 'post',
    data
  });
};
// 短信验证码
export const sendAuthSms = data => {
  return api({
    url: '/admin/admin/user/sendAuthSms',
    method: 'post',
    data
  });
};
// 短信修改密码
export const smsChangePassword = data => {
  return api({
    url: '/admin/admin/user/smsChangePassword',
    method: 'post',
    data
  });
};

// 获取小程序授权状态
// export const authStatus = data => {
//   return api({
//     url: '/admin/wx_open/wx_applet_auth/authStatus',
//     method: 'post',
//     data
//   });
// };

export const getCode = (platform) => {
  return api({
    url: '/admin/admin/nav/platformCodes',
    method: 'post',
    data: {
      platform
    },
    notCancel: true
  })
}
