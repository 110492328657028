/*
 * @Author: mulingyuer
 * @Date: 2021-10-20 18:44:49
 * @LastEditTime: 2021-10-21 11:42:26
 * @LastEditors: mulingyuer
 * @Description: 大数据
 * @FilePath: \saas-admin-vue\src\modules\gov\store\big-data.js
 * 怎么可能会有bug！！！
 */
export default {
  namespaced: true,
  state: {
    associationList: [{ project_id: -1, name: "全部" }], //协会列表
  },
  getters: {
    associationList(state) {
      return state.associationList;
    }
  },
  mutations: {
    setAssociationList(state, value) {
      state.associationList = value;
    }
  },
  actions: {

  }
}
