export default [
  {
    path: "yqby", name: "YQBY", component: {render: h => h("router-view")},
    redirect: {name: "CycAskList"},
    children: [
      {
        // 信息反馈
        path: "leave_msg", name: "CycAskList", component: {render: h => h("router-view")},
        redirect: {name: "CycAskListIndex"},
        children: [
          // 反馈列表
          {
            path: "leave_list", name: "CycAskListIndex", component: () => import("../views/leavemsg-list"),
            meta: {alias: "CycAskList"}
          },
          {
            path: "leave_detail/:id",
            name: "MsgDetail",
            component: () => import("../views/leavemsg-detail"),
            meta: {title: "查看"}
          }
        ]
      },
      {path: "cyc_ask_category", name: "CycAskCategory", component: () => import("../views/category-list"),},
    ]
  }
]
