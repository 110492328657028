<template>
  <div id="app">
    <transition name="el-fade-in-linear" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import api from "@/base/utils/request"; /* 注释之后就白屏了哈 */
import { setFavicon, setTitle } from "@/base/utils/tool";
import CnNotify from './modules/common/components/CnNotify'

export default {
  name: "app",
  components: {
    CnNotify
  },
  computed: {
    docTitle() {
      return this.$store.getters.docTitle;
    },
    routeTitle({ $route }) {
      let titleArr = [];
      let title = "";
      $route.matched.forEach((route) => {
        route.meta?.title && titleArr.push(route.meta.title);
      });
      // 获取倒叙第一个title
      if (titleArr.length) {
        title = titleArr.reverse()[0];
      }
      /* 读取菜单接口返回的页面标题 */
      let localData = sessionStorage.getItem("sidebarData")
        ? JSON.parse(sessionStorage.getItem("sidebarData"))
        : [];
      let menuItem = this.findNodeConfig(localData, this.routeName)
      const matchedArr = $route.matched
      let parentRouteName = matchedArr[matchedArr.length - 1]?.parent?.name
      if (!parentRouteName && matchedArr.length > 1) parentRouteName = matchedArr[matchedArr.length - 2]?.parent?.name
      // if (!parentRouteName && matchedArr.length > 2) parentRouteName = matchedArr[matchedArr.length - 3]?.parent?.name
      // if (!parentRouteName && matchedArr.length > 3) parentRouteName = matchedArr[matchedArr.length - 4]?.parent?.name
      // if (!parentRouteName && matchedArr.length > 4) parentRouteName = matchedArr[matchedArr.length - 5]?.parent?.name
      const parentMenuItem = parentRouteName ? this.findNodeConfig(localData, parentRouteName) : null
      // if (!menuItem && matchedArr.length > 1 && parentRouteName) menuItem = parentMenuItem
      const pName = parentMenuItem?.name
      const cName = menuItem?.name || title
      // console.log(pName, cName, matchedArr.map(el => el.name))
      let autoName = ''
      if (pName === cName) autoName = pName
      else if (!pName) autoName = cName
      else if (!cName) autoName = pName
      else autoName = `${cName}-${pName}`
      return autoName || '';
    },
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    watchUpdate() {
      window.addEventListener("onmessageUpdate", (res) => {
        console.log("🚀 ~ file: App.vue:20 ~ window.addEventListener ~ res:", res)
        let msg = res.detail.msg,
            version = res.detail.version

        this.$confirm(msg, '更新提醒', {
          confirmButtonText: '立即刷新',
          cancelButtonText: '手动刷新', // 就是取消
        }).then(() => {
          location.reload()
        }).catch(() => {})

        /** 右下角提示 */
        // this.$notify({
        //   title: "版本更新提示",
        //   duration: 0,
        //   position: "bottom-right",
        //   dangerouslyUseHTMLString: true,
        //   message: this.$createElement("CnNotify", {
        //     // 使用自定义组件
        //     ref: "CnNotify",
        //     props: {
        //       msg: msg,
        //       version: version
        //     },
        //   }),
        //   customClass: 'versionNotifyStyle', //自定义类名
        // })
      })
    },
    /* 查看高亮节点对应的接口返回的信息 */
    findNodeConfig(children, routeName) {
      const child = children.find(ch => ch.url === routeName)
      if (child) {
        return child
      }
      let result
      children.forEach(ch => {
        const config = this.findNodeConfig(ch.children || [], routeName)
        if (config) result = config
        // else if (!ch.children || !ch.children.length) result = ch
        // else if ((!ch.children || !ch.children.length)) console.log(ch.url)
      })
      return result
    },
    //获取全局配置文件
    getWebConfig() {

    },
    //修改页面标题
    reviseDocTitle() {
      if (!this.docTitle) return;
      setTitle(`${this.routeTitle && (this.routeTitle + '-')}${this.docTitle}`);
    },
    // 浏览器刷新事件之前
    beforeunloadHandler(e) {
      sessionStorage.clear();
    },
  },
  watch: {
    docTitle(val) {
      if (val) this.reviseDocTitle();
    },
    routeName(val) {
      if (val) this.reviseDocTitle();
    },
  },
  created() {
    // 获取全局配置文件
    // this.getWebConfig();

    if (this.$store.getters['user/token']) {
      // 开发时记得注释掉不然会频繁触发
      this.$store.commit('socket/initSocket')
    }
  },
  mounted() {
    // 注册浏览器监听刷新关闭事件
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));

    this.watchUpdate()
  },
  destroyed() {
    // 卸载浏览器监听刷新关闭事件
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
}

.clamp2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
