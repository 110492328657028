import lib from "../assets/jsons/component-library.json"

export default {
  namespaced: true,
  state: {
    /* 辅助判断组件使用次数 */
    clone: {
      key: '',
      data: null
    },
    pageDetail: null,
    paramsData: null,
    loading: false,
    library: [],
    /* 修改前的数据 */
    backupJson: "",
  },
  getters: {
    clone(state) {
      return state.clone;
    },
    loading(state) {
      return state.loading;
    },
    library(state) {
      return state.library;
    },
    backupJson(state) {
      return state.backupJson;
    },
    paramsData(state) {
      return state.paramsData;
    }
  },
  mutations: {
    setClone(state, value) {
      state.clone = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setLibrary(state, value) {
      state.library = value;
    },
    setBackupJson(state, value) {
      state.backupJson = value;
    },
    setParamsData(state, value) {
      state.paramsData = value
    }
  },
  actions: {

  },
}
