import { mediaFolders } from "../api/media";

export default {
  namespaced: true,
  state: {
    filterConfig: {  //筛选配置
      page_size: 18, //每页条数（默认：15）
      type: "all", //类型
    },
    // 当前文件夹信息
    currentFolder: {
      id: -1,
      name: '全部'
    },
    // 选择的文件夹id，已过时，被currentFolder对象代替
    // folder_id: -1,
    folderOptions: [], // 文件夹选项数组
    breadcrumbArr: [{ id: -1, title: "全部文件" }], //面包屑导航数据
    showType: true, //显示模式：true缩略图、false列表
    showDetail: false,
    detailId: 0,
    isRefresh: false,
    otherFilterConfig: {},
    // 是否打开预览视频弹窗
    isOpenMediaVideoDialog: false,
    // 弹窗数据，可共用
    dialogDetail: {},
    isOpenMediaMusicDialog: false,
    isOpenMediaMoveDialog: false,
  },
  getters: {
    filterConfig(state) {
      return state.filterConfig;
    },
    // folder_id(state) {
    //   return state.folder_id;
    // },
    folderOptions(state) {
      return state.folderOptions;
    },
    breadcrumbArr(state) {
      return state.breadcrumbArr;
    },
    showType(state) {
      return state.showType;
    },
    showDetail(state) {
      return state.showDetail;
    },
    detailId(state) {
      return state.detailId;
    },
    isRefresh(state) {
      return state.isRefresh;
    },
    otherFilterConfig(state) {
      return state.otherFilterConfig;
    },
    isOpenMediaVideoDialog(state) {
      return state.isOpenMediaVideoDialog;
    },
    dialogDetail(state) {
      return state.dialogDetail;
    },
    isOpenMediaMusicDialog(state) {
      return state.isOpenMediaMusicDialog;
    },
    isOpenMediaMoveDialog(state) {
      return state.isOpenMediaMoveDialog;
    },
    currentFolder(state) {
      return state.currentFolder;
    }
  },
  mutations: {
    setFilterConfig(state, value) {
      state.filterConfig = value;
    },
    // setFolder_id(state, value) {
    //   state.folder_id = value;
    // },
    setFolderOptions(state, value) {
      state.folderOptions = value;
    },
    setBreadcrumbArr(state, value) {
      state.breadcrumbArr = value;
    },
    setShowType(state, value) {
      state.showType = value;
    },
    setShowDetail(state, value) {
      state.showDetail = value;
    },
    setDetailId(state, value) {
      state.detailId = value;
    },
    setIsRefresh(state, value) {
      state.isRefresh = value;
    },
    setOtherFilterConfig(state, value) {
      state.otherFilterConfig = value;
    },
    setIsOpenMediaVideoDialog(state, value) {
      state.isOpenMediaVideoDialog = value;
    },
    setDialogDetail(state, value) {
      state.dialogDetail = value;
    },
    setIsOpenMediaMusicDialog(state, value) {
      state.isOpenMediaMusicDialog = value;
    },
    setIsOpenMediaMoveDialog(state, value) {
      state.isOpenMediaMoveDialog = value;
    },
    setCurrentFolder(state, value) {
      state.currentFolder = value;
    }
  },
  actions: {
    // api获取文件夹目录信息
    getFoldersData({ commit }, data) {
      mediaFolders().then((res) => {
        const { data: d } = res;
        const folders = [
          { title: "全部", id: -1 },
          { title: "根目录", id: 0 },
        ].concat(d);
        // 更新vuex
        commit("setFolderOptions", folders)

        if (data && data.callback) {
          data.callback()
        }
      }).catch(() => {})
    },
    // 查看详情
    openMediaDetailDialog({ commit }, data) {
      commit('setDetailId', data.id)
      commit('setShowDetail', true)
    },
    // 视频预览
    openMediaVideoDialog({ commit }, data) {
      commit('setDialogDetail', data)
      commit('setIsOpenMediaVideoDialog', true)
    },
    // 音频预览
    openMediaMusicDialog({commit}, data) {
      commit('setDialogDetail', data)
      commit('setIsOpenMediaMusicDialog', true)
    },
    updateMediaView({ commit }, data) {
      commit('setOtherFilterConfig', data)
      commit('setIsRefresh', true)
    },
    // 移动
    openMediaMoveDialog({commit}, data) {
      commit('setDialogDetail', data)
      commit('setIsOpenMediaMoveDialog', true)
    }
  },
}
