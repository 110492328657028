export default [
  {
    path: "wechat-company", component: { render: h => h("router-view") },
    // meta: { title: "企业微信" },
    children: [
      {
        path: "wx-company-plugin", name: "PluginFeature", component: () => import("../views/wx-company-plugin"),
        // meta: {
        //   title: "客户联系插件"
        // }
      },
      {
        path: "wx-company-app", name: "WxCompanyAppConfig", component: () => import("../views/wx-company-app"),
        // meta: {
        //   title: "企微工作台应用"
        // }
      },
      {
        path: "wx-company-config", name: "WxCompanyConfig", component: () => import("../views/wx-company-config"),
        // meta: {
        //   title: "企微设置"
        // }
      }
    ]
  }
]
