export default [
  {
    path: "organization-msg", component: { render: h => h("router-view") },
    // meta: { activeMenu: "CycOtherConfig", breadcrumb: {name: "CycOtherConfig"} },
    children: [
    //   {
    //     path: "message-config", name: "CycProjectInfo",
    //     component: () => import("../views/message-config"),
    // // meta: { activeMenu: "CycOtherConfig" },
    //   }
    ]
  }
]
