/*
 * @Author: mulingyuer
 * @Date: 2021-10-25 16:01:37
 * @LastEditTime: 2021-10-26 11:22:54
 * @LastEditors: mulingyuer
 * @Description:全局请求loading
 * @FilePath: \super-admin-vue\src\base\utils\loading.js
 * 怎么可能会有bug！！！
 */
import store from "@/base/store";


//计数器
let loadingCount = 0;


//触发loading
const startLoading = () => {
  loadingCount++;
  store.commit("layout/setMainLoading", true);
};


//结束loading
const endLoading = () => {
  loadingCount--;

  // 这个纯属是防止某些极端情况下变量减成负数（当然此处可以优化  但是我觉得这样写意图更明显！）
  loadingCount = loadingCount < 0 ? 0 : loadingCount
  if (!loadingCount) {
    store.commit('layout/setMainLoading', false);
  }
};


//重置loading
const resetLoading = () => {
  loadingCount = 0;
  store.commit('layout/setMainLoading', false);
}


export default {
  startLoading,
  endLoading,
  resetLoading,
}
