export default [
  {
    path: "topic-library",
    name: "DataListTopicLibrary",
    component: { render: h => h("router-view") },
    redirect: {
      name: "DataListTopicLibraryIndex"
    },
    children: [
      {
        path: "", name: "DataListTopicLibraryIndex",
        component: () => import(/* webpackChunkName: "data-list"*/ "../../views/topic-library/index")
      },
      // { path: "topic-detail/:id", name: "GovTopicDetail",
      //   component: () => import("../../../community-admin/views/post-admin/list"),
      //   meta: { title: "详情" }
      // }
      {
        path: "posts/:topic_id",
        name: "GovTopicPosts",
        meta: {title: "帖子列表", activeMenu: "DataListTopicLibrary"},
        component: { render: h => h("router-view") },
        redirect: {
          name: "GovTopicPostsIndex"
        },
        children: [
          {
            path: "",
            name: "GovTopicPostsIndex",
            component: () => import("../../views/post-library/index"),
          },
          { path: "post-detail/:id",
            name: "GovTopicPostDetail",
            component: () => import("../../../community-admin/views/post-admin/detail"),
            meta: { title: "详情" }
          },
        ]
      }
    ]
  }
]
