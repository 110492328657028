import Vue from 'vue';
import VueRouter from 'vue-router';
import { moduleRouter } from "../config";
import MuRouterSwatchData from "../plugins/mu-router-swatch-data/index";

Vue.use(VueRouter);
//挂载自定义方法
MuRouterSwatchData.install(VueRouter);



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: moduleRouter
});





export default router
