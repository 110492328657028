/*
 * @Author: dongjia
 * @Date: 2021-10-09 10:53:46
 * @LastEditTime: 2022-04-19 10:45:41
 * @LastEditors: aleaner
 * @Description: 用户中心跳转处理
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\common\mixins\jump-user-admin.js
 * 怎么可能会有bug！！！
 */
import { getLoginAccessToken } from '../api/jump-user-admin'
import { enCode } from '@/base/utils/tool.js'

export default {
  methods: {
    // 点击去购买
    goToPayInIframe({ type }) {
      // type 有 package 和 sms
      if (type === 'sms') {
        const routeInfo = {
          name: 'UserCenterExtrasSms',
          params: {
            project_id: this.$store.state.tenant.id || '',
          }
        }
        this.$router.push(routeInfo).catch(() => {
          this.$router.replace(routeInfo)
        })
      } else this.handleRenew()

      this.openDialog = false
    },
    handleRenew() {
      const routeInfo = {
        name: 'MyProjectRenew',
        params: {
          id: this.$store.state.tenant.id,
        }
      }
      this.$store.commit("setRefresh", true);

      this.$router.push(routeInfo).catch(() => {
        this.$router.replace(routeInfo)
      })
    },
    // 跳转前判断当前用户是否为超级管理员，是：直接登录并跳转特定路由，否：跳转登录页
    handleJumpUserAdmin(routeName, params = {}, query = {}) {
      let loading = this.$loading({
        text: '正在跳转用户中心...',
      })
      getLoginAccessToken()
        .then((res) => {
          const { data } = res
          if (!data.is_super) {
            this.$message.error('暂无操作权限')
            loading.close()
            return
          }
          let server = window.serverConfig
          let baseUrl = server.VUE_APP_OTHER_BASEURL
            ? server.VUE_APP_OTHER_BASEURL['user-admin']
            : server.VUE_APP_OTHER_HEADERS['user-admin']
          let finalUrl = `${baseUrl}?redirect=${routeName}&params=${enCode(
            params
          )}&query=${enCode(query)}`
          if (data.is_super) finalUrl += `&superToken=${data.token}`
          console.log(
            finalUrl,
            '------------------用户中心链接-----------------'
          )
          window.open(finalUrl, '_blank')
          loading.close()
        })
        .catch((err) => {
          loading.close()
        })
    },
    // 以地址访问形式跳转
    jumpUserAdminUrl(url) {
      let loading = this.$loading({
        text: "正在跳转用户中心..."
      })
      getLoginAccessToken().then(res => {
        const { data } = res
        if (data.is_super) url += `?superToken=${data.token}`
        console.log(url, '------------------用户中心链接-----------------')
        window.open(url, "_blank");
        loading.close()
      }).catch(err => { loading.close() })
    },
  },
}
