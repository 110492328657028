export default [
  {
    path: 'task-list',
    name: 'TaskList',
    component: { render: (h) => h('router-view') },
    redirect: { name: 'TaskListIndex' },
    children: [
      {
        path: 'index',
        name: 'TaskListIndex',
        component: () => import('../views/index'),
      },
      {
        path: 'add',
        name: 'TaskAdd',
        component: () => import('../views/add'),
        meta: {
          title: '新增',
        },
      },
      {
        path: 'edit/:id',
        name: 'TaskEdit',
        component: () => import('../views/add'),
        meta: {
          title: '编辑',
        },
      },
    ],
  },
]
