/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 17:12:36
 * @LastEditTime: 2021-09-16 15:20:51
 * @LastEditors: mulingyuer
 * @Description:活动库
 * @FilePath: \saas-admin-vue\src\modules\gov\router\data-list\activity-library.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "activity-library", component: { render: h => h("router-view") },
    // meta: {
    //   title: "资源库",
    //   activeMenu: "DataListActivityLibrary",
    //   breadcrumb: { name: "DataListActivityLibrary" }
    // },
    children: [
      {
        path: "", name: "DataListActivityLibrary",
        component: () => import(/* webpackChunkName: "data-list"*/ "../../views/activity-library/index")
      }
    ]
  }
]
