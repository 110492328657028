export default [
  {
    path: "report-admin", name: "", component: { render: h => h("router-view") },
    // meta: { title: "举报管理", breadcrumb: { name: "Complaint" } },
    children: [
      {
        path: "report-list", name: "Complaint", component: () => import("../views/list"),
      }
    ]
  }
]
