export default [
  {
    path: "app-design", name: "AppDesign", component: { render: h => h("router-view") },
    meta: { title: "页面装修" },
    redirect: {name: "AppDesignIndex"},
    children: [
      {
        path: "index", name: "AppDesignIndex", component: () => import("../views/index"), meta: { title: "", activeMenu: "AppDesign" }
      },
      { path: ":platform/:id", name: "AppDesignPage", component: () => import("../views/page"), meta: { title: "页面设置", activeMenu: "AppDesign" }}
    ]
  },
  {
    path: 'design', name: 'DesignAll', component: { render: h => h("router-view") },
    // meta: { title: "页面装修" },
    children: [
      {
        path: "mp-weixin", name: "AppDesignApp", component: () => import("../views/index"), meta: { title: "", activeMenu: "AppDesignApp" }
      },
      {
        path: "h5", name: "AppDesignH5", component: () => import("../views/index"), meta: { title: "", activeMenu: "AppDesignH5" }
      },
      // { path: ":platform/:id", name: "AppDesignPage", component: () => import("../views/page")}
    ]
  },
  // {
  //   path: "app-design-app", name: "AppDesignApp", component: { render: h => h("router-view") }, meta: { title: "页面装修" },
  //   redirect: {name: "AppDesignAppIndex"},
  //   children: [
  //     {
  //       path: "index", name: "AppDesignAppIndex", component: () => import("../views/index"), meta: { title: "", activeMenu: "AppDesignApp" }
  //     },
  //     { path: ":platform/:id", name: "AppDesignPage", component: () => import("../views/page"), meta: { title: "页面设置", activeMenu: "AppDesignApp" }}
  //   ]
  // },
  // {
  //   path: "app-design-h5", name: "AppDesignH5", component: { render: h => h("router-view") }, meta: { title: "页面装修" },
  //   redirect: {name: "AppDesignH5Index"},
  //   children: [
  //     {
  //       path: "index", name: "AppDesignH5Index", component: () => import("../views/index"), meta: { title: "", activeMenu: "AppDesignH5" }
  //     },
  //     { path: ":platform/:id", name: "AppDesignPage", component: () => import("../views/page"), meta: { title: "页面设置", activeMenu: "AppDesignH5" }}
  //   ]
  // },
  {
    path: "page-path", name: "CycLinkSearch", component: () => import("../views/search"),
    // meta: { title: "页面路径查询" }
  }
]
