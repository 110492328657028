export default [
  {
    path: "wechat-channels", component: { render: h => h("router-view") },
    children: [
      {
        path: "video", component: { render: h => h("router-view") },
        // meta: {
        //   title: "视频号视频", breadcrumb: { title: "视频号视频", name: "WxAccountVideos" },
        // },
        children: [
          { path: "list", name: "WxAccountVideos",
            component: () => import("../views/video-list"),
          }
        ]
      },
      {
        path: "live", component: { render: h => h("router-view") },
        // meta: {
        //   title: "视频号直播", breadcrumb: { title: "视频号直播", name: "WxAccountLive" },
        // },
        children: [
          { path: "list", name: "WxAccountLive",
            component: () => import("../views/live-list"),
          }
        ]
      },
      {
        path: "category", name: "WxAccountCategory",
        component: () => import("../views/category-list"),
        // meta: { title: "分类列表" },
      }
    ]
  }
]
