export default [
  {
    path: "/test", name: "test", component: { render: h => h("router-view") },
    redirect: { name: "testIndex" },
    children: [
      {path: 'index', name: "testIndex", component: () => import("../views/index"),
      },
    ],
    // meta: {private: false, guest: true}
  },
]