
import api from "@/base/utils/request";

// 获取分类列表
export const getServiceConfig = data => {
  return api({
    url: "/admin/consult/settings/getServiceConfig",
    method: "post",
    data
  });
};
export const setServiceConfig = data => {
  return api({
    url: "/admin/consult/settings/saveServiceConfig",
    method: "post",
    data
  });
}
