/*
 * @Author: mulingyuer
 * @Date: 2021-09-03 14:44:52
 * @LastEditTime: 2021-09-07 14:27:34
 * @LastEditors: mulingyuer
 * @Description: 课程管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\router\course-manage.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "course-manage", component: { render: h => h("router-view") },
    // meta: {
    //   title: "课程管理",
    //   activeMenu: "ClassroomCourseManage",
    //   breadcrumb: { name: "ClassroomCourseManage" }
    // },
    name: "ClassroomCourseManage",
    redirect: {name: "ClassroomCourseManageIndex"},
    children: [
      {
        path: "", name: "ClassroomCourseManageIndex",
        component: () => import(/* webpackChunkName: "online-course" */"../views/course-manage/index"),
      },
      {
        path: "add-edit", name: "ClassroomCourseManageAddEdit",
        component: () => import(/* webpackChunkName: "online-course" */"../views/course-manage/add-edit"),
        meta: { title: "新增课程" }
      },
      {
        path: "students", name: "ClassroomCourseManageStudents",
        component: () => import("../views/course-manage/student-list"),
        meta: {title: "学员列表"}
      }
    ]
  }
]
