/*
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:29:41
 * @LastEditTime: 2021-04-08 15:29:54
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\saas-admin-vue-vote\config.js
 * 怎么可能会有bug！！！
 */

import router from "./router";

export default {
  router
}

