/*
 * @Author: mulingyuer
 * @Date: 2021-08-06 15:01:53
 * @LastEditTime: 2021-08-06 15:11:20
 * @LastEditors: mulingyuer
 * @Description: 复制按钮
 * @FilePath: \agent-admin-vue\src\base\directives\lib\copy.js
 * 怎么可能会有bug！！！
 */
import { Message } from "element-ui";
import { copyText } from "@/base/utils/tool";

export default {
  bind(el, { value }) {
    el.$value = value;
    el.handler = () => {
      if (!el.$value) {
        return Message.warning('无复制内容');
      }

      copyText(el.$value).then(() => {
        Message.success('复制成功');
      }).catch((err) => {
        Message.error(err.message);
      })

    }

    // 绑定点击事件，就是所谓的一键 copy 啦
    el.addEventListener('click', el.handler)
  },
  // 当传进来的值更新的时候触发
  componentUpdated(el, { value }) {
    el.$value = value
  },
  // 指令与元素解绑的时候，移除事件绑定
  unbind(el) {
    el.removeEventListener('click', el.handler)
  },
}
