export default [
  {
    path: "brand-ad", name: "brandAd", component: { render: h => h("router-view") },
    redirect: { name: "CycBrandList" },
    children: [
      {
        path: "brand-module", name: "CycBrandList", component: () => import("../views/index"),
        // meta: { title: "专题列表", activeMenu: "CycBrandList" },
        redirect: { name: "CycBrandListIndex" },
        children: [
          { path: "list", name: "CycBrandListIndex", component: () => import("../views/brand-list") },
          { path: "add_brand/:id", name: "addBrand", component: () => import("../views/add-brand"), meta: { title: "新增" } },
          { path: "edit-brand/:id", name: "editBrand", component: () => import("../views/add-brand"), meta: { title: "内容信息" } },
          { path: "check_brand/:id", name: "checkBrand", component: () => import("../views/check-brand"), meta: { title: "数据列表" } },
        ]
      },
      { path: "brand-category", name: "CycBrandCategoryList", component: () => import("../views/brand-category") }
    ]
  }
]
