/*
 * @Author: mulingyuer
 * @Date: 2021-05-25 10:36:59
 * @LastEditTime: 2021-05-26 17:19:14
 * @LastEditors: mulingyuer
 * @Description: 专题管理路由
 * @FilePath: \saas-admin-vue\src\modules\portal\router\article-subject.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "article-subject", component: { render: h => h("router-view") },
    name: "ArticleSubjectList",
    // meta: {
    //   title: "专题管理",
    //   activeMenu: "ArticleSubjectList",
    //   breadcrumb: { name: "ArticleSubjectList" }
    // },
    redirect: {name: "ArticleSubjectListIndex"},
    children: [
      { path: "", name: "ArticleSubjectListIndex", component: () => import("../views/article-subject") },
      {
        path: "add-edit", name: "ArticleSubjectAddEdit",
        component: () => import("../views/article-subject/add-edit"),
        meta: { title: "新增" }
      },
      {
        path: "article-list/:id", name: "SubjectArticleList",
        component: () => import("../views/article-subject/article-list"),
        meta: { title: "文章列表" }, props: true
      }
    ]
  }
];
