export default [
  {
    path: "commerce-journal", component: { render: h => h("router-view") },
    // meta: { title: "会刊列表", breadcrumb: { name: "JournalList" } },
    name: "JournalList",
    redirect: {name: "JournalListIndex"},
    children: [
      { path: "journal-list", name: "JournalListIndex", component: () => import("../views/journal-list"),
        },
      { path: "add-journal/:type/:id", name: "AddJournal", component: () => import("../views/add-journal"),
        meta: { title: "新增" } },
    ]
  }
]
