import api from "@/base/utils/request";

//获取多媒体列表页数据
export const mediaList = data => {
  return api({
    url: "/admin/admin/media/index",
    method: "post",
    data
  });
};

// 多媒体弹窗数据
export const MediaBounced = data => {
  return api({
    url: "/admin/admin/media/MediaBounced",
    method: "post",
    data
  });
};

//获取媒体库所有目录
export const mediaFolders = data => {
  return api({
    url: "/admin/admin/media/folders",
    method: "post",
    data
  });
};

//媒体库资源详情
export const mediaDetail = data => {
  return api({
    url: "/admin/admin/media/detail",
    method: "post",
    data
  });
};

//媒体库编辑资源详情
export const mediaEditDetail = data => {
  return api({
    url: "/admin/admin/media/save",
    method: "post",
    data
  });
};

//媒体库迁移
export const mediaMove = data => {
  return api({
    url: "/admin/admin/media/move",
    method: "post",
    data
  });
};

//媒体库删除
export const mediaDel = data => {
  return api({
    url: "/admin/admin/media/del",
    method: "post",
    data
  });
};

//媒体库文件类型
export const mediaTypes = data => {
  return api({
    url: "/admin/admin/media/types",
    method: "post",
    data
  });
};

//媒体库新建文件夹
export const mediaCreateFolder = data => {
  return api({
    url: "/admin/admin/media/createFolder",
    method: "post",
    data
  });
};

export const getDialogList = (data) => {
  return api({
    url: '/admin/admin/media/MediaBounced',
    method: 'post',
    data
  })
}
