/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 15:31:34
 * @LastEditTime: 2021-09-16 15:21:30
 * @LastEditors: mulingyuer
 * @Description: 资源库
 * @FilePath: \saas-admin-vue\src\modules\gov\router\data-list\resource-library.js
 * 怎么可能会有bug！！！
 */

export default [
  {
    path: "resource-library", component: { render: h => h("router-view") },
    // meta: {
    //   title: "资源库",
    //   activeMenu: "DataListResourceLibrary",
    //   breadcrumb: { name: "DataListResourceLibrary" }
    // },
    children: [
      {
        path: "", name: "DataListResourceLibrary",
        component: () => import(/* webpackChunkName: "data-list"*/ "../../views/resource-library/index")
      }
    ]
  }
]
