export default [
  {
    path: 'resource-circle',
    name: 'resource-circle',
    component: () => import('../views/index'),
    // meta: { title: "" },
    redirect: { name: 'circleCategory' },
    children: [
      {
        path: 'circle-category',
        name: 'circleCategory',
        component: () => import('../views/circle-category'),
        meta: { title: '分类管理', activeMenu: 'circleCategory' },
      },
      {
        path: 'circle-index',
        name: 'circleContentIndex',
        component: { render: (h) => h('router-view') },
        // meta: { title: "资源列表", activeMenu: "circleContent" },
        redirect: { name: 'circleContent' },
        children: [
          {
            path: 'circle-content',
            name: 'circleContent',
            component: () => import('../views/circle-content/list'),
          },
          {
            path: 'circle-detail/:id/:type',
            name: 'circleDetail',
            component: () => import('../views/circle-content/detail'),
            meta: { title: '资源详情' },
          },
        ],
      },
      {
        path: 'circle-top-time',
        name: 'circleTopTime',
        component: () => import('../views/circle-top-time'),
        meta: { title: '置顶付费配置', activeMenu: 'circleOtherConfig' },
      },
      {
        path: 'circle-check',
        name: 'circleCheck',
        component: () => import('../views/circle-check'),
        // meta: { title: "审核设置", activeMenu: "circleOtherConfig" }
      },
      {
        path: 'circle_send_config',
        name: 'circleSendConfig',
        component: () => import('../views/circle-send-config'),
        meta: { title: '发布配置', activeMenu: 'circleOtherConfig' },
      },
      {
        path: 'circle-share',
        name: 'circleShare',
        component: () => import('../views/circle-share'),
        meta: { title: '分享设置', activeMenu: 'circleOtherConfig' },
      },
      // { path: "circle-comment", name: "circleComment", component: () => import("../views/circle-comment"), meta: { title: "评论列表", activeMenu: "circleComment" } }
      {
        path: 'comment',
        name: 'circleComment',
        component: { render: (h) => h('router-view') },
        // meta: { title: "评论列表", activeMenu: "circleComment" },
        redirect: { name: 'circleCommentIndex' },
        children: [
          {
            path: 'circle-comment',
            name: 'circleCommentIndex',
            component: () => import('../views/circle-comment'),
            meta: {alias: 'circleComment'}
          },
          {
            path: 'circle-detail/:id',
            name: 'CircleDetail',
            component: () => import('../views/circle-detail'),
            meta: {
              title: '评论详情',
            },
          },
        ],
      },
      {
        path: 'circle-order',
        name: 'circleOrder',
        component: () => import('../views/circle-order'),
        // meta: { title: "订单列表", activeMenu: "circleOrder" }
      },
      {
        path: 'circle-order',
        name: 'circleOrderList',
        component: () => import('../views/circle-order'),
        meta: { title: '订单列表', activeMenu: 'circleOrderList' },
      },
      {
        path: 'others',
        name: 'circleOtherConfig',
        component: { render: (h) => 'router-view' },
        // meta: {title: ''},
        redirect: {
          name: 'circleCheck',
        },
        children: [],
      },
    ],
  },
]
