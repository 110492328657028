/*
 * @Author: mulingyuer
 * @Date: 2021-07-06 12:02:53
 * @LastEditTime: 2021-07-07 16:39:02
 * @LastEditors: mulingyuer
 * @Description: 证书管理
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "cert_list",
    name: "CertList",
    redirect: { name: "CertListIndex" },
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "list",
        component: { render: (h) => h("router-view") },
        // meta: {
        //   title: "证书列表",
        //   activeMenu: "CertList",
        //   breadcrumb: { name: "CertList" }
        // },
        redirect: { name: "CertManage" },

        name: "CertListIndex",
        children: [
          {
            path: "index",
            name: "CertManage",

            component: () => import("../views/list"),
          },
          {
            path: "cert_record_list",
            name: "CertRecordList",
            component: () => import("../views/cert_record_list"),
          },
          {
            path: "manage/:id",
            name: "CertManageList",
            component: { render: (h) => h("router-view") },
            redirect: { name: "CertManageListIndex" },
            meta: { title: "证书管理", activeMenu: "CertList" },
            children: [
              {
                path: "index",
                name: "CertManageListIndex",
                component: () => import("../views/manage"),
                props: true,
              },
              {
                path: "edit",
                name: "CertEdit",
                component: () => import("../views/add-edit"),
                meta: { title: "编辑" },
              },
            ],
          },
          {
            path: "add",
            name: "CertAdd",
            component: () => import("../views/add-edit"),
            meta: { title: "新增" },
          },
        ],
      },
    ],
  },
];
