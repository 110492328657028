/*
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:30:00
 * @LastEditTime: 2021-04-08 18:39:04
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\vote\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "vote", component: { render: h => h("router-view") },
    // meta: { activeMenu: "VoteList" },
    name: "VoteList",
    redirect: {name: "VoteListIndex"},
    children: [
      {
        path: "", name: "VoteListIndex", component: () => import("../views/vote-list"),
      },
      {
        path: "add-edit", name: "VoteAddEdit", component: () => import("../views/add-edit"),
        meta: { title: "新增&编辑" }
      },
      {
        path: 'analysis', name: 'VoteAnalysis', component: () => import('../views/vote-analysis'),
        meta: {title: '查看'}
      }
    ]
  }
]
