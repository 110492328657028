export default {
  state: {
    menberActiveName: 'detail', //会员资料页tab状态
  },
  getters: {
    menberActiveName(state) {
      return state.menberActiveName;
    }
  },
  mutations: {
    setMenberActiveName(state, value) {
      console.log(value)
      state.menberActiveName = value;
    }
  },
  actions: {

  },
}