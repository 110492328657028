export default [
  {
    path: 'channel-library',
    name: 'DataListWxAccountVideo',
    component: { render: h => h("router-view") },
    redirect: {name: 'DataListWxAccountVideoIndex'},
    children: [
      {
        path: '',
        name: "DataListWxAccountVideoIndex",
        component: () => import("../../views/channel-library/index")
      }
    ]
  }
]
