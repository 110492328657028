import articleSubject from "./article-subject";

export default [
  {
    path: "article-admin", component: () => import("../views/index"), meta: { title: "" },
    redirect: { name: "ArticleList" },
    children: [
      {
        path: "write-article", name: "WriteArticle", component: {render: h => h("router-view")},
        redirect: {name: "CreateArticle"},
        children: [
          {path: "create-article", name: "CreateArticle", component: () => import("../views/write-article")},
          // 应该没用了吧！（2024年05月07日15:25:17
          // {path: "wx-public-article", name: "WxPublicArticle", component: () => import("../views/write-article"),
          //   // meta: {title: '公众号文章'},
          // }
        ]
      },
      {
        path: "template", name: "CustomArticleTmplList", component: { render: h => h("router-view") },
        // meta: { activeMenu: "CustomArticleTmplList", title: "模板列表" },
        redirect: { name: "CustomArticleTmplListIndex" },
        children: [
          {
            path: "list", name: "CustomArticleTmplListIndex", component: () => import("../views/article-template/list"),
          },
          {
            path: "detail/:id", name: "CustomArticleTmplDetail", component: () => import("../views/article-template/detail"),
            meta: { title: "详情" }
          }
        ]
      },
      {
        path: "article", name: "ArticleList", component: { render: h => h("router-view") },
        redirect: {name: "ArticleListIndex"},
        children: [
          {
            path: "list", name: "ArticleListIndex", component: () => import("../views/article-list"),
          },
          {
            path: "edit-article", name: "EditArticle", component: () => import("../views/write-article"),
            meta: { title: "编辑文章" }
          },
          {
            path: "edit-wx-article", name: "EditWxListArticle", component: () => import("../views/write-article"),
            meta: { title: "编辑文章" }
          },
        ]
      },
      {
        path: "my-article", name: "MyArticleList", component: { render: h => h("router-view") },
        // meta: { title: "我的文章", breadcrumb: {name: "MyArticleList"}, activeMenu: "MyArticleList" },
        redirect: {name: "MyArticleListIndex"},
        children: [
          {
            path: "index", name: "MyArticleListIndex", component: () => import('../views/my-article'),
          },
          {
            path: "edit-article", name: "EditMyArticle", component: () => import('../views/write-article'),
            meta: { title: "编辑文章" }
          },
          {
            path: "edit-wx-article", name: "EditMyWxArticle", component: () => import("../views/write-article"),
            meta: { title: "编辑文章" }
          },
        ]
      },
      {
        path: "examine", name: "Examine", component: { render: h => h("router-view") },
        meta: { title: "审核列表", breadcrumb: { name: "ArticleCheckList" }, activeMenu: "ArticleCheckList" },
        children: [
          {
            path: "examine-article", name: "ArticleCheckList", component: () => import("../views/examine-article"),
            meta: { title: "" }
          },
          // {
          //   path: "edit-article", name: "EditArticle", component: () => import("../views/write-article"),
          //   meta: { title: "编辑文章" }
          // },
          {
            path: "edit-wx-article", name: "EditWxArticle", component: () => import("../views/write-article"),
            meta: { title: "编辑文章" }
          },
        ]
      },
      {
        path: "release-article", name: "", component: { render: h => h("router-view") },
        // meta: { title: "发布列表", breadcrumb: { name: "ArticlePostList" }, activeMenu: "ArticlePostList" },
        children: [
          {
            path: "index", name: "ArticlePostList", component: () => import('../views/release-article'),
            meta: {title: ""}
          },
          // {
          //   path: "edit-article", name: "EditArticle", component: () => import('../views/write-article'),
          //   meta: { title: "编辑文章" }
          // },
          {
            path: "edit-wx-article", name: "EditPostWxArticle", component: () => import('../views/write-article'),
            meta: { title: "编辑文章" }
          }
        ]
      },
      {
        path: "classify-article", name: "ArticleCategoryList",
        component: () => import("../views/classify-article"),
        // meta: { title: "分类列表" }
      },
      {
        path: "source-manage", name: "ArticleSourceList", component: () => import("../views/source-manage"),
        meta: { title: "来源管理" }
      },
      {
        path: "comment-list", name: "ArticleCommentList", component: () => import("../views/comment-list"),
        meta: { title: "评论列表" }
      },
      {
        path: "article-config", name: "ArticleConfig",
        component: () => import("../views/article-config"),
        // meta: { title: "设置" }
      },
      //专题管理
      ...articleSubject
    ]
  }
]
