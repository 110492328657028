export default [
  {
    path: "journal-library", component: { render: h => h("router-view") },
    name: "DataListJournalLibrary",
    redirect: {name: "DataListJournalLibraryIndex"},
    children: [
      { path: "list", name: "DataListJournalLibraryIndex", component: () => import("../../views/journal-library/index"),
      },
    ]
  }
]
