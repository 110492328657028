export default [
  {
    path: 'venue',
    name: 'VenueList',
    component: { render: (h) => h('router-view') },
    redirect: { name: 'VenueListIndex' },
    children: [
      {
        path: 'index',
        name: 'VenueListIndex',
        component: () => import('../views/list'),
        meta: {alias: 'VenueList'}
      },
      {
        path: 'detail',
        component: { render: (h) => h('router-view') }, // 这个不能删
        name:'VenueCondition',
        redirect: { name: 'VenueConditionList' },
        children: [
          {
            path: 'list',
            name: 'VenueConditionList',
            component: () => import('../views/venue-condition'),
          },
          {
            path: ':id',
            name: 'VenueDetail',
            component: () => import('../views/venue-detail'),
            redirect: { name: 'VenueDetailInfo' },
            meta: {  title: '场地详情',activeMenu: 'VenueCondition' },
            children: [
              {
                path: 'info',
                name: 'VenueDetailInfo',
                component: () => import('../views/venue-detail-info'),
               
              },
              {
                path: 'record',
                name: 'VenueDetailRecord',
                component: () => import('../views/venue-detail-record'),
            
              },
              {
                name:'VenueDetailStatistics',
                path:'statistics',
                component: () => import('../views/venue-detail-statistics')
              }
            ],
          },
        ],
      },
      {
        path: 'add',
        name: 'AddVenue',
        component: () => import('../views/add'),
        meta: { title: '新增' },
      },
      {
        path: 'edit/:id/:step',
        name: 'EditVenue',
        component: () => import('../views/add'),
        meta: { title: '编辑' },
      },
      {
        path: 'check',
        name: 'VenueCheckList',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'VenueCheckListIndex' },
        children: [
          {
            path: 'list/:tab?',
            name: 'VenueCheckListIndex',
            component: () => import('../views/check-list'),
            meta: {alias: 'VenueCheckList'}
          },
          {
            path: 'view/:id',
            name: 'VenueCheckView',
            meta: {
              title: '查看',
              activeMenu: 'VenueCheckList',
            },
            component: () => import('../views/check-detail'),
          },
          {
            path: 'detail/:id',
            name: 'VenueCheckDetail',
            meta: {
              title: '审核',
              activeMenu: 'VenueCheckList',
            },
            component: () => import('../views/check-detail'),
          },
        ],
      },
      {
        path: 'template',
        name: 'VenueApprovalTmplList',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'VenueTmplListIndex' },
        children: [
          {
            path: 'list',
            name: 'VenueTmplListIndex',
            component: () => import('../views/template-list'),
          },
          {
            path: 'add',
            name: 'VenueTmplAddNew',
            meta: {
              title: '新增',
              activeMenu: 'VenueApprovalTmplList',
            },
            component: () => import('../views/template-detail'),
          },
          {
            path: 'detail/:id',
            name: 'VenueTmplDetail',
            meta: {
              title: '编辑',
              activeMenu: 'VenueApprovalTmplList',
            },
            component: () => import('../views/template-detail'),
          },
          {
            path: 'design/:id',
            name: 'VenueTmplDesign',
            component: () => import('../views/template-design'),
          },
        ],
      },
      {
        path: 'category',
        name: 'VenueCategoryList',
        component: () => import('../views/category'),
      },
      {
        path: 'facility',
        name: 'VenueFacilityList',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'VenueFacilityListIndex' },
        children: [
          {
            path: 'list',
            name: 'VenueFacilityListIndex',
            component: () => import('../views/facility'),
          },
        ],
      },
    ],
  },
]
