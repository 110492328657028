/*
 * @Author: mulingyuer
 * @Date: 2021-10-19 15:15:15
 * @LastEditTime: 2021-10-22 10:33:48
 * @LastEditors: mulingyuer
 * @Description: 路由的方法
 * @FilePath: \super-admin-vue\src\base\plugins\mu-router-swatch-data\core\router.js
 * 怎么可能会有bug！！！
 */
import MuSession from "./mu-session";
import { isString, isEmptyString } from "@/base/utils/tool";

//初始化存储
const muSession = new MuSession();
export { muSession };

//路由进入前
export const muRouterBefore = function (to, from) {
  const routeName = to.name;
  if (!routeName) return;

  //获取存储中的值
  const sData = muSession.get(routeName);
  if (!sData) return;

  //如果是从之前页面返回，传参
  const { goName, currentName } = sData;
  if (goName === from.name && routeName === currentName) {
    to.params.muRouteData = sData.data;
  }

}


//路由进入后
export const muRouterAfter = function (to) {
  const routeName = to.name;
  if (!routeName) return;

  //判断存储是否有值
  const sData = muSession.get(routeName);
  if (!sData) return;

  //移除
  if (sData.clear) muSession.delete(routeName);

}

//保存数据方法
function saveRouteData(router, location, routeData, clear = true) {
  //当前路由name
  const routeName = router.currentRoute.name;
  //记录数据
  if (routeData && routeName && routeName !== "") {
    muSession.set(routeName, {
      clear,
      data: routeData,
      timestamp: Date.now(),
      path: router.currentRoute.fullPath,
      goName: location.name || "", //前往的路由name
      currentName: routeName, //当前路由name
    });
  }
}

//路由name是否合法
function isRouteName(name) {
  return isString(name) && !isEmptyString(name)
}


//挂载到VueRouter原型方法
export const install = function (VueRouter) {
  //挂载muSession
  VueRouter.prototype.$muSession = muSession;

  //push
  VueRouter.prototype.muPush = function (location, routeData, clear = true) {
    //保存数据
    saveRouteData(this, location, routeData, clear);

    return this.push(location);
  };

  //删除数据
  VueRouter.prototype.muRemove = function (routeName) {
    let removeRouteName = "";
    //如果没有传参默认删除当前路由的参数
    if (isRouteName(routeName)) {
      removeRouteName = routeName;
    } else {
      removeRouteName = this.currentRoute.name;
    }

    //路由名存在就删除
    if (isRouteName(removeRouteName)) {
      const flag = muSession.delete(removeRouteName);
      //删除失败&&开发模式就报警告（可以无视，不影响使用）
      if (!flag && process.env.NODE_ENV === "development") {
        console.warn(`muRemove 删除 ${removeRouteName} 路由名的数据失败，可能数据并不存在`);
      }
    }
  }
}
