/*
 * @Author: dongjia
 * @Date: 2021-10-09 10:54:32
 * @LastEditTime: 2021-10-09 10:54:32
 * @LastEditors: aleaner
 * @Description: 跳转用户中心接口
 * @FilePath: \saas-admin-vue\src\modules\common\api\jump-user-admin.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//获取多媒体列表页数据
export const getLoginAccessToken = data => {
  return api({
    url: "/admin/admin/user/getLoginAccessToken",
    method: "post",
    data
  });
};