import store from '@/base/store'
import Vue from 'vue'

export default function handleLocalStorageFetch (options = {}) {
  options = Object.assign({}, {
    /* 缓存的字段名，与 state[key] 对应 */
    key: '',
    /* 拉取请求的 api({}) */
    api: () => Promise.resolve(),
    data: {}
  }, options)

  const {key} = options
  const state = store.state.appDesign
  /* 记录了每个 key 拉取的时间 */
  let record = JSON.parse(JSON.stringify(state.lastFetchTimestampRecord))
  /* 当前 key 上次拉取的时间 */
  let ts = record[key]
  /* 当前 key 的缓存数据 */
  let dt = state[key]
  /* 缓存分钟（半个小时 = 1800000） */
  const CASH_TIME = 1800000 // 毫秒
  // console.log(new Date().getTime() - ts, ts, CASH_TIME, dt.length)
  if (new Date().getTime() - ts < CASH_TIME && dt.length > 0) {
    // use cache
    console.log(key + ': use cache')
    Vue.bus.emit('form-update', {key: key, val: dt})
    return Promise.resolve(dt)
  } else {
    // do not use cache
    console.log(key + ': do not use cache')
    options.api(options.data || {}).then(res => {
      const { data } = res
      record[key] = new Date().getTime()
      let dt2 = []
      if (key === 'mePageLinkOptions') {
        dt2 = data.map(el => {
          el.disabled = false
          return el
        })
      } else if (key === 'shopCategory') {
        dt2 = data.filter(el => el.value !== -1)
      } else {
        dt2 = data
      }
      store.commit('appDesign/setStateAttr', [{
        key: 'lastFetchTimestampRecord', val: {
          ...state.lastFetchTimestampRecord, ...record
        }
      }, {
        key: key, val: dt2
      }])
      Vue.bus.emit('form-update', {key: key, val: dt2})
      return Promise.resolve(dt2)
    }).catch(err => {
      console.log(err)
      return Promise.reject()
    })
  }
}
