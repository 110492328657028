import router from "./router";
import upload from "./store/upload";
import header from "./store/header";
import sidebar from "./store/sidebar";

export default {
  admin: true,
  router,
  store: { upload, header, sidebar }
}
