export default {
  state: {
    positionList: [], //职务列表
  },
  getters: {
    positionList(state) {
      return state.positionList;
    }
  },
  mutations: {
    setPositionList(state, value) {
      state.positionList = value;
    }
  },
  actions: {

  },
}