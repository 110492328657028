<template>
  <el-tabs
    @tab-click="handleClick"
    class="table-tab"
    type="card"
    v-model="Name"
  >
    <el-tab-pane
      :disabled="isLoading"
      :key="tab.name"
      :label="`${tab.label}${tab.total > 0 ? '（' + tab.total + '）' : ''}`"
      :name="tab.name"
      v-for="(tab, index) in tabs"
    >
      <template #label
        >{{ `${tab.label}${tab.total > 0 ? '（' + tab.total + '）' : ''}` }}
        <!--        {{messagesData}}-->
        <!--        {{$route.name}}-->
        <!--
         && ['handling', 'checking', 'unchecked', 'waite_deliver', 'stayCheck'].includes(tab.name)
        -->
        <!-- 情况一：每一个tab视为不同节点 -->
        <el-badge
          :hidden="!(messagesData[tab.url] > 0)"
          :value="messagesData[tab.url] || ''"
          style="overflow: visible"
          v-if="messagesData && tab.badge && tab.url"
        ></el-badge>
        <!-- 情况二：每一个tab视为同个节点，只是列表查询参数不同，如：tab=normal -->
        <el-badge
          v-else-if="messagesData && tab.badge"
          :hidden="!(messagesData[myRouteName] > 0)"
          :value="messagesData[myRouteName] || ''"
          style="overflow: visible"
        ></el-badge>
      </template>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex'

export default {
  model: {
    prop: 'activeName',
    event: 'updateActive',
  },
  props: {
    //当前选中tab
    activeName: {
      type: String,
    },
    //tab配置
    tabs: {
      type: Array, // {label, name, badge?0|1, url?''}[]
    },
    // 列表是否在加载，加载中不能切换tab
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('socket', ['messagesData']),
    Name: {
      get() {
        return this.activeName
      },
      set(val) {
        this.$emit('updateActive', val)
      },
    },
    itemData() {
      const sidebarData = this.$store.getters.sidebarData || []
      return this.findSidebarItem(sidebarData)
    },
    myRouteName() {
      return this.$route.meta.alias || this.$route.name;
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('tab-click', e)
    },
    findSidebarItem(arr) {
      const item = arr.find((a) => a.url === this.$route.name)
      if (item) return item
      let _item
      if (arr.children?.length) {
        for (let i = 0; i < arr.children.length; i++) {
          const current = arr.children[i]
          const __item = this.findSidebarItem(current)
          if (__item) {
            _item = __item
            break
          }
        }
      }
      return _item
    },
    checkItemChildrenMessage(item) {
      if (!item) return false
      if (!this.messagesData) return false

      if (item.url && this.messagesData[item.url] > 0)
        return this.messagesData[item.url]

      const children = item.children || []
      let sum = 0
      for (let i = 0; i < children.length; i++) {
        const current = children[i]
        const _bol = this.checkItemChildrenMessage(current)
        if (_bol) {
          sum += _bol
        }
      }
      return sum
    },
  },
}
</script>

<style></style>
