/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 16:24:38
 * @LastEditTime: 2021-09-16 15:21:07
 * @LastEditors: mulingyuer
 * @Description:企业库
 * @FilePath: \saas-admin-vue\src\modules\gov\router\data-list\enterprise-library.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "enterprise-library", component: { render: h => h("router-view") },
    // meta: {
    //   title: "资源库",
    //   activeMenu: "DataListEnterpriseLibrary",
    //   breadcrumb: { name: "DataListEnterpriseLibrary" }
    // },
    children: [
      {
        path: "", name: "DataListEnterpriseLibrary",
        component: () => import(/* webpackChunkName: "data-list"*/ "../../views/enterprise-library/index")
      }
    ]
  }
]
