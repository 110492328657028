/*
 * @Author: mulingyuer
 * @Date: 2021-09-14 16:20:43
 * @LastEditTime: 2021-09-16 15:21:20
 * @LastEditors: mulingyuer
 * @Description: 会员库
 * @FilePath: \saas-admin-vue\src\modules\gov\router\data-list\member-library.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "member-library", component: { render: h => h("router-view") },
    // meta: {
    //   title: "会员库",
    //   activeMenu: "DataListMemberLibrary",
    //   breadcrumb: { name: "DataListMemberLibrary" }
    // },
    children: [
      {
        path: "", name: "DataListMemberLibrary",
        component: () => import(/* webpackChunkName: "data-list"*/ "../../views/member-library/index")
      }
    ]
  }
]
