import { headerData } from "../api/header";

export default {
  namespaced: true,
  state: {
    isRefresh: false,
    userPassWordTips: false,
    headerData: {}, // header数据
    iframeRouteData: {
      UserCenterApps: 'AppCenter',
      UserCenterExtras: 'CurrentProjectValueAddedServices',
      UserCenterExtrasSms: 'CurrentProjectSmsUrchase',
      MyProjectManage: 'MyCurrentProjectManage',
      MyProjectRenew: 'MyCurrentProjectRenew'
    },
    isOpenAIChat: false
  },
  getters: {
    isRefresh(state) {
      return state.isRefresh;
    },
    userPassWordTips(state) {
      return state.userPassWordTips
    },
    headerData(state) {
      return state.headerData;
    }
  },
  mutations: {
    setIsRefresh(state, value) {
      state.isRefresh = value;
    },
    setUserPassWordTips(state, value) {
      state.userPassWordTips = value;
    },
    setHeaderData: (state, value) => {
      // sessionStorage.setItem('headerData', JSON.stringify(value))
      state.headerData = value;
    }
  },
  actions: {
    getHeaderData({ commit }) {
      headerData().then(res => {
        const { data } = res;
        commit("setHeaderData", data);
      }).catch(() => { })
    },
    openChat({commit, state}, data) {
      console.log(data, 'open ai chat drawer')
      state.isOpenAIChat = true
    },
    closeChat({commit, state}, data) {
      state.isOpenAIChat = false
    }
  }
}
