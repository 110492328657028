export default {
  namespaced: true,
  state: {
    // 是否使用新版本的活动详情
    isNewEdition: 1,
  },
  getters: {},
  mutations: {
    setIsNewEdition(state, value) {
      state.isNewEdition = value
    },
  },
}
