/*
 * @Author: mulingyuer
 * @Date: 2021-09-10 18:35:15
 * @LastEditTime: 2021-09-16 11:12:54
 * @LastEditors: mulingyuer
 * @Description: 商协会列表
 * @FilePath: \gov\router\business-association-list.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: "business-association-list", component: { render: h => h("router-view") },
    // meta: {
    //   title: "商协会列表",
    //   activeMenu: "BusinessAssociationList",
    //   breadcrumb: { name: "BusinessAssociationList" }
    // },
    name: "BusinessAssociationList",
    redirect: { name: "BusinessAssociationListIndex" },
    meta: {
      title:'',
      activeMenu: "BusinessAssociationList",
     
    },
    children: [
   
      {
        path: "", name: "BusinessAssociationListIndex",
        meta: { alias: 'BusinessAssociationList' },
        component:
          () => import(/* webpackChunkName: "business-association-list"*/
            "../views/business-association-list/list"),
      },
      {
        path: "detail/:detailId", name: "BusinessAssociationDetail",
        component:
          () => import(/* webpackChunkName: "business-association-list"*/
            "../views/business-association-list/detail"),
        meta: { title: "详情" }, props: true
      }
    ]
  },
  {
    path: "gov-platform", component: { render: h => h("router-view") },
  
    name: "GovPlatform",
    redirect: { name: "GovPlatformIndex" },


    children: [
      {
        path: "", name: "GovPlatformIndex",
        meta: { title:'',alias: 'GovPlatform' },
        component:
          () => import(/* webpackChunkName: "business-association-list"*/
            "../views/gov-platform/list"),
      },

    ]
  },

]
