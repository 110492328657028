export default {
  namespaced: true,
  state: {
    loading: true,
    // left
    componentLibrary: [],
    // middle
    configs: [],
    // right
    editing: {
      index: -1,
      config: null,
    }
  },
  getters: {},
  mutations: {
    setLoading(state, value) {
      if (state.loading !== value) {
        state.loading = value;
      }
    },
    setComponentLibrary(state, value) {
      state.componentLibrary = value
    },
    // 更新组件库组件的使用次数
    updateComponentLibrary(state) {
      let fieldsMap = {}
      for (let config of state.configs) {
        if (fieldsMap[config.field] !== undefined) {
          fieldsMap[config.field] = fieldsMap[config.field] + 1;
        } else {
          fieldsMap[config.field] = 1;
        }
      }
      for (let group of state.componentLibrary) {
        for (let component of group.components) {
          if (fieldsMap[component.field] !== undefined) {
            component.extra.count = fieldsMap[component.field];
          } else {
            component.extra.count = 0
          }
        }
      }
    },
    setConfigs(state, value) {
      state.configs = value
      this.commit('companyFormEditor/updateComponentLibrary')
    },
    removeConfigsItem(state, index) {
      if (state.editing.index === index) {
        this.commit('companyFormEditor/setEditing', -1)
      }
      state.configs.splice(index, 1)
      this.commit('companyFormEditor/updateComponentLibrary')
    },
    setEditing(state, index) {
      state.editing.index = index
      if (index >= 0) {
        console.log('--------set editing--------')
        const config = state.configs[index]
        console.log('title', JSON.stringify(config.title))
        console.log('field', JSON.stringify(config.field))
        console.log('props.normal', JSON.stringify(config.props?.normal))
        // console.log('extra', JSON.stringify(config.extra))
        console.log('===========================')
        state.editing.config = state.configs[index]
      } else {
        state.editing.config = null
      }
    }
  }
}
