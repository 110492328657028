export default [
  {
    path: 'consult',
    name: 'consult',
    component: { render: (h) => h('router-view') },
    // meta: { title: "" },
    redirect: { name: 'ConsultContentConfig' },
    children: [
      {
        path: 'consult-content-config',
        name: 'ConsultContentConfig',
        component: () => import('../views/consult-content-config'),
        meta: { title: '问答设置' },
      },
      {
        path: 'consult-content',
        component: { render: (h) => h('router-view') },
        name: 'ConsultContent',
        redirect: { name: 'ConsultContentComment' },
        children: [
          {
            path: 'consult-content-list',
            component: { render: (h) => h('router-view') },
            redirect: { name: 'ConsultContentList' },
            children: [
              {
                path: '',
                name: 'ConsultContentList',
                component: () => import('../views/consult-content-list/list'),
              },
              {
                path: 'problem-detail/:id',
                name: 'problemDetail',
                component: () => import('../views/consult-content-list/detail'),
                meta: { title: '' },
              },
            ],
          },
          {
            path: 'consult-content-comment',
            name: 'ConsultContentComment',
            component: () =>
              import(
                '../views/consult-content-comment/consult-content-comment.vue'
              ),
            meta: { title: '回答列表' },
          },
          {
            path: 'consult-content-comment-detail/:id',
            name: 'ConsultContentCommentDetail',
            component: () =>
              import(
                '../views/consult-content-comment/consult-content-comment-detail.vue'
              ),
            meta: {
              title: '问答详情',
            },
          },
          {
            path: 'consult-content-category',
            name: 'ConsultContentCategory',
            component: () =>
              import(
                '../views/consult-content-category/consult-content-category.vue'
              ),
            meta: {
              title: '问题分类',
            },
          },
          {
            path: 'consult-stats',
            name: 'ConsultStats',
            component: () =>
              import(
                '../views/consult-stats.vue'
              ),
            meta: {
              title: '数据看板',
            },
          },
        ],
      },
      {
        path: 'consult-service',
        name: 'ConsultService',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'ConsultServiceReply' },
        children: [
          {
            path: 'reply',
            name: 'ConsultServiceReply',
            component: () => import('../views/consult-service/reply/index.vue'),
          },
          {
            path: 'category-list',
            name:'ConsultServiceCategory',
            component: () => import('../views/consult-service/category-list.vue'),
          },{
            path: 'config',
            name:'ConsultServiceConfig',
            component: () => import('../views/consult-service/config.vue'),
          },{
            path:'survey',
            name:'ConsultServiceSurvey',
            component: () => import('../views/consult-service/survey-list.vue'),
          },{
            path:'survey-form/:id',
            name:'ConsultServiceSurveyForm',
            component: () => import('../views/consult-service/survey-form.vue'),
          },
          {
            path: 'consult-list',
            name:'ConsultServiceChat',  
            component: () => import('../views/consult-service/consult-list.vue'),
          }
          ,{
            path:'consult-service-stats',
            name:'ConsultServiceStats',
            component: () => import('../views/consult-service/consult-service-stats.vue'),
          }
        ],
      },
      {
        path: 'consult-service-workbench',
        name: 'ConsultServiceWorkbench',
        component: () => import('../views/consult-service-workbench.vue'),
        meta: { title: '客服工作台' },
      },
      {
        path: 'consult-Page-config',
        name: 'ConsultPageConfig',
        component: () => import('../views/consult-page-config.vue'),
        meta: { title: '咨询页配置' },
      }
    ],
  },
]
