export default {
  namespaced: true,
  state: {
    token: null,
    avatar: '',
    nickname: '',

    login_method: 'password' // 记录登录方式
  },
  getters: {
    token(state) {
      return state.token;
    },
    avatar(state) {
      return state.avatar;
    },
    nickname(state) {
      return state.nickname;
    },
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
    },
    setAvatar(state, value) {
      state.avatar = value;
    },
    setNickname(state, value) {
      state.nickname = value;
    },
    setLoginMethod(state, value) {
      state.login_method = value;
    }
  },
  actions: {

  },
}
