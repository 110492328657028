import store from "@/base/store";

/*
 * @Author: mulingyuer
 * @Date: 2021-08-20 10:55:58
 * @LastEditTime: 2021-09-16 16:29:30
 * @LastEditors: mulingyuer
 * @Description: 项目共用过滤器
 * @FilePath: \saas-admin-vue\src\modules\common\filters\index.js
 * 怎么可能会有bug！！！
 */
//删除字符串所有空格
const deleteEmpty = function (string) {
  if (typeof string === 'string') {
    return string.split(/\s+/).join("");
  }
  return string;
}

//价格转文字
const toPrice = (val) => {
  if (val) {
    return Number(val).toLocaleString();
  }
  return val;
};

/** SaaS 版本关键词替换显示 */
const saasVersionKeyword = value => {
  const tenantVersion = store.getters.tenant.version
  return tenantVersion === 'school' ? value.replace('会员', '校友') : value
}

export default {
  deleteEmpty,
  toPrice,
  saasVersionKeyword,
}
