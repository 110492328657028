export default [
  {
    path: 'employ',
    name: 'employ',
    component: { render: (h) => h('router-view') },
    // meta: { title: "" },
    redirect: { name: 'EmployCompanyList' },
    children: [
      {
        path: 'employ-config',
        name: 'EmployConfig',
        redirect: { name: 'EmployPageConfig' },
        component: { render: (h) => h('router-view') }, // 这个不能删
        children: [
          {
            path: 'employ-page-config',
            component: () =>
              import('../views/employ-config/employ-page-config.vue'),
            name: 'EmployPageConfig',
          },

          {
            path: 'employ-profile-config',
            name: 'EmployProfileConfig',
            meta: { fitContent: true },
            component: () =>
              import('../views/employ-config/employ-profile-config.vue'),
          },
          {
            path: 'employ-deliver-config',
            name: 'EmployDeliverConfig',
            component: () =>
              import('../views/employ-config/employ-deliver-config.vue'),
          },
        ],
      },
      {
        path: 'employ-company-list',
        name: 'EmployCompanyList',
        redirect: { name: 'EmployCompanyListIndex' },
        component: { render: (h) => h('router-view') }, // 这个不能删
        meta: { title: '企业列表' },
        children: [
          {
            path: 'list',
            component: () => import('../views/employ-company-list/list.vue'),
            name: 'EmployCompanyListIndex',
          },

          {
            path: 'add',
            name: 'EmployCompanyAdd',
            component: () => import('../views/employ-company-list/detail.vue'),
            meta: { title: '企业新增', activeMenu: 'EmployCompanyList' },
          },
          {
            path: 'edit/:id',
            name: 'EmployCompanyEdit',
            component: () => import('../views/employ-company-list/detail.vue'),
            meta: { title: '企业编辑', activeMenu: 'EmployCompanyList' },
          },
        ],
      },
      {
        path: 'employ-career-list',
        name: 'EmployCareerList',
        redirect: { name: 'EmployCareerListIndex' },
        component: { render: (h) => h('router-view') }, // 这个不能删
        meta: { title: '岗位列表' },
        children: [
          {
            path: 'list',
            component: () => import('../views/employ-career-list/list.vue'),
            name: 'EmployCareerListIndex',
          },
          {
            path: 'add',
            name: 'EmployCareerAdd',
            component: () => import('../views/employ-career-list/detail.vue'),
            meta: { title: '岗位新增', activeMenu: 'EmployCareerList' },
          },
          {
            path: 'edit/:id',
            name: 'EmployCareerEdit',
            component: () => import('../views/employ-career-list/detail.vue'),
            meta: { title: '岗位编辑', activeMenu: 'EmployCareerList' },
          },
        ],
      },
      {
        path: 'employ-career-category-list',
        name: 'EmployCareerCategoryList',
        component: () => import('../views/employ-career-category-list.vue'),
      },
      {
        path: 'employ-deliver-list',
        name:'EmployDeliverList',
        component: () => import('../views/employ-deliver-list.vue'),
      },
      // 投递用户列表
      {
        path: 'employ-deliver-user-list',
        name:'EmployDeliverUserList',
        component: () => import('../views/employ-deliver-user-list.vue'),
      },
      // 数据看板
      {
        path: 'employ_data_stats',
        name:'EmployDataStats',
        component: () => import('../views/employ-data-stats.vue'),
      }
    ],
  },
]
