export default [
  {
    path: "finance", name: "finance", component: () => import("../views/index"), meta: { title: "" },
    redirect: { name: "CycOrderList" },
    children: [
      { path: "order-list", name: "CycOrderList", component: () => import("../views/cost-list"),
        // meta: { title: "会费列表" }
        },
      { path: "member-order-list", name: "CycMemberOrderList", component: () => import("../views/cost-list"), meta: { title: "会费列表" } },
      { path: "cost-rule", name: "CycOrderConfig", component: () => import("../views/cost-rule"), meta: { title: "会费规则" } },
    ]
  }
]
