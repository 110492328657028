export default [
  {
    path: "commerce-spirit", name: "CycSpiritList", component: { render: h => h("router-view") },
    redirect: { name: "CycSpiritListIndex" },
    children: [
      { path: "list", name: "CycSpiritListIndex", component: () => import("../views/spirit-list"),
      },
      { path: "add/:type/:id", name: "AddSpirit", component: () => import("../views/add-spirit"), meta: { title: "新增" } },
    ]
  }
]
