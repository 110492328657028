export default [
  {
    path: "circle-group", component: { render: h => h("router-view") },
    // meta: { activeMenu: "CircleGroupList", breadcrumb: { title: "小组列表", name: "CircleGroupList" } },
    children: [
      {
        path: "group", component: () => import("../views/group/index"),
        // meta: { title: "", breadcrumb: { title: "", name: "CircleGroupList" } },
        name: "CircleGroupList",
        redirect: {
          name: "CircleGroupListIndex"
        },
        children: [
          { path: "list", name: "CircleGroupListIndex", component: () => import("../views/group/group-list"), meta: { title: "" } },
          { path: "add-detail/:id", name: "addGroup", component: () => import("../views/group/group-detail"), meta: { title: "新增" } },
          { path: "detail/:id", name: "groupDetail", component: () => import("../views/group/group-detail"), meta: { title: "小组详情" } },
          { path: "members-admin/:id", name: "memberAdmin", component: () => import("../views/group/member-admin"), meta: { title: "成员管理" } },
          { path: "member-activity/:id", name: "memberActivity", component: () => import("../views/group/member-activity"), meta: { title: "成员活跃度" } },
          { path: "preference/:id", name: "preference", component: () => import("../views/group/preference"), meta: { title: "互动内容类型偏好" } },
          { path: "preference-chart/:id", name: "preferenceChart", component: () => import("../views/group/preference-chart"), meta: { title: "互动内容类型偏好图表分析" } },

          {
            path: "group-topic", component: { render: h => h("router-view") },
            meta: { title: "", breadcrumb: { title: "话题管理", name: "groupTopicList" }, activeMenu: "CircleGroupList" },
            children: [
              { path: "group-topic-list/:id", name: "groupTopicList", component: () => import("../views/topic-admin/list"), meta: { title: "" } },
              {
                path: "group-topic-detail", component: { render: h => h("router-view") }, meta: { title: "", breadcrumb: { title: "话题详情", name: "groupTopicDetail" } },
                children: [
                  { path: "group-topic-detail/:id/:topic_id", name: "groupTopicDetail", component: () => import("../views/post-admin/list"), meta: { title: "" } },
                  {
                    path: "add-group-topic-post/:id/:topic_id/:group_id", name: "addGroupTopicPost", component: () => import("../views/post-admin/add-post"), meta: { title: "新增" }
                  },
                  {
                    path: "", component: { render: h => h("router-view") },
                    meta: { activeMenu: "groupTopicPostDetail", breadcrumb: { title: "帖子详情", name: "groupTopicPostDetail" } },
                    children: [
                      { path: "group-topic-post-detail/:id/:topic_id/:group_id", name: "groupTopicPostDetail", component: () => import("../views/post-admin/detail"), meta: { title: "" } },
                      { path: "group-topic-edit-post/:id/:topic_id/:group_id", name: "editGroupTopicPost", component: () => import("../views/post-admin/add-post"), meta: { title: "编辑" } },
                    ]
                  },
                ]
              },
            ]
          },
          {
            path: "group-post", component: { render: h => h("router-view") },
            meta: { title: "", breadcrumb: { title: "帖子管理", name: "groupPostList" }, activeMenu: "CircleGroupList" },
            children: [
              { path: "group-post-list/:id", name: "groupPostList", component: () => import("../views/post-admin/list"), meta: { title: "" } },
              { path: "add-post/:id/:group_id", name: "addGroupPost", component: () => import("../views/post-admin/add-post"), meta: { title: "新增" } },
              {
                path: "", component: { render: h => h("router-view") },
                meta: { activeMenu: "groupPostDetail", breadcrumb: { title: "帖子详情", name: "groupPostDetail" } },
                children: [
                  { path: "group-post-detail/:id/:group_id", name: "groupPostDetail", component: () => import("../views/post-admin/detail"), meta: { title: "" } },
                  { path: "group-edit-post/:id/:group_id", name: "editGroupPost", component: () => import("../views/post-admin/add-post"), meta: { title: "编辑" } },
                ]
              },
            ]
          },
          { path: "group-activity-list/:id", name: "groupActivityList", component: () => import("../views/group/group-activity-list"), meta: { title: "活动管理" } },
        ]
      },
    ]
  },
  // { path: "category-list", name: "CircleCategoryList", component: () => import("../views/category-list"), meta: { title: "分类列表" } },
  {
    path: "circle-topic", component: { render: h => h("router-view") },
    // meta: { activeMenu: "CircleTopicList", breadcrumb: { title: "话题列表", name: "CircleTopicList" } },
    name: "CircleTopicList",
    redirect: {name: "CircleTopicListIndex"},
    children: [
      {
        path: "topic-list", name: "CircleTopicListIndex", component: () => import("../views/topic-admin/list"),
        meta: {alias: 'CircleTopicList'}
      },
      {
        path: "detail", component: { render: h => h("router-view") },
        meta: { activeMenu: "CircleTopicList", breadcrumb: { title: "话题详情", name: "topicDetail" } },
        children: [
          { path: "topic-detail/:topic_id", name: "topicDetail", component: () => import("../views/post-admin/list"), meta: { title: "" } },
          {
            path: "add-topic-post/:id/:topic_id", name: "addTopicPost", component: () => import("../views/post-admin/add-post"), meta: { title: "新增" }
          },
          {
            path: "", component: { render: h => h("router-view") },
            meta: { activeMenu: "topicPostDetail", breadcrumb: { title: "帖子详情", name: "topicPostDetail" } },
            children: [
              { path: "topic-post-detail/:id/:topic_id", name: "topicPostDetail", component: () => import("../views/post-admin/detail"), meta: { title: "" } },
              { path: "topic-edit-post/:id/:topic_id", name: "editTopicPost", component: () => import("../views/post-admin/add-post"), meta: { title: "编辑" } },
            ]
          },
        ]
      }
    ]
  },
  {
    path: "circle-post", component: { render: h => h("router-view") },
    // meta: { activeMenu: "CirclePostList", breadcrumb: { title: "帖子列表", name: "CirclePostList" } },
    name: "CirclePostList",
    redirect: {name: "CirclePostListIndex"},
    children: [
      {
        path: "post-list", name: "CirclePostListIndex", component: () => import("../views/post-admin/list"),
        meta: {alias: 'CirclePostList'}
      },
      { path: "add-post/:id", name: "addPost", component: () => import("../views/post-admin/add-post"), meta: { title: "新增" } },
      {
        path: "detail/", component: { render: h => h("router-view") },
        meta: { activeMenu: "CirclePostList", breadcrumb: { title: "帖子详情", name: "postDetail" } },
        children: [
          { path: "post-detail/:id", name: "postDetail", component: () => import("../views/post-admin/detail"), meta: { title: "" } },
          { path: "add-post/:id", name: "editPost", component: () => import("../views/post-admin/add-post"), meta: { title: "编辑" } },
        ]
      },
    ]
  },
  {
    path: "circle-comment", component: { render: h => h("router-view") },
    // meta: { activeMenu: "CircleCommentList", breadcrumb: { title: "评论列表", name: "CircleCommentList" } },
    name: "CircleCommentList",
    redirect: {name: "CircleCommentListIndex"},
    children: [
      {
        path: "comment-list", name: "CircleCommentListIndex", component: () => import("../views/comment-admin/list"),
        meta: {alias: 'CircleCommentList'}
      },
      {
        path: "comment-detail/:id", name: "commentDetail", component: () => import("../views/comment-admin/detail"), meta: { title: "评论详情" }
      },
    ]
  },
  {
    path: "black-list", name: "CircleBlackList", component: () => import("../views/black-member/list"),
    // meta: { activeMenu: "CircleBlackList", title: "黑名单记录" },
  },
  {
    path: "circle-config", name: "CircleConfig", component: () => import("../views/community-config"),
    // meta: { activeMenu: "CircleConfig", title: "设置" },
  },
]
