/*
 * @Author: mulingyuer
 * @Date: 2021-10-19 11:53:55
 * @LastEditTime: 2021-10-19 16:21:31
 * @LastEditors: mulingyuer
 * @Description:路由切换参数
 * @FilePath: \super-admin-vue\src\base\plugins\mu-router-swatch-data\index.js
 * 怎么可能会有bug！！！
 */
import { muRouterBefore, muRouterAfter, install, muSession } from "./core/router";

//MuRouterSwatchData.install(VueRouter);
export default {
  install
}

export {
  muRouterBefore,
  muRouterAfter,
  install,
  muSession
}