/*
 * @Author: mulingyuer
 * @Date: 2021-07-22 16:32:20
 * @LastEditTime: 2021-09-22 15:14:54
 * @LastEditors: aleaner
 * @Description: 默认页面入口
 * @FilePath: \saas-admin-vue\src\modules\index\config.js
 * 怎么可能会有bug！！！
 */

export default {
  is_main_route: true, //声明是独立主页
  router: [
    {
      path: '/',
      name: '',
      component: { render: (h) => h("router-view") },
      // redirect: { name: 'Home' },
    },
  ],
}
