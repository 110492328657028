export default [
  {
    path: "goods-library", component: { render: h => h("router-view") },
    name: "DataListShopGoods",
    redirect: {name: "DataListShopGoodsIndex"},
    children: [
      { path: "list", name: "DataListShopGoodsIndex", component: () => import("../../views/goods-library/index"),
      },
    ]
  }
]
