/*
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:30:00
 * @LastEditTime: 2021-04-08 18:39:04
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\vote\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: 'plugins',
    component: { render: (h) => h('router-view') },
    // meta: { activeMenu: "VoteList" },
    name: 'Plugin',
    redirect: { name: 'pluginsOverview' },
    children: [
      {
        path: '',
        name: 'pluginsOverview',
        component: () => import('../views/plugins-overview'),
      },
    ],
  },
]
