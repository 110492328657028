export default [
  {
    path: 'user-check-settings',
    name: 'UserCheck',
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: 'settings',
        name: 'UserCheckSettings',
        component: () => import('../views/settings'),
      },
    ],
  },
]
