import router from "./router/index";
import positionList from "./store/position-list";
import formPage from "./store/form-page"
import dataStats from "./store/data-stats"

export default {
  router,
  store: {
    positionList,
    formPage,
    dataStats
  }
}
