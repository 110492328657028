/*
 * @Author: dongjia
 * @Date: 2021-12-14 16:44:12
 * @LastEditTime: 2021-12-14 16:58:37
 * @LastEditors: aleaner
 * @Description: 组织吗，指标选择列表
 * @FilePath: \organization-admin\store\data-stats.js
 * 怎么可能会有bug！！！
 */
export default {
  namespaced: true,
  state: {
    organizationList: [{ project_id: -1, name: "全部" }], //组织列表
    targetList: [], // 指标列表
  },
  getters: {
    organizationList(state) {
      return state.organizationList;
    },
    targetList(state) {
      return state.targetList;
    }
  },
  mutations: {
    setOrganizationList(state, value) {
      state.organizationList = value;
    },
    setTargetList(state, value) {
      state.targetList = value;
    }
  },
  actions: {

  }
}
