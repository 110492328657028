export default {
  state: {
    authStatus: 1,
  },
  getters: {
    authStatus(state) {
      return state.authStatus
    }
  },
  mutations: {
    setAuthStatus(state, value) {
      state.authStatus = value;
    },
  },
  actions: {

  },
}
