/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-16 15:42:36
 */
export default [
  {
    path: "donation-manage",
    name: "donation-manage",
    component: { render: (h) => h("router-view") },
    // meta: { title: "" },
    redirect: { name: "donationProject" },
    children: [
      {
        path: "project",
        name: "donationProject",
        component: () => import("../views/donation-project/project-index"),
        // meta: {
        //   // title: "捐款项目",
        //   activeMenu: "donationProject",
        //   breadcrumb: { name: "" },
        // },
        redirect: {name: "donationProjectIndex"},
        children: [
          {
            path: "donation-project",
            name: "donationProjectIndex",
            component: () => import("../views/donation-project/index"),
          },
          {
            path: "add-project/:id",
            name: "addProject",
            component: () => import("../views/donation-project/add-project"),
            meta: { title: "新增" },
          },
          {
            path: "Edit-project/:id",
            name: "EditProject",
            component: () => import("../views/donation-project/add-project"),
            meta: { title: "项目详情" },
          },
          {
            path: "message-list/:id",
            name: "MessageList",
            component: () => import("../views/donation-project/messageList"),
            meta: { title: "留言列表" },
          },
          {
            path: "donation-stats/:id",
            name: "DonationStats",
            component: () => import("../views/donation-project/donationStats"),
            meta: { title: "数据统计" },
          },
        ],
      },
      {
        path: "donation-order",
        name: "donationOrder",
        component: () => import("../views/donation-order/donation-order"),
        meta: { title: "捐款订单" },
      },
      {
        path: "donation-config",
        name: "donationConfig",
        component: () => import("../views/donation-config/donation-config"),
      },
    ],
  },
];
