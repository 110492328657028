/*
 * @Author: mulingyuer
 * @Date: 2021-09-14 16:27:17
 * @LastEditTime: 2021-10-20 18:47:44
 * @LastEditors: mulingyuer
 * @Description: 数据列表入口
 * @FilePath: \saas-admin-vue\src\modules\gov\config.js
 * 怎么可能会有bug！！！
 */
import router from "./router";
import bigData from "./store/big-data";

export default {
  router,
  store: {
    bigData,
  }
}