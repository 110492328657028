export default [
  {
    path: "annual_report", component: { render: h => h("router-view") },
    //   meta: { title: "会刊列表", breadcrumb: { name: "JournalList" } },
    name: "AnnualReport",
    redirect: { name: "AnnualReportIndex" },
    children: [
      {
        path: "index", name: "AnnualReportIndex", component: () => import("../views/index"),
        meta: { fitContent: true }
      },
    ]
  }
]
