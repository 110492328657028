/*
 * @Author: mulingyuer
 * @Date: 2021-07-06 12:03:16
 * @LastEditTime: 2021-07-06 12:03:38
 * @LastEditors: mulingyuer
 * @Description: 证书管理
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\config.js
 * 怎么可能会有bug！！！
 */
import router from "./router";

export default {
  router
}
