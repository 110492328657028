
export default [
    {
        path: "signup", name: "SignupList", component: { render: h => h("router-view") },
        // meta: { title: "", activeMenu: "SignupList" },
        redirect: { name: "SignupListIndex" },
        children: [
            { path: "index", name: "SignupListIndex", component: () => import("../views/signup-list"), meta: { title: "" } },
            { path: "add-signup/:sign/:id", name: "addSignup", component: () => import("../views/add-signup"), meta: { title: "新增" } },
            { path: "design-signup/:sign/:id", name: "designSignup", component: () => import("../views/add-signup"), meta: { title: "字段配置" } },
            { path: "edit-signup/:sign/:id", name: "editSignup", component: () => import("../views/add-signup"), meta: { title: "编辑" } },
            { path: "signup-tube/:id", name: "signupTube", component: () => import("../views/signup-tube"), meta: { title: "信息管理" } },
        ]
    }
]
