export default [
  {
    path: "ad", name: "advert", component: () => import("../views/index"),
    redirect: { name: "AdPosition" },
    children: [
      { path: "ad-position", name: "AdPosition", component: () => import("../views/advert-position"), meta: { title: "广告位置" } },
      {
        path: "ad-list", name: "AdList", component: { render: h => h("router-view") },
        // meta: { title: "广告列表", activeMenu: 'AdList' },
        redirect: { name: "AdListIndex" },
        children: [
          { path: "list", name: "AdListIndex", component: () => import("../views/advert-list"), meta: { title: "" } },
          { path: "add-advert", name: "AddAdvert", component: () => import("../views/advert-edit"), meta: { title: "新增" } },
          { path: "edit-advert/:id", name: "edit-advert", component: () => import("../views/advert-edit"), meta: { title: "编辑" } },
        ]
      },
      { path:'banner-category-list',name:'BannerCategoryList', component:()=> import("../views/banner-category")},
    ],
  }
]
