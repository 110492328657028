/*
 * @Author: dongjia
 * @Date: 2021-12-08 15:44:47
 * @LastEditTime: 2021-12-13 16:31:12
 * @LastEditors: aleaner
 * @Description:
 * @FilePath: \organization-admin\router\data-stats.js
 * 怎么可能会有bug！！！
 */

export default [
  {
    path: "organize-data", component: { render: h => h("router-view") },
    // meta: { breadcrumb: { name: "CycUserStat" }, title: "数据统计" },
    children: [
      {
        path: "data-stats", name: "CycUserStat", component: () => import('../views/data-stats/index.vue'), meta: {
          title: "",
        }
      },
      {
        path: "stats-edit", name: "statEdit", component: () => import('../views/data-stats/survey-edit.vue'), meta: {
          title: "编辑",
        }
      }
    ]
  }
]
