// {
//   "node": "approval",
//   "name": "审批管理",
//   "is_open_new_window": 0,
//   "url": "",
//   "icon": "https://testsaasapi.shetuan.pro/static/approval/images/admin_node_icons/classroom_normal.png",
//   "icon_selected": "https://testsaasapi.shetuan.pro/static/approval/images/admin_node_icons/classroom_selected.png",
//   "children": [
//   {
//     "node": "approval_list",
//     "name": "审批列表",
//     "is_open_new_window": 0,
//     "url": "ApprovalList",
//     "icon": "",
//     "icon_selected": ""
//   },
//   {
//     "node": "approval_tmpl_list",
//     "name": "模板管理",
//     "is_open_new_window": 0,
//     "url": "ApprovalTmplList",
//     "icon": "",
//     "icon_selected": ""
//   },
//   {
//     "node": "approval_category_list",
//     "name": "分类管理",
//     "is_open_new_window": 0,
//     "url": "ApprovalCategoryList",
//     "icon": "",
//     "icon_selected": ""
//   }
// ]
// }

export default [
  /* path 不能加 / ，否则会不带 /admin */
  {
    path: "approval", component: {render: h => h("router-view")},
    children: [
      {
        path: "",
        name: "ApprovalList",
        // meta: {title: "审批列表",breadcrumb: { name: "ApprovalList" },},
        component: {render: h => h("router-view")},
        redirect: {name: "ApprovalListIndex"},
        children: [
          {
            path: 'list/:tab',
            name: "ApprovalListIndex",
            component: () => import("../views/index"),
            meta: {alias: 'ApprovalList'}
          },
          {
            path: "detail/:id",
            name: 'ApprovalDetail',
            meta: {title: '详情', activeMenu: "ApprovalList"},
            component: () => import("../views/approval-detail")
          },
        ]
      },
      {
        path: 'template',
        name: "ApprovalTmplList",
        component: {render: h => h("router-view")},
        redirect: {name: "ApprovalTmplListIndex"},
        children: [
          {
            path: 'list', name: "ApprovalTmplListIndex",
            component: () => import("../views/template-list"),
          },
          {
            path: 'add', name: "ApprovalTmplAddNew",
            meta: {
              title: '新增',
              activeMenu: "ApprovalTmplList",
            }, component: () => import('../views/template-detail')
          },
          {
            path: 'detail/:id', name: "ApprovalTmplDetail",
            meta: {
              title: '编辑',
              activeMenu: "ApprovalTmplList"
            }, component: () => import('../views/template-detail')
          },
          {
            path: 'design/:id',
            name: "ApprovalTmplDesign",
            component: () => import('../views/template-design')
          },
          {
            path: 'record/:id',
            name: "",
            redirect: {name: 'ApprovalTmplRecordUp'},
            component: {render: h => h("router-view")},
            children: [
              {
                path: '', name: 'ApprovalTmplRecordUp',
                meta: {
                  title: '申请记录',
                  activeMenu: "ApprovalTmplList"
                },
                redirect: {name: "ApprovalTmplRecord"},
                component: {render: h => h("router-view")},
        
                // component: () => import('../views/index'),
                children: [
                  {
                    path: '', name: 'ApprovalTmplRecord',
                    // meta: {
                    //   title: '申请记录',
                    //   activeMenu: "ApprovalTmplList"
                    // },
                    component: () => import('../views/index'),
                  },
                  {
                    path: 'view', name: "ApprovalRecordDetail",
                    meta: {
                      title: '查看',
                      activeMenu: "ApprovalTmplList"
                    }, component: () => import('../views/approval-detail')
                  },
                  // {
                  //   path: '', name: '',
                  //   component: {render: h => h("router-view")},
                  //   redirect: {name: 'ApprovalTmplRecordApprovalRecord'},
                  //   children: [
                  //     {
                  //       path: ':name', name: 'ApprovalTmplRecordApprovalRecord',
                  //       component: () => import('../views/index'),
                  //       meta: {
                  //         title: '审批记录',
                  //         activeMenu: "ApprovalTmplList"
                  //       }
                  //     }
                  //   ]
                  // }
                ]
              },
            ]
          }
        ]
      },
      {
        path: "category",
        name: "ApprovalCategoryList",
        meta: {title: "分类管理"},
        component: () => import("../views/category-list")
      }
    ]
  }
]
