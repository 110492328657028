import Vue from 'vue'
import App from './App.vue'
import router from './base/router'
import store from './base/store'

// import tinymce from 'tinymce'
// import VueTinymce from '@packy-tang/vue-tinymce'
//
// Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
// Vue.use(VueTinymce)              // 安装vue的tinymce组件

//注册路由守卫
import guard from './router/guard';
guard(router);

//element 引入
import { enCode, deCode } from './base/utils/tool.js'

import ElementUI from 'element-ui';
import './base/assets/scss/element-variables.scss';

//全局修改默认配置
ElementUI.Dialog.props.closeOnClickModal.default=false;
ElementUI.Dialog.props.closeOnPressEscape.default=false;
// console.log(ElementUI.DatePicker.props)
ElementUI.DatePicker.props.timeArrowControl=true; // 不要秒
// ElementUI.Badge.props.max = 99; // 默认 99+，这样写没有用，还会报错！
Vue.use(ElementUI);

// 组件通信 vue-bus
import VueBus from 'vue-bus'
Vue.use(VueBus);

// 全局注册列表tabs
import ListTabs from "./base/components/Default/ListTabs.vue"
Vue.component('list-tabs', ListTabs)

/* 全局注册自定义标签组件，对齐 uni-app */
/* view 是 vue 的保留名称，会报错 */
// import View from "@/modules/app-design/components/Base/View";
// Vue.component('view', View)

// import 'default-passive-events';  // 去除touch事件谷歌提示
require("./base/utils/polyfill"); // 去除touch事件谷歌提示
import api from "@/base/utils/request";

//axios
// import axios from "axios";
// Vue.prototype.$axios = axios;

import xss from 'xss'
Vue.prototype.xss = xss // 挂在全局是为了在 loader中能够通过该方法去过滤全局的 v-html 返回数据

const mergeModel = require('./base/utils/model');
Vue.prototype.$mergeModel = mergeModel;

// 全局图片预览方法激活
import Preview from "@/base/utils/image-preview.js";
Vue.use(Preview);

const hls = require('videojs-contrib-hls')
Vue.use(hls)
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

/**
 * axios请求
 * @param {String} url    接口url
 * @param {Object} data   接口参数
 * @param config
 */
Vue.prototype.$request = function (url, data, config) {
  return api({
    url,
    method: 'post',
    data,
    ...config
  })
}

Vue.prototype.$enCode = enCode
Vue.prototype.$deCode = deCode

// 全局过滤器
import filters from '@/base/utils/globalFilters';
Object.keys(filters).forEach(item => Vue.filter(item, filters[item]));
//项目过滤器
import saasFilter from "@/modules/common/filters";
Object.keys(saasFilter).forEach(key => Vue.filter(key, saasFilter[key]))

//自定义指令
import Directives from '@/base/directives'
Vue.use(Directives);

import BaiduMap from "vue-baidu-map" // 百度地图引入
Vue.use(BaiduMap, { ak: 'SsvbYw5NRyodd8IgvZ0v8zvrYG2uobOs' })

//全局mixins
import fixImageUrl from "@/base/mixins/image";
Vue.mixin(fixImageUrl);

// 项目mixins
import handleJumpUserAdmin from "@/modules/common/mixins/jump-user-admin";
Vue.mixin(handleJumpUserAdmin);
import SaaSVersionKeyword from "@/modules/common/mixins/saas-version-keyword"
Vue.mixin(SaaSVersionKeyword)

// 重置样式
import "./base/assets/scss/reset.scss";
import "./base/assets/scss/style.scss";

// console.log(process.env.NODE_ENV)
// console.log(process.env.VUE_APP_MODE)

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/* 自定义指令 */
// table表格触底事件
Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper') || el.querySelector('.el-scrollbar__wrap')
    selectWrap.addEventListener('scroll', function () {
      let sign = 1
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign) {
        if (binding && binding.value) binding.value()
      }
    })
  }
})

import directives from '@/base/directives'
Vue.use(directives)

// 引入自动更新提醒
import autoUpdate from './auto-update'
// 非生产环境使用
process.env.NODE_ENV === 'production' && autoUpdate.start()
