export default [
  {
    path: 'retail',
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: 'config',
        component: () => import('../views/setting'),
        name: 'RetailConfig',
      },
      {
        path: 'goods',
        component: { render: (h) => h("router-view") },
        name: 'RetailGoods',
        redirect: {name: 'RetailGoodsIndex'},
        children: [
          {
            path: 'list',
            component: () => import('../views/goods'),
            name: 'RetailGoodsIndex',
          }
        ]
      },
      {
        path: 'record',
        component: { render: (h) => h("router-view") },
        name: 'RetailRecord', // 对应后端 menu/index 接口的 url
        redirect: {name: 'RetailRecordIndex'},
        children: [
          {
            path: 'list',
            component: () => import('../views/record'),
            name: 'RetailRecordIndex',
          }
        ]
      },
      {
        path: 'examine',
        component: { render: (h) => h("router-view") },
        name: 'RetailWithdraw', // 对应后端 menu/index 接口的 url
        redirect: {name: 'RetailWithdrawIndex'},
        children: [
          {
            path: 'list',
            component: () => import('../views/examine'),
            name: 'RetailWithdrawIndex',
            meta: {alias: 'RetailWithdraw'}
          }
        ]
      },
    ],
  },
]
