import { getPageDetail, getSystemSettings } from "../api/page";
import {
  searchTypes,
  getAllCategories,
  getAllShopCategory,
  allGroupList,
  brandCategoryOptions,
  activityPhotoCategoryOptions,
  spiritColumnOptions,
  mePageLinkOptions,
  onlineLearningCategories,
  memberIndexQuickEnterOptions,
  getMemberIdentity,
  getParentToList
} from "../api/components";
import { formatConfigData } from "@/modules/app-design/assets/js/tool";

import debounce from "lodash/debounce";
import handleLocalStorageFetch from "@/modules/app-design/store/helper";
import api from "@/base/utils/request";
import Vue from "vue";

let { setting } = require("../assets/jsons/component-library.json");

export default {
  namespaced: true,
  state: {
    isRefreshPages: false,
    articlepage: false, // 文章列表组件是否可以分页加载
    isFreeContainer: false, // 判断当前选中是否是自由容器组件
    /* 辅助判断组件使用次数 */
    clone: {
      key: "",
      data: null,
    },
    pageDetail: null,
    indexPageDetail: null,
    loading: true,
    library: [],
    systemList: [],
    // 页面装修组织架构是否显示会员身份
    memberIdentity:0,
    /* 修改前的数据 */
    backupJson: "",
    bottomNavBackupJson: "",
    /* 外部事件触发左侧tabs */
    paneName: "page",
    /* 右侧自定义拖拽组件配置编辑数据 */
    componentListConfig: {
      list: [],
      current_select: -1,
      //自由容器子组件下标
      free_container_select: -1,
      maintain_select: false,
    },
    /* 基础业务组件表单接口下拉选项存储 */
    searchTypes: [], // 搜索框搜索内容选项
    articleCategory: [], // 文章分类选项
    specialArticleCategory: [], // 文章专题分类选项
    shopCategory: [], // 小商店分类选项
    brandCategoryOptions: [], // 专题分类选项
    memberIndexQuickEnters: [], // 会员主页快捷入口选项
    // 小组选项
    hotGroupList:[],
    // 列表形式显示架构
    parentToList:[],
    /* 模板搜索内容 */
    templateSearch: "",
    showTemplate: false, // 控制更换模板页面是否显示
    resetPageDetail: false,
    /* 地图组件相关 */
    addressOptions: [],
    searching: false, /* 正在对关键词进行检索 */
    /* TODO: 把 main.selected 统一放在这里 */
    selected: -1,

    /** Persisted State */
    // 重新从 `页面装修-客户端管理` 进入就会重置为0
    lastFetchTimestampRecord: {},
    mePageLinkOptions: [], // 个人中心入口选项数据
    activityPhotoCategoryOptions: [], // 云相册分类选项
    spiritColumnOptions: [], // 会员风采栏目选项
    onlineLearningOptions: [], // 在线学习分类选项数据

    /* 如使用系统页的底部导航，则为 false */
    isUseCustomIndex: false,
    customIndexPageName: "",

    unitFormFields: [],
    userFormFields: [],
    isUnit: 0,
  },
  getters: {
    isRefreshPages(state) {
      return state.isRefreshPages;
    },
    clone(state) {
      return state.clone;
    },
    pageDetail(state) {
      return state.pageDetail;
    },
    loading(state) {
      return state.loading;
    },
    library(state) {
      return state.library;
    },
    systemList(state) {
      return state.systemList;
    },
    backupJson(state) {
      return state.backupJson;
    },
    bottomNavBackupJson(state) {
      return state.bottomNavBackupJson;
    },
    paneName(state) {
      return state.paneName;
    },
    articlepage(state) {
      return state.articlepage;
    },
    isFreeContainer(state) {
      return state.isFreeContainer;
    },
    searchTypes(state) {
      return state.searchTypes;
    },
    articleCategory(state) {
      return state.articleCategory;
    },
    specialArticleCategory(state) {
      return state.specialArticleCategory;
    },
    shopCategory(state) {
      return state.shopCategory;
    },
    brandCategoryOptions(state) {
      return state.brandCategoryOptions;
    },
    activityPhotoCategoryOptions(state) {
      return state.activityPhotoCategoryOptions;
    },
    spiritColumnOptions(state) {
      return state.spiritColumnOptions;
    },
    mePageLinkOptions(state) {
      return state.mePageLinkOptions;
    },
    onlineLearningOptions(state) {
      return state.onlineLearningOptions;
    },
    componentListConfig(state) {
      return state.componentListConfig;
    },
    templateSearch(state) {
      return state.templateSearch;
    },
    showTemplate(state) {
      return state.isShowTemplate;
    },
    resetPageDetail(state) {
      return state.resetPageDetail;
    },
    addressOptions(state) {
      return state.addressOptions;
    },
    isUseCustomIndex: (state) => state.isUseCustomIndex,
    customIndexPageName: (state) => state.customIndexPageName,
  },
  mutations: {
    setSearching(state, value) {
      state.searching = value;
    },
    setIsRefreshPages(state, value) {
      state.isRefreshPages = value;
    },
    setClone(state, value) {
      state.clone = value;
    },
    setPageDetail(state, value) {
      state.pageDetail = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setLibrary(state, value) {
      state.library = value;
    },
    setSystemList(state, value) {
      state.systemList = value;
    },
    setMemberIdentity(state, value) {
      state.memberIdentity = value;
    },
    setBackupJson(state, value) {
      state.backupJson = value;
    },
    setPaneName(state, value) {
      state.paneName = value;
    },
    setArticlePage(state, value) {
      state.articlepage = value;
    },
    setIsFreeContainer(state, value) {
      state.isFreeContainer = value;
    },
    setSearchTypes(state, value) {
      state.searchTypes = value;
    },
    setArticleCategory(state, value) {
      state.articleCategory = value;
    },
    setSpecialArticleCategory(state, value) {
      state.specialArticleCategory = value;
    },
    setShopCategory(state, value) {
      state.shopCategory = value;
    },
    setBrandCategoryOptions(state, value) {
      state.brandCategoryOptions = value;
    },
    setActivityPhotoCategoryOptions(state, value) {
      state.activityPhotoCategoryOptions = value;
    },
    setSpiritColumnOptions(state, value) {
      state.spiritColumnOptions = value;
    },
    setMePageLinkOptions(state, value) {
      state.mePageLinkOptions = value;
    },
    setComponentListConfig(state, value) {
      state.componentListConfig = value;
    },
    setTemplateSearch(state, value) {
      state.templateSearch = value;
    },
    setShowTemplate(state, value) {
      state.showTemplate = value;
    },
    setResetPageDetail(state, value) {
      state.resetPageDetail = value;
    },
    setAddressOptions(state, value) {
      state.addressOptions = value;
    },
    setOnlineLearningOptions(state, value) {
      state.onlineLearningOptions = value;
    },
    /**
     * 更新state数据
     * @param {String} state
     * @param {Object} param 可以是对象数组或者对象 {key: [String], val: [Object]}
     */
    setStateAttr(state, param) {
      if (param instanceof Array) {
        for (let item of param) {
          state[item.key] = item.val;
        }
      } else {
        state[param.key] = param.val;
      }
    },
  },
  actions: {
    /**
     * 获取页面的详情数据
     * @param commit
     * @param state
     * @param data
     */
    getPageDetail({ commit, state }, data) {
      /* 传入requestData对象 */
      getPageDetail(data.requestData)
        .then((res) => {
          let resData = res.data;
          // console.log('------------------resData.config------------------')
          // console.log(resData.config)
          // console.log('--------------------------------------------------')

          /* 重置一下 */
          // if (data.requestData.system_page === "eleCard")
          //   commit("setStateAttr", { key: "isUnit", val: 0 });

          /* 新增的页面，config字段可能没有东西，给他默认的页面设置 */
          if (resData &&
            (!resData.config || JSON.stringify(resData.config) === "[]") &&
            data.newTitle
          ) {
            console.log("是新增的页面，还没有配置");
            resData.config = formatConfigData({});
            commit("setBackupJson", JSON.stringify(resData.config));

            /* 页面设置的标题自动填入新增时的标题，此修改会触发离开时询问保存 */
            resData.config.page.props.normal.titleConfig = {
              name: data.newTitle,
              isShow: 1,
            };
          } else if (resData) {
            // TEST：页面props直接使用合并后的
            resData.config = formatConfigData(
              resData.config,
              resData.system_page
            );
            // console.log('------------------resData.config.list------------------')
            // console.log(resData.config.list)
            // console.log('-------------------------------------------------------')
            commit("setBackupJson", JSON.stringify(resData.config));
          } else {
            // 数据不存在！
            commit("setBackupJson", JSON.stringify(undefined));
          }

          commit("setResetPageDetail", false);
          commit("setPageDetail", resData);

          if (resData?.system_page === "index")
            commit("setStateAttr", {
              key: "indexPageDetail",
              val: resData,
            });

          data.callback && data.callback(res);
        })
        .catch((err) => {
          data.callback && data.callback();
        });
    },
    getSettingList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getSystemSettings(data)
          .then((res) => {
            let li = res.data.map((item) => {
              let selectedName = "";
              for (let key in setting) {
                if (setting.hasOwnProperty(key)) {
                  if (setting[key].system_page === item.system_page) {
                    selectedName = setting[key].selectedName;
                    break;
                  }
                }
              }
              return { ...item, selectedName };
            });
            commit("setSystemList", li);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取搜索内容选项
     * @param commit
     * @param data
     */
    getSearchTypes({ commit ,state}, data) {
      return new Promise((resolve, reject) => {
        searchTypes(data)
          .then((res) => {
            // console.log(state.pageDetail,'state')

            commit("setSearchTypes", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取组织架构是否展示会员身份选项
     * 踩坑了 handleLocalStorageFetch 不行就直接请求接口
     * @param commit
     */
    getShowMemberIdentity({ commit }) {
      return new Promise((resolve, reject) => {
        getMemberIdentity()
          .then((res) => {
            // 是否展示会员身份
            let memberIdentity = 0;
            if (
              res.data.personal_user_enable === 1 &&
              res.data.unit_user_enable === 1
            ) {
              memberIdentity = 1;
            }
            commit("setMemberIdentity", memberIdentity);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取搜索文章分类选项
     * @param commit
     * @param data
     */
    getArticleCategory({ commit }, data) {
      return handleLocalStorageFetch({
        key: "articleCategory",
        api: getAllCategories,
        data,
      });
    },
    /**
     * 获取搜索文章专题分类选项
     * @param commit
     * @param data
     */
    getSpecialArticleCategory({ commit }, data) {
      const key = "specialArticleCategory";
      return handleLocalStorageFetch({ key, api: getAllCategories, data });
    },
    /**
     * 获取小商店分类选项
     * @param commit
     * @param data
     */
    getShopCategory({ commit }) {
      return handleLocalStorageFetch({
        key: "shopCategory",
        api: getAllShopCategory,
      });
    },
    // 小组选项
    getHotGroupList({ commit }) {
      return handleLocalStorageFetch({
        key: "hotGroupList",
        api: allGroupList,
      });
    },
    // 选择架构列表
    getParentList({ commit }) {
      return handleLocalStorageFetch({
        key: "parentToList",
        api: getParentToList,
        data:{
          crr_level:-1,
          only_last_level:0,
          is_show_all:0
        }
      });
    },
    /**
     * 获取专题分类选项
     * @param commit
     * @param data
     */
    getBrandCategoryOptions({ commit }, data) {
      const key = "brandCategoryOptions";
      return handleLocalStorageFetch({ key, api: brandCategoryOptions, data });
    },

    getActivityPhotoCategoryOptions() {
      const key = "activityPhotoCategoryOptions";
      return handleLocalStorageFetch({
        key,
        api: activityPhotoCategoryOptions,
      });
    },

    getSpiritColumnOptions({commit},data) {
      const key = "spiritColumnOptions";
      return handleLocalStorageFetch({ key, api: spiritColumnOptions,data});
    },

    getMePageLinkOptions: debounce(
      function () {
        const key = "mePageLinkOptions";
        return handleLocalStorageFetch({ key, api: mePageLinkOptions });
      },
      1500,
      {
        leading: true,
        trailing: false,
      }
    ),
    getMemberIndexQuickEnters: debounce(
      function () {
        const key = "memberIndexQuickEnters";
        return handleLocalStorageFetch({
          key,
          api: memberIndexQuickEnterOptions,
        });
      },
      1500,
      {
        leading: true,
        trailing: false,
      }
    ),
    // 获取在线学习分类选项数据
    getOnlineLearningOptions() {
      const key = "onlineLearningOptions";
      return handleLocalStorageFetch({ key, api: onlineLearningCategories });
    },
    getFormField({ commit }, params) {
      api({
        url: "/admin/cyc/apply_template/infoComponentSelect2",
        method: "post",
        data: params,
      })
        .then((res) => {
          commit("setStateAttr", {
            key:
              params.pageset === "unit" ? "unitFormFields" : "userFormFields",
            val: res.data,
          });
        })
        .catch(() => {});
    },
  },
};
