/*
 * @Author: mulingyuer
 * @Date: 2021-09-14 16:26:23
 * @LastEditTime: 2021-10-20 11:09:55
 * @LastEditors: aleaner
 * @Description:数据列表
 * @FilePath: \gov\router\index.js
 * 怎么可能会有bug！！！
 */
import businessAssociationList from "./business-association-list";
import dataList from "./data-list";


export default [
  {
    path: "gov-data-manage", component: { render: h => h("router-view") },
    children: [
      //商协会列表
      ...businessAssociationList,
      //资源库
      ...dataList,
      //大数据入口
      {
        path: "big-data-enter", name: "GovDataManageBigData",
        component: () => import(/* webpackChunkName: "big-data"*/ "../views/big-data/main"),
        // meta: { title: "商协会大数据" }
      },
      // 大数据主页
      {
        path: "big-data", name: "GovBigDataIndex",
        component: () => import(/* webpackChunkName: "big-data"*/ "../views/big-data/index"),
        meta: { title: "商协会大数据" }
      },
      // 基本信息
      {
        path: "base-message", name: "GovIndex",
        component: () => import(/* webpackChunkName: "big-data"*/ "../views/base-message/index"),
        // meta: { title: "基本信息" }
      },
      // 新闻
      {
        path: "news-play", name: "GovNewsPlay",
        component: () => import("../views/news-play/index"),
        // meta: { title: "新闻联播" }
      }
    ]
  }
]
