/*
 * @Author: mulingyuer
 * @Date: 2021-08-06 11:22:19
 * @LastEditTime: 2021-08-06 15:18:48
 * @LastEditors: mulingyuer
 * @Description: 小图
 * @FilePath: \agent-admin-vue\src\base\directives\lib\mini-src.js
 * 怎么可能会有bug！！！
 */
import { fixImageUrl } from "@/base/utils/tool";

export default {
  //首次
  bind(el, binding) {
    if (binding.value) {
      el.src = fixImageUrl(binding.value);
    } else {
      el.src = "";
    }
  },

  //更新
  componentUpdated(el, binding) {
    if (binding.value) {
      el.src = fixImageUrl(binding.value);
    } else {
      el.src = "";
    }
  }
}
