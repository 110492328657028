export default [
  {
    path: "ma-intro", component: { render: h => h("router-view") }, meta: { breadcrumb: { name: "CycIntroduce" } },
    children: [
      {
        path: "", name: 'Introduce', component: { render: h => h("router-view") }, meta: { breadcrumb: { title: "商会介绍" }, activeMenu: "CycIntroduce" },
        redirect: {name: 'CycIntroduce'},
        children: [
          { path: "", name: "CycIntroduce", component: () => import("../views/List"), meta: { title: "" } },
          {
            path: "config/:name", name: "MAConfig", component: () => import("../views/Config"),
            meta: { title: "商会配置" }
          }
        ]
      },
    ]
  }
]
