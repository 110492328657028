export default [
  {
    path: "authority-admin", component: {render: h => h("router-view")},
    children: [
      {
        path: "menu-manage", name: "MenuManage", component: () => import("../views/MenuList"),
      },
      {
        path: "", name: "UserManage", component: {render: h => h("router-view")},
        redirect: {name: "AdminUserListIndex"},
        children: [
          {name: "AdminUserListIndex", path: '', component: {render: h => h("router-view")},
            redirect: {name: "AdminUserList"},
            meta: {
            breadcrumb: {name: "AdminUserList", title: "用户管理"}},
            children: [
              {
                path: "manage/:id", name: "UserEdit", component: () => import("../views/UserEdit"),
                meta: {title: "用户编辑", }
              }
            ]
          },
          {path: "list", name: "AdminUserList", component: () => import("../views/UserList")},
          {path: "list", name: "AdminRoleList", component: () => import("../views/RoleList"),
          // meta: {breadcrumb: {title: "角色管理", name: "AdminRoleList"}}
          },
          {
            name: "AdminRoleListIndex",
            path: '', component: {render: h => h("router-view")},
            redirect: {name: "AdminRoleList"},
            meta: {breadcrumb: {name: "AdminRoleList", title: "角色管理"}},
            children: [
              {
                path: "user/:id", name: "RoleUser", component: () => import("../views/RoleUser"),
                meta: {title: "角色授权"}
              },
              {
                path: "access/:id", name: "RoleAccess", component: () => import("../views/RoleAccess"),
                meta: {title: "访问授权"}
              },
            ]
          },
        ]
      },
      // {
      //   path: "role-manage", name: "RoleManage", component: {render: h => h("router-view")},
      //   // meta: {activeMenu: "AdminUserList"},
      //   redirect: {name: "AdminRoleListIndex"},
      //   children: [
      //
      //   ]
      // }
    ]
  }
]
