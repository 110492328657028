export default [
  {
    path: "system-message",
    name: "system-message",
    component: () => import("../views/index"),
    meta: { title: "" },
    redirect: { name: "MessageSystem" },
    children: [
      {
        path: "message_system",
        name: "MessageSystem",
        component: () => import("../views/message_system"),
        meta: { title: "系统消息" },
      },
      {
        path: "broadcast_message",
        name: "CycBroadcastMessage",
        component: { render: (h) => h("router-view") },
        // meta: { title: "广播消息", activeMenu: "CycBroadcastMessage" },
        redirect: { name: "CycBroadcastMessageIndex" },

        children: [
          {
            path: "cyc_broadcast_message",
            name: "CycBroadcastMessageIndex",
            component: () => import("../views/cyc_broadcast_message"),
          },
          {
            path: "add_cyc_broadcast_message/add/:id",
            name: "AddCycBroadcastMessage",
            component: () => import("../views/add_cyc_broadcast_message"),
            meta: { title: `新增消息` },
          },
          {
            path: "add_cyc_broadcast_message/edit/:id",
            name: "EditCycBroadcastMessage",
            component: () => import("../views/add_cyc_broadcast_message"),
            meta: { title: `编辑消息` },
          },
        ],
      },
      {
        path: 'robot_message',
        name: "RobotMessage",
        component: () => import('../../../modules/wx-company/views/wx-company-config')
      }
    ],
  },
];
