import dataStats from "./data-stats";

export default [
  ...dataStats,
  {
    path: "organize",
    name: "Organize",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "member-list",
        name: "CycUserList",
        component: () => import("../views/member-index"),
        // meta: {
        //   activeMenu: "CycUserList",
        //   breadcrumb: { name: "CycUserList" },
        // },
        redirect: { name: "CycUserListIndex" },
        children: [
          {
            path: "index/:tab?",
            name: "CycUserListIndex",
            // name: "CycUserList",
            component: () => import("../views/member/member-list"),
            meta: {
              alias: 'CycUserList'
            }
          },
          {
            path: "edit-uncertified-student/:id",
            name: "EditUncertified",
            component: () => import("../views/member/student-detail"),
            meta: { title: "查看" },
          },
          {
            path: "add-uncertified-student/:data",
            name: "AddUncertified",
            props: true,
            component: () => import("../views/class/add-student"),
            meta: { title: "添加未认证校友" },
          },
          // {
          //   path: 'user',
          //   name: 'User',
          //   component: {render: h => h("router-view")},
          //   children: [
          //     {
          //       path: "edit/:data",
          //       name: "EditMember",
          //       props: true,
          //       component: () =>
          //         import ("../views/member/member-detail"),
          //       meta: {title: "基本信息"}
          //     },
          //     {
          //       path: "add/:data",
          //       name: "AddMember",
          //       props: true,
          //       component: () =>
          //         import ("../views/member/member-detail"),
          //       meta: {title: '添加会员信息'}
          //     },
          //   ]
          // },
          {
            path: "unit/:tab/:user_id",
            name: "Unit",
            component: () => import("../views/unit/index"),
            meta: {
              activeMenu: "CycUserList",
              breadcrumb: { name: "CycUserList", query: { role: "unit" } },
            },
            children: [
              {
                path: "add",
                name: "AddUnit",
                component: () =>
                  import("../../mail/views/company/list/add-view-edit"),
                meta: { title: "添加单位信息" },
              },
              {
                path: "edit",
                name: "EditUnit",
                // props: true,
                component: () =>
                  import("../../mail/views/company/list/add-view-edit"),
                meta: { title: "单位信息" },
              },
              {
                path: "member-message",
                name: "UnitMemberMessage",
                // props: true,
                component: () => import("../views/member/member-message"),
                meta: { title: "会员信息" },
              },
              {
                path: "my-company",
                name: "UnitCompany",
                component: { render: (h) => h("router-view") },
                meta: {
                  title: "我的企业",
                  breadcrumb: { name: "UnitCompanyList" },
                },
                redirect: { name: "UnitCompanyList" },
                children: [
                  {
                    path: "list",
                    name: "UnitCompanyList",
                    // props: true,
                    component: () => import("../views/member/my-company"),
                  },
                  {
                    path: "detail/:type/:id",
                    name: "UnitCompanyDetail",
                    component: () =>
                      import(
                        "@/modules/mail/views/company/list/add-view-edit"
                        ),
                    meta: { title: "查看" },
                  },
                ],
              },
              {
                path: "my-resource",
                name: "UnitResource",
                component: { render: (h) => h("router-view") },
                meta: {
                  title: "我的资源",
                  breadcrumb: { name: "UnitResources" },
                },
                redirect: { name: "UnitResources" },
                children: [
                  {
                    path: "",
                    name: "UnitResources",
                    // props: true,
                    component: () => import("../views/member/my-resource"),
                    meta: { title: "" },
                  },
                  {
                    path: "detail/:type/:id",
                    name: "UnitCircleDetail",
                    component: () =>
                      import(
                        "@/modules/resource-circle/views/circle-content/detail"
                        ),
                    meta: { title: "资源详情" },
                  },
                ],
              },
              {
                path: "activity",
                name: "UnitActivity",
                // props: true,
                component: () => import("../views/member/my-activity"),
                meta: { title: "我的活动" },
              },
              {
                path: "my-leave-msg",
                name: "UnitLeaveMsg",
                component: { render: (h) => h("router-view") },
                meta: { title: "我的留言" },
                redirect: { name: "UnitLeaveMsgList" },
                children: [
                  {
                    path: "",
                    name: "UnitLeaveMsgList",
                    // props: true,
                    component: () => import("../views/member/my-leavemsg"),
                    meta: { title: "" },
                  },
                  {
                    path: "detail/:id",
                    name: "UnitLeaveMsgDetail",
                    component: () =>
                      import("@/modules/ask/views/leavemsg-detail"),
                    meta: { title: "查看" },
                  },
                ],
              },
              {
                path: "my-order",
                name: "UnitOrder",
                // props: true,
                component: () => import("../views/member/my-order"),
                meta: { title: "我的订单" },
              },
              {
                path: "position-record",
                name: "UnitPositionRecode",
                // props: true,
                component: () => import("../views/member/position-recode"),
                meta: { title: "职务记录" },
              },
              {
                path: "report-record",
                name: "UnitReportRecode",
                // props: true,
                component: () => import("../views/member/report-recode"),
                meta: { title: "被举报记录" },
              },
              {
                path: "my-credential",
                name: "UnitCredential",
                // props: true,
                component: () => import("../views/member/my-credential"),
                meta: { title: "转账凭证" },
              },
              {
                path: "",
                name: "UnitInvitation",
                component: { render: (h) => h("router-view") },
                meta: {
                  title: "邀请收益",
                  breadcrumb: { name: "UnitInvitationRevenue" },
                },
                children: [
                  {
                    path: "invitation-revenue",
                    name: "UnitInvitationRevenue",
                    // props: true,
                    component: () =>
                      import("../views/member/invitation-revenue"),
                    meta: { title: "" },
                  },
                  {
                    path: "view-member/:data",
                    name: "UnitViewMember",
                    props: true,
                    component: () =>
                      import("../views/member/member-detail"),
                    meta: { title: "查看" },
                  },
                ],
              },
              {
                path: "my-income-records",
                name: "UnitIncomeRecords",
                // props: true,
                component: () =>
                  import("../views/member/my-income-records"),
                meta: { title: "等级积分记录" },
              },
            ],
          },
          // {
          //   path: ":tab",
          //   name: "CycUserListRoot",
          //   component: { render: (h) => h("router-view") },
          //   redirect: { name: "CycUserListIndex" },
          //   // redirect: { name: "CycUserListIndex" },
          //   // meta: {
          //   //   title: "会员列表",
          //   //   titleObj: { school: "校友列表" },
          //   // },
          //   children: [
          //
          //   ],
          // },
          {
            path: "add-member/:data",
            name: "AddMember",
            props: true,
            component: () => import("../views/member/member-detail"),
            meta: { title: "添加会员信息" },
          },
          {
            path: "edit-member/:data",
            name: "EditMember",
            props: true,
            component: () => import("../views/member/member-detail"),
            meta: { title: "基本信息" },
          },
          {
            path: "class-message/:data",
            name: "ClassMessage",
            props: true,
            component: () => import("../views/member/class-message"),
            meta: { title: "班级信息" },
          },
          {
            path: "member-message/:data",
            name: "MemberMessage",
            props: true,
            component: () => import("../views/member/member-message"),
            meta: { title: "会员信息" },
          },
          {
            path: 'my-resume/:data',
            name: "MyResume",
            props: true,
            component: () => import("../views/member/my-resume"),
            meta: { title: "我的简历" },
          },
          {
            path: "my-company",
            name: "MyCompany",
            component: { render: (h) => h("router-view") },
            meta: { title: "我的企业", breadcrumb: { name: "MyCompanyList" } },
            redirect: { name: "MyCompanyList" },
            children: [
              {
                path: "mycompany-list/:data",
                name: "MyCompanyList",
                props: true,
                component: () => import("../views/member/my-company"),
                meta: { title: "" },
              },
              {
                path: "mycompany-detail/:type/:id",
                name: "MyCompanyDetail",
                component: () =>
                  import("@/modules/mail/views/company/list/add-view-edit"),
                meta: { title: "查看" },
              },
            ],
          },
          {
            path: "my-resource",
            name: "MyResource",
            component: { render: (h) => h("router-view") },
            meta: { title: "我的资源", breadcrumb: { name: "MyResources" } },
            redirect: { name: "MyResources" },
            children: [
              {
                path: "my-resources/:data",
                name: "MyResources",
                props: true,
                component: () => import("../views/member/my-resource"),
                meta: { title: "" },
              },
              {
                path: "mycircle-detail/:type/:id",
                name: "MyCircleDetail",
                component: () =>
                  import(
                    "@/modules/resource-circle/views/circle-content/detail"
                  ),
                meta: { title: "资源详情" },
              },
            ],
          },
          {
            path: "my-activity/:data",
            name: "MyActivity",
            props: true,
            component: () => import("../views/member/my-activity"),
            meta: { title: "我的活动" },
          },
          {
            path: "my-leavemsg",
            name: "MyLeaveMsg",
            component: { render: (h) => h("router-view") },
            meta: { title: "我的留言" },
            redirect: { name: "MyLeaveMsgList" },
            children: [
              {
                path: "myleavemsg-list/:data",
                name: "MyLeaveMsgList",
                props: true,
                component: () => import("../views/member/my-leavemsg"),
                meta: { title: "" },
              },
              {
                path: "myleavemsg-detail/:id",
                name: "MyLeaveMsgDetail",
                component: () => import("@/modules/ask/views/leavemsg-detail"),
                meta: { title: "查看" },
              },
            ],
          },
          {
            path: "my-order/:data",
            name: "MyOrder",
            props: true,
            component: () => import("../views/member/my-order"),
            meta: { title: "我的订单" },
          },
          {
            path: "position-recode/:data",
            name: "PositionRecode",
            props: true,
            component: () => import("../views/member/position-recode"),
            meta: { title: "职务记录" },
          },
          {
            path: "report-recode/:data",
            name: "ReportRecode",
            props: true,
            component: () => import("../views/member/report-recode"),
            meta: { title: "被举报记录" },
          },
          {
            path: "my-credential/:data",
            name: "myCredential",
            props: true,
            component: () => import("../views/member/my-credential"),
            meta: { title: "转账凭证" },
          },
          {
            path: 'my-revenue',
            name: "MyRevenue",
            component: { render: (h) => h("router-view") },
            // meta: {
            //   title: "邀请收益",
            //   breadcrumb: { name: "InvitationRevenue" },
            // },
            meta: {
              title: "邀请收益",
              activeMenu: "CycUserList",
              breadcrumb: { name: "CycUserList" },
            },
            children: [
              {
                path: "invitation-revenue/:data",
                name: "InvitationRevenue",
                props: true,
                component: () => import("../views/member/invitation-revenue"),
                meta: { title: "" },
              },
              {
                path: "view-member/:data",
                name: "ViewMember",
                props: true,
                component: () => import("../views/member/member-detail"),
                meta: { title: "查看" },
              },
            ],
          },
          {
            path: "my-income-records/:data",
            name: "myIncomeRecords",
            props: true,
            component: () => import("../views/member/my-income-records"),
            meta: { title: "等级积分记录" },
          },
        ],
      },
      {
        path: "announce",
        name: "AnnounceList",
        component: { render: (h) => h("router-view") },
        // meta: { activeMenu: "CycUserPublicList" },
        // redirect: { name: "CycUserPublicList" },
        redirect: { name: "CycUserPublicList" },
        children: [
          {
            path: "list",
            name: "CycUserPublicList",
            component: { render: (h) => h("router-view") },
            // meta: {
            //   // title: "公示列表",
            //   breadcrumb: { name: "CycUserPublicList" },
            //   activeMenu: "CycUserPublicList",
            // },
            redirect: { name: "CycUserPublicListIndex" },
            children: [
              {
                path: "",
                name: "CycUserPublicListIndex",
                component: () => import("../views/announce/list"),
              },
              {
                path: "detail/:id",
                name: "CycUserPublicDetail",
                component: () => import("../views/announce/detail"),
                meta: {
                  title: "详情",
                  // activeMenu: "CycUserPublicList",
                },
              },
            ],
          },
          {
            path: "config",
            name: "CycUserPublicConfig",
            component: () => import("../views/announce/other-config"),
            meta: {
              title: "其他配置",
            },
          },
        ],
      },
      {
        path: "cyc-organization",
        name: "CycOrganizationList",
        component: { render: (h) => h("router-view") },
        // meta: { title: "", activeMenu: "CycOrganizationList" },
        redirect: { name: "CycOrganizationListIndex" },
        children: [
          {
            path: "list",
            name: "CycOrganizationListIndex",
            component: () => import("../views/org-frame/frame-list"),
            // meta: { title: "组织架构", titleObj: { school: "校友会列表" } },
          },
          {
            path: "add",
            name: "Add",
            component: { render: (h) => h("router-view") },
            // meta: { title: "" },
            redirect: { name: "AddFrame" },
            meta: {activeMenu: "CycOrganizationList"},
            children: [
              {
                path: "add-frame",
                name: "AddFrame",
                component: () => import("../views/org-frame/add-frame"),
                meta: { title: "新增" },
              },
              // {
              //   path: "frame-template", name: "AddTemplate", props: true,
              //   component: () => import("../views/org-frame/frame-template"), meta: { title: "申请模板" }
              // },
              {
                path: "form-page/:id/:pageset",
                name: "FormPage",
                component: () => import("../views/org-frame/form-page"),
                meta: { title: "配置个人申请信息" },
              },
              {
                path: "pre-task",
                name: "preTask",
                component: { render: (h) => h("router-view") },
                meta: { title: "预备会员任务配置" },
                redirect: { name: "TaskConfig" },
                children: [
                  {
                    path: "task-config/:id",
                    name: "TaskConfig",
                    component: () => import("../views/org-frame/task-config"),
                    meta: { title: "" },
                  },
                  {
                    path: "edit-rule/:id/:previousId",
                    name: "EditRule",
                    component: () => import("../views/org-frame/edit-rule"),
                    meta: { title: "编辑规则" },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "cyc-position",
        name: "CycPositionList",
        component: { render: (h) => h("router-view") },
        redirect: { name: "CycPositionListIndex" },
        children: [
          {
            path: "list",
            name: "CycPositionListIndex",
            component: () => import("../views/meet-post/post-list"),
          },
          {
            path: "level-config",
            name: "LevelConfig",
            component: () => import("../views/meet-post/level-config"),
            meta: { title: "等级配置" },
          },
        ],
      },
      // 架构排序
      {
        path: "sorts-config",
        name: "CycSortsConfig",

        component: { render: (h) => h("router-view") },
        // meta: {
        //   breadcrumb: { title: "", name: "CycSortsConfig" },
        //   activeMenu: "CycSortsConfig",
        // },
        redirect: { name: "CycSortsConfigIndex" },
        children: [
          {
            path: "index",
            name: "CycSortsConfigIndex",
            component: () => import("../views/cyc-sorts-config.vue"),
          },
          {
            path: "member-sorts/:id",
            name: "MemberSorts",
            component: () =>
              import("../views/cyc-sorts-config/member-sorts.vue"),
            meta: { title: "会员排序" },
          },
        ],
      },
      {
        path: "student",
        name: "student",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "校友列表",
          activeMenu: "SchoolStudentList",
          breadcrumb: { name: "SchoolStudentList" },
        },
        children: [
          {
            path: "school-student-list",
            name: "SchoolStudentList",
            component: () => import("../views/class/school-student-list"),
          },
          {
            path: "add-uncertified-student/:data",
            name: "AddUncertifiedStudent",
            props: true,
            component: () => import("../views/class/add-student"),
            meta: { title: "添加未认证校友" },
          },
        ],
      },
      {
        path: "class",
        name: "Class",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "班级列表",
          activeMenu: "SchoolClassList",
          breadcrumb: { name: "SchoolClassList" },
        },
        children: [
          {
            path: "school-class-list",
            name: "SchoolClassList",
            component: () => import("../views/class/school-class-list"),
          },
          {
            path: "student-list",
            name: "studentList",
            component: { render: (h) => h("router-view") },
            meta: {
              title: "班级成员列表",
              activeMenu: "studentList",
              breadcrumb: { name: "studentList" },
            },
            children: [
              {
                path: "edit-uncertified-students/:id",
                name: "EditUncertifiedStudent",
                component: () => import("../views/member/student-detail"),
                meta: { title: "查看" },
              },
              {
                path: "student-list-member/:id",
                name: "studentListMember",
                component: () => import("../views/class/school-student-list"),
                meta: { title: "" },
              },
            ],
          },
        ],
      },
      {
        path: "other-config",
        name: "CycOtherConfig",
        component: { render: (h) => h("router-view") },
        meta: { title: "", activeMenu: "CycOtherConfig" },
        redirect: { name: "CycProjectInfo" },
        children: [
          {
            path: "term",
            name: "CycTerm",
            component: () => import("../views/other-config/term-config"),
            // meta: { title: "届数配置" },
          },
          {
            path: "position-show",
            name: "CycPositionShow",
            component: () =>
              import("../views/other-config/position-show-config"),
            // meta: { title: "组织架构展示" },
          },
          {
            path: "docking",
            name: "CycDocking",
            component: () => import("../views/other-config/docking-config"),
            // meta: { title: "对接人配置" },
          },
          {
            path: "school-campus-and-period",
            name: "SchoolCampusAndPeriod",
            component: () =>
              import("../views/other-config/school-campus-and-period"),
            // meta: { title: "校区、学段配置" },
          },
          {
            path: "invitation-code",
            name: "CycInvitationCode",
            component: () =>
              import("../views/other-config/invitation-code-config"),
            // meta: { title: "邀请码配置" },
          },
          {
            path: "position-permissions",
            name: "CycPositionPermissions",
            component: () =>
              import("../views/other-config/position-permissions-config"),
            // meta: { title: "权限配置" },
          },
          {
            path: "contact",
            component: { render: (h) => h("router-view") },
            // meta: {
            //   title: "联系方式",
            //   breadcrumb: { title: "", name: "CycRegisterContact" },
            // },
            children: [
              {
                path: "register-contact",
                name: "CycRegisterContact",
                component: () => import("../views/other-config/register-contact"),
                // meta: { title: "联系我" },
              },
              // {
              //   path: "join-group-chat",
              //   name: "CycGroupChat",
              //   component: () => import("../views/other-config/join-group-chat"),
              //   // meta: { title: "加入群聊" },
              // },
          ]},
          {
            path: "message-config", name: "CycProjectInfo",
            component: () => import("../../organization-message/views/message-config"),
            // meta: { activeMenu: "CycOtherConfig" },
          }
            // ],
          // },
        ],
      },
      {
        path: "transfer-recode",
        name: "CycCredentialList",
        component: () => import("../views/member/transfer-record.vue"),
        meta: { title: "转账记录", activeMenu: "CycCredentialList" },
      },
      //其他配置
      {
        path: "cyc-other-config",
        name: "CycMemberOtherConfig",
        component: () => import("../views/cyc-other-config/index"),
        // meta: { title: "其他配置", activeMenu: "CycMemberOtherConfig" },
      },
    ],
  },
];
