export default [
  {
    path: "badage", component: { render: h => h("router-view") },
    //   meta: { title: "会刊列表", breadcrumb: { name: "JournalList" } },
    name: "BadgeList",
    redirect: { name: "BadgeListIndex" },
    children: [
      {
        path: "badage", name: "BadgeListIndex", component: () => import("../views/badge-list"),
      },
      {
        path: "AddBadge", name: "AddBadge", component: () => import("../views/details-badge"),
        meta: { title: "新增" }
      },
      {
        path: "EditBadge/:id", name: "EditBadge", component: () => import("../views/details-badge"),
        meta: { title: "编辑" }
      },
    ]
  }
]
