export default [
  {
    path: "photos-album-library", component: { render: h => h("router-view") },
    name: "DataListPhotoLibrary",
    redirect: {name: "DataListPhotoLibraryIndex"},
    children: [
      { path: "list", name: "DataListPhotoLibraryIndex", component: () => import("../../views/album-library/index"),
      },
    ]
  }
]
